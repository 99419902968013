<template>
  <v-card class="mx-auto booking-view" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <v-toolbar class="px-0" color="#ffffff" prominent elevation="3">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="toolbar-title">Booking</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="refreshPage">
        <v-icon color="#007BFF">mdi-reload</v-icon>
      </v-btn>

      <!-- Using v-slot:extension for tabs -->
      <template v-slot:extension>
        <v-tabs v-model="tabs" grow>
          <v-tab :value="0">
            <v-icon>mdi-format-list-bulleted</v-icon>
            Book Now
          </v-tab>
          <v-tab :value="1">
            <v-icon>mdi-format-line-spacing</v-icon>
            Bookings
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-window v-model="tabs">
      <v-window-item :value="0">
        <v-card>
          <BookingPoisList />
        </v-card>
      </v-window-item>
      <v-window-item :value="1">
        <v-card>
          <UserBookings />
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
  import { ref, defineComponent, watch, onMounted } from 'vue'
  import UserBookings from '@/components/general/UserBookings.vue'
  import BookingPoisList from '@/components/general/BookingPoisList.vue'
  import { getToolbarLogoImage, getBottomBarColor } from '@/assets/Branding/branding.js'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'BookingView',
    components: {
      BookingPoisList,
      UserBookings,
    },
    setup() {
      const tabs = ref(0) // Starts with Book Now tab
      const router = useRouter()
      let startX = 0
      const logo = ref('')
      const tabColor = ref('')

      const handleTouchStart = e => {
        startX = e.touches[0].clientX // Get the first touch point
      }

      const handleTouchEnd = e => {
        const endX = e.changedTouches[0].clientX // Get the point where touch ended
        const diffX = startX - endX // Calculate the difference in start and end position

        if (diffX > 50) {
          // Swipe Left
          tabs.value = Math.min(tabs.value + 1, 1) // Increment tabs index, with a max of 1
        } else if (diffX < -50) {
          // Swipe Right
          tabs.value = Math.max(tabs.value - 1, 0) // Decrement tabs index, with a min of 0
        }
      }

      // Watch for tab changes to adjust routing
      watch(tabs, newValue => {
        if (newValue === 0) {
          router.push({ name: 'BookingPoisList' })
        } else {
          router.push({ name: 'UserBookings' })
        }
      })

      // Initialize component with specific tab based on the route
      const initializeComponent = () => {
        if (router.currentRoute.value.name === 'BookingPoisList') {
          tabs.value = 0 // Corresponds to <BookingPoisList>
        } else if (router.currentRoute.value.name === 'UserBookings') {
          tabs.value = 1 // Corresponds to <UserBookings>
        }
      }

      // Method to handle hot refresh
      const refreshPage = () => {
        router.go(0)
      }

      onMounted(async () => {
        tabColor.value = await getBottomBarColor()
        logo.value = await getToolbarLogoImage()
        initializeComponent()
      })

      return {
        tabs,
        logo,
        tabColor,
        handleTouchStart,
        handleTouchEnd,
        refreshPage,
      }
    },
  })
</script>

<style scoped>
  .booking-view {
    box-shadow: none;
  }

  .toolbar-icon {
    height: 40px;
    align-self: center;
    margin-left: 1rem;
  }

  .toolbar-title {
    font-weight: 500;
    font-size: 1.5rem;
    color: #333333;
  }

  .v-toolbar {
    background-color: #ffffff;
    color: #007bff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .v-tab {
    font-weight: bold;
    color: #007bff; /* Default color for non-active tabs */
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-tab v-icon {
    color: #007bff; /* Default icon color for non-active tabs */
    margin-right: 8px;
  }

  .v-tab-item--selected,
  .v-tab--selected {
    background-color: #007bff !important; /* Background color for active tab */
    color: #ffffff !important; /* Text color for active tab */
  }

  .v-tab-item--selected v-icon,
  .v-tab--selected v-icon {
    color: #ffffff !important; /* Icon color for active tab */
  }

  .v-tabs {
    border-bottom: 2px solid #007bff;
  }
</style>
