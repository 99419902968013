<template>
  <div class="homeview">
    <Loader :loading="showLoader" />
    <v-toolbar color="#ffffff" prominent elevation="3" class="mb-4">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="toolbar-title">Motivation Center</v-toolbar-title>
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col v-for="motivationItem in motivationItems" :key="motivationItem.id" cols="12" sm="4" md="4" lg="3">
          <v-card
            class="info-card"
            :class="{ 'info-card-hover': hoveredCard === motivationItem.id }"
            @mouseover="hoveredCard = motivationItem.id"
            @mouseleave="hoveredCard = null"
            @click="motivationItemDetail(motivationItem)"
          >
            <img :src="motivationItem.image" class="info-img" />
            <v-card-title class="card-title">
              {{ motivationItem.title }}
            </v-card-title>
            <v-card-text class="text-description">
              {{ motivationItem.description }}
            </v-card-text>
            <v-card-actions>
              <v-btn class="read-more-btn" density="compact" @click="motivationItemDetail(motivationItem)" variant="tonal">
                Learn More
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog Component -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="headline">{{ selectedItem.title }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="(detail, index) in selectedItem.details" :key="index" cols="12" md="4">
                <v-card flat class="pa-3">
                  <div class="text-center">
                    <img :src="selectedItem.images[index]" class="dialog-img mb-2" />
                    <div class="text-h6">{{ detail }}</div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted } from 'vue'
  import Loader from '@/components/general/Loader.vue'
  import { motivationItems } from '@/dummydata/motivationData.js'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'MarketingView',
    components: {
      Loader,
    },
    setup() {
      const hoveredCard = ref(null)
      const showLoader = ref(true)
      const logo = ref('')
      const dialog = ref(false)
      const selectedItem = ref({})
      const router = useRouter()

      const motivationItemDetail = motivationItem => {
        selectedItem.value = { ...motivationItem }
        dialog.value = true
      }

      onMounted(async () => {
        logo.value = await getToolbarLogoImage()

        setTimeout(() => {
          showLoader.value = false
        }, 1200)
      })

      return {
        hoveredCard,
        motivationItems,
        motivationItemDetail,
        showLoader,
        logo,
        dialog,
        selectedItem,
      }
    },
  })
</script>

<style scoped>
  .homeview {
    padding-bottom: 70px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }

  .info-card {
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .info-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  .info-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .card-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    color: #333;
    padding: 8px 16px 0 16px;
  }

  .text-description {
    font-family: 'Roboto', sans-serif;
    color: #666;
    font-size: 0.875rem;
    padding: 0 16px 8px 16px;
  }

  .read-more-btn {
    font-family: 'Roboto', sans-serif;
    color: #1976d2;
    text-transform: none;
    font-weight: bold;
    margin: 8px 16px;
  }

  .read-more-btn v-icon {
    margin-left: 4px;
  }

  .toolbar-icon {
    height: 40px;
    align-self: center;
    margin-left: 1rem;
  }

  .toolbar-title {
    font-weight: 500;
    font-size: 1.5rem;
    color: #333333;
  }

  .v-toolbar {
    background-color: #ffffff;
    color: #007bff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .dialog-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 600px) {
    .info-card {
      margin-bottom: 16px;
    }

    .card-title {
      font-size: 1rem;
    }

    .text-description {
      font-size: 0.75rem;
    }

    .read-more-btn {
      font-size: 0.875rem;
    }
  }
</style>
