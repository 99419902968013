<template>
  <div class="infocenter">
    <Loader :loading="showLoader" />
    <v-toolbar color="#ffffff" prominent elevation="3">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="toolbar-title">Information Center</v-toolbar-title>
      <v-btn icon>
        <v-icon color="#007BFF">mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col v-for="infoItem in infoItems" :key="infoItem.id" cols="12" sm="6" md="4" lg="3">
          <v-card
            class="info-card"
            :class="{ 'info-card-hover': hoveredCard === infoItem.id }"
            @mouseover="hoveredCard = infoItem.id"
            @mouseleave="hoveredCard = null"
            @click="infoItemDetail(infoItem)"
          >
            <v-img :src="infoItem.image ? infoItem.image : fallbackImage" class="white--text align-end" aspect-ratio="1.7">
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="white"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title class="card-title">
              {{ infoItem.title }}
            </v-card-title>
            <v-card-text class="text-description">
              {{ infoItem.shortText }}
            </v-card-text>
            <v-card-subtitle class="card-subtitle">
              {{ infoItem.creationTime }}
            </v-card-subtitle>

            <v-card-actions v-if="infoItem?.buttonText">
              <v-btn class="read-more-btn" density="compact" @click="infoItemDetail(infoItem)" variant="tonal">
                {{ infoItem?.buttonText || 'Read More' }}
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <InfoItemDetails ref="infoItemDetailDialogRef" :infoItem="selectedInfoItem" />
    </v-container>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted } from 'vue'
  import lodash from 'lodash'
  import moment from 'moment'
  import Loader from '@/components/general/Loader.vue'
  import InfoItemDetails from '@/components/general/InfoItemDetails.vue'
  import { getToolbarLogoImage, getToolBarCustomerName } from '@/assets/Branding/branding.js'
  import { isStaingOrQa } from '@/controllers/BaseController'

  export default defineComponent({
    name: 'InfoCenterView',
    components: {
      Loader,
      InfoItemDetails,
    },
    setup() {
      const hoveredCard = ref(null)
      const showLoader = ref(true)
      const customerName = ref('')
      const logo = ref('')
      const fallbackImage = ref('')
      const infoItems = ref([])
      const selectedInfoItem = ref({})
      const infoItemDetailDialogRef = ref(null)

      const infoItemDetail = infoItem => {
        infoItem = { ...infoItem }
        selectedInfoItem.value = infoItem
        if (infoItem?.text === '') {
          if (infoItem.buttonUrl !== '') {
            window.open(infoItem.buttonUrl, '_blank')
          }
        } else {
          infoItemDetailDialogRef.value.openInfoItemDetail(infoItem)
        }
      }

      onMounted(async () => {
        try {
          logo.value = await getToolbarLogoImage()
          const isPIA = await isStaingOrQa()

          // Conditionally import the correct functions based on environment
          let getInfoItems, getInfoCategoryById, getFile, getFileById, getDataFromBlob, readAsDataURL, getFileInfo

          if (isPIA) {
            const imports = await import('@/dummydata/infoItems.js')
            getInfoItems = imports.infoItems
            getInfoCategoryById = imports.getInfoCategoryById
            getFileById = imports.getFileById
            getFile = getFileById // Use getFileById as getFile for consistency
          } else {
            const imports = await import('@/controllers/BaseController')
            getInfoItems = imports.getInfoItems
            getInfoCategoryById = imports.getInfoCategoryById
            getFile = imports.getFile
            getDataFromBlob = imports.getDataFromBlob
            readAsDataURL = imports.readAsDataURL
            getFileInfo = imports.getFileInfo
          }

          const INFOITEMS = await getInfoItems()
          const formattedInfoItems = await Promise.all(
            INFOITEMS.map(async item => {
              const infoCategory = await getInfoCategoryById(item.infoCategory.id)
              item.infoCategory = infoCategory

              const titleImage = item.files.entities.find(file => ['titleimage'].includes(file.type))?.id

              // Skip item if titleImage is undefined and isPIA is true
              if (isPIA && titleImage === undefined) {
                return null // Mark this item as null to filter it out later
              }

              if (titleImage !== undefined) {
                const file = await getFile(titleImage)
                if (isPIA) {
                  item['image'] = file.file // Directly use base64 image in dummy data
                } else {
                  item['image'] = await getDataFromBlob(file) // Convert blob to base64 for live environment
                }
              } else {
                const image = new Image()
                image.src = logo.value
                image.onload = () => {
                  const canvas = document.createElement('canvas')
                  const ctx = canvas.getContext('2d')
                  const imgWidth = 50
                  const imgHeight = 40
                  canvas.width = imgWidth
                  canvas.height = imgHeight
                  const x = (canvas.width - imgWidth) / 2
                  const y = (canvas.height - imgHeight) / 2
                  ctx.drawImage(image, x, y, imgWidth, imgHeight)
                  fallbackImage.value = canvas.toDataURL()
                }
              }

              const attachedImages = item.files.entities.filter(file => ['image'].includes(file.type))
              const attachedFiles = item.files.entities.filter(file => ['attachment'].includes(file.type))

              if (attachedImages.length > 0) {
                const images = await Promise.all(
                  attachedImages.map(async image => {
                    const file = await getFile(image.id)
                    return isPIA ? file.file : await getDataFromBlob(file)
                  })
                )
                item['attachedImages'] = images
              }

              if (attachedFiles.length > 0) {
                item['filesArray'] = await Promise.all(
                  attachedFiles.map(async fileEntity => {
                    const file = await getFile(fileEntity?.id)
                    const base64data = isPIA ? file.file : await readAsDataURL(file)
                    const fileInfo = await getFileInfo(fileEntity?.id)
                    return {
                      source: base64data,
                      fileName: fileInfo.fileName,
                      fileType: fileInfo['mimeType'].split('/')[1],
                    }
                  })
                )
              }

              if (item?.shortText) {
                item['description'] = item.shortText
              }

              if (item?.tags['display-button']) {
                item['displayButton'] = item.tags['display-button']
                item['buttonText'] = item.tags['button-text']
                item['buttonUrl'] = item.tags['button-url']
              }

              item['creationTime'] = moment(item.creationTime).format('MMM DD, YYYY')

              return item
            })
          )

          // Filter out null items (those that were skipped)
          const filteredInfoItems = formattedInfoItems.filter(item => item !== null)

          // Sort and assign to infoItems
          const sortedInfoItems = lodash.sortBy(filteredInfoItems, [item => -moment(item.creationTime, 'MMM DD, YYYY').valueOf()])
          infoItems.value = sortedInfoItems

          infoItems.value = sortedInfoItems
          customerName.value = await getToolBarCustomerName()
        } catch (error) {
          console.error('Error fetching info items', error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        hoveredCard,
        infoItems,
        infoItemDetail,
        showLoader,
        customerName,
        logo,
        selectedInfoItem,
        infoItemDetailDialogRef,
        fallbackImage,
      }
    },
  })
</script>

<style scoped>
  .infocenter {
  }

  .toolbar-icon {
    height: 40px;
    align-self: center;
    margin-left: 1rem;
  }

  .toolbar-title {
    font-weight: 500;
    font-size: 1.5rem;
    color: #333333;
  }

  .v-toolbar {
    background-color: #ffffff;
    color: #007bff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .info-card {
    border-radius: 12px;
    overflow: hidden;
    background: whitesmoke;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .info-card-hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  .v-card-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    color: #333;
    padding-bottom: 0 !important;
  }

  .v-card-subtitle {
    font-family: 'Roboto', sans-serif;
    color: #50c878;
    margin-bottom: 4px;
  }

  .text-description {
    font-family: 'Roboto', sans-serif;
    color: #666;
    font-size: 0.875rem;
    padding-bottom: 0 !important;
  }

  .read-more-btn {
    font-family: 'Roboto', sans-serif;
    color: #1976d2;
    text-transform: none;
    font-weight: bold;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .fallback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .fallback-logo {
    width: 100px;
    height: 100px;
  }

  .read-more-btn v-icon {
    margin-left: 4px;
  }

  @media (max-width: 600px) {
    .info-card {
      margin-bottom: 16px;
    }

    .v-card-title {
      font-size: 1rem;
    }

    .text-description {
      font-size: 0.75rem;
    }

    .v-card-subtitle {
      font-size: 0.75rem;
    }

    .read-more-btn {
      font-size: 0.875rem;
    }
  }
</style>
