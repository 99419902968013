<template>
  <div class="poi-details">
    <v-card>
      <v-toolbar color="black" dark flat>
        <v-btn icon @click="goBack">
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-toolbar-title>{{ getPoiName() }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="openMap">
          <v-icon>mdi-map</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <Loader :loading="showLoader" />

    <MapView ref="mapView" />

    <v-container>
      <v-row dense>
        <v-col :cols="12" sm="4" class="mx-auto">
          <v-card elevation="4" style="border-radius: 1px">
            <v-img :src="poiImage" height="300px" cover></v-img>
          </v-card>
        </v-col>

        <v-card elevation="2" style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem" width="100%">
          <v-card-text>
            <v-card-title style="font-weight: bold; font-size: 1rem">{{ poi?.name }}</v-card-title>
            <v-card-subtitle style="text-align: start; margin-top: 0.2rem">
              <v-icon>mdi-office-building-marker-outline</v-icon>
              {{ poiCategory?.name }}
            </v-card-subtitle>
            <v-card-subtitle style="text-align: start; margin-top: -1.2rem">
              <v-icon>mdi-blur</v-icon>
              {{ poi?.floor?.name }}
            </v-card-subtitle>
          </v-card-text>
        </v-card>

        <v-card
          elevation="2"
          v-if="poi?.hasHardware || poi?.hasEntrances"
          style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem; padding: 1.5rem"
          width="100%"
        >
          <!-- First Row: Hardware Icons with Shadow -->
          <v-card-text class="d-flex justify-start card-content" v-if="poi?.hasHardware">
            <v-btn
              v-if="poi.LIGHT?.length"
              icon
              style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
              class="ma-2"
            >
              <v-icon color="black">mdi-lightbulb-on-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="poi.BLINDS?.length"
              icon
              style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
              class="ma-2"
            >
              <v-icon color="black">mdi-blinds</v-icon>
            </v-btn>
            <v-btn
              v-if="poi.VENTILATOR?.length"
              icon
              style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
              class="ma-2"
            >
              <v-icon color="black">mdi-fan</v-icon>
            </v-btn>
            <v-btn
              v-if="poi.HEATER?.length"
              icon
              style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
              class="ma-2"
            >
              <v-icon color="black">mdi-thermometer</v-icon>
            </v-btn>
          </v-card-text>

          <!-- Second Row: Elevator Information -->
          <v-row no-gutters align="center" justify="space-between" class="mt-4 d-flex" v-if="poi?.hasEntrances">
            <v-col class="d-flex align-center" cols="auto">
              <v-icon color="purple" size="32" class="mr-2">mdi-elevator</v-icon>
              <span>{{ poi.entrances[0]?.elevator?.name }}</span>
            </v-col>
          </v-row>

          <!-- Third Row: Entrance Information with Chips -->
          <v-row no-gutters align="center" justify="start" class="mt-4 d-flex flex-wrap" v-if="poi?.entrances?.length">
            <v-col class="d-flex align-center" cols="auto">
              <v-icon color="gray" size="32" class="mr-2">mdi-door</v-icon>
              <div>
                <span>Entrances:</span>
                <v-chip v-for="(entrance, index) in poi.entrances" :key="index" class="ma-1" small outlined color="primary">
                  {{ entrance.name }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          v-if="poi?.description"
          elevation="2"
          style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem"
          width="100%"
        >
          <v-card-text>
            <v-card-title style="font-weight: bold">Description</v-card-title>
            <v-card-subtitle style="text-align: start">
              <span v-if="!readMore" v-html="trimmedDescription"></span>
              <span v-else v-html="fullDescription"></span>
              <v-btn text small color="primary" v-if="longDescription" @click="toggleReadMore" style="margin-top: 1rem">
                {{ readMore ? 'Read Less' : 'Read More' }}
              </v-btn>
            </v-card-subtitle>
          </v-card-text>
        </v-card>

        <v-dialog v-model="showIncidents" max-width="500">
          <v-card elevation="2" style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem">
            <v-card-title class="text-h5 grey lighten-2">
              Current Incidents
              <v-btn icon @click="showIncidents = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-list dense>
                <v-list-item v-for="(incident, index) in sortedIncidents" :key="index" three-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ incident?.incidentCategoryName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(incident.creationTime) }}</v-list-item-subtitle>
                    <v-list-item-subtitle :style="{ color: getIncidentColor(incident.state) }">
                      {{ incident.state }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-card
          v-if="!isRestroom"
          elevation="2"
          style="
            border-radius: 10px;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
            margin-top: 1rem;
            margin-top: 1rem;
            padding: 1.5rem;
            transition: box-shadow 0.3s;
            &:hover {
              box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
            }
          "
          width="100%"
        >
          <v-row align="center" justify="space-between">
            <v-card-title class="flex-grow-1" style="font-weight: bold">{{ getAvailabiltyText() }}</v-card-title>
            <v-btn text small dark @click="availabilityDialog = true">Show Booking</v-btn>
          </v-row>

          <v-dialog v-model="availabilityDialog" persistent max-width="300px">
            <v-card style="background-color: #1a202c">
              <v-card-title></v-card-title>
              <v-card-text>
                <DatePicker
                  :max-date="maxDate"
                  ref="datePicker"
                  color="red"
                  :disabled-dates="disabledDates"
                  is-dark
                  v-model="selectedDate"
                  mode="date"
                ></DatePicker>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn plain color="white" style="background-color: #ff5252" text @click="discardDate">Cancel</v-btn>
                <v-btn plain style="background-color: #4caf50; color: white" @click="confirmDate">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-container class="d-flex justify-center align-center" fill-height>
            <v-btn color="black" width="20%" style="border-radius: 2px; color: white" @click="goToBooking">Book</v-btn>
          </v-container>
          <TimeSlot
            v-if="!showLoader"
            :startTime="new Date(slotsStart)"
            :endTime="new Date(slotsEnd)"
            :bookings="bookings"
            :freeBookings="freeBookings"
            :class="{ 'disabled-timeslot': showCalendarModal || showIncidents }"
          />
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { ref, onMounted, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import lodash from 'lodash'
  import moment from 'moment'
  import momentTZ from 'moment-timezone'
  import Loader from '@/components/general/Loader.vue'
  import { DatePicker } from 'v-calendar'
  import MapView from '@/components/general/MapView.vue'
  import TimeSlot from '@/components/general/ChildComponents/TimeSlot.vue'
  import {
    getFile,
    getFloorById,
    getPoiCategoryById,
    getPoiById,
    getBuildings,
    isStaingOrQa,
    getReservationsByPoi,
  } from '@/controllers/BaseController'
  import { getHardwareByType, getElevators } from '@/controllers/BackboneAPI'

  export default {
    name: 'PoiDetails',
    components: {
      Loader,
      TimeSlot,
      DatePicker,
      MapView,
    },
    setup() {
      const router = useRouter()
      const showLoader = ref(true)
      const poi = ref({})
      const selectedBuilding = ref({})
      const mapView = ref(null)
      const poiCategory = ref({})
      const bookings = ref([])
      const freeBookings = ref([])
      const showIncidents = ref(false)
      const readMore = ref(false)
      const longDescription = ref(false)
      const originalPoi = ref({})
      const trimmedDescription = ref('')
      const disabledDates = ref([
        {
          repeat: {
            weekdays: [7, 1], // 1 is Monday, 7 is Sunday
          },
        },
      ])
      const fullDescription = ref('')
      const slotsStart = ref(new Date())
      const slotsEnd = ref(new Date())
      const showCalendarModal = ref(false)
      const availabilityDialog = ref(false)
      const selectedDate = ref(new Date())
      const poiImage = ref('')
      const hasHardware = ref(false)
      const isRestroom = ref(false)

      const goBack = () => router.back()

      const maxDate = computed(() => {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 57)
      })

      const openDatePicker = () => {
        availabilityDialog.value = true
      }

      const discardDate = () => {
        availabilityDialog.value = false
        selectedDate.value = new Date()
      }

      const confirmDate = async () => {
        availabilityDialog.value = false
        await handleAvailability()
      }

      const handleAvailability = async () => {
        let start = poi.value.availableFrom
        let end = poi.value.availableUntil

        let bookings = await getReservationsByPoi(poi.value.id, start, end)
        console.log(bookings)
      }

      const getPoiName = () => poi.value.name

      const fetchPoiImage = async category => {
        let image = ''
        if (lodash.some(category.files.entities, { type: 'image' })) {
          const imageId = category.files.entities.find(file => file.type === 'image').id
          const blob = await getFile(imageId)
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            image = reader.result
            poiImage.value = image
          }
        } else {
          poiImage.value = 'https://via.placeholder.com/300' // Fallback image
        }
      }

      const openMap = () => {
        let BUILDING = JSON.parse(JSON.stringify(selectedBuilding.value))
        let Category = JSON.parse(JSON.stringify(poiCategory.value))
        let POI = lodash.cloneDeepWith(poi.value, value => (typeof value === 'object' && new WeakSet().has(value) ? undefined : undefined))
        mapView.value.openMap(BUILDING, [POI], [Category])
      }

      const goToBooking = () => {
        router.push({
          name: 'createManageBooking',
          params: {
            building_id: poi.value.buildingId,
            category_id: poi.value.poiCategories.ids[0],
            poi_id: poi.value.id,
          },
        })
      }

      const toggleReadMore = () => {
        readMore.value = !readMore.value
      }

      const getAvailabiltyText = () => {
        const today = moment().format('YYYY-MM-DD')
        const givenDate = moment(slotsStart.value).format('YYYY-MM-DD')
        const isToday = today === givenDate
        return isToday ? 'Current Availability' : `${moment(slotsStart.value).format('DD MMMM YYYY')} Availability`
      }

      const formatDate = date => moment(date).format('DD.MM.YYYY HH:mm')
      const getIncidentColor = state => {
        if (state === 'Open') return 'orange'
        if (state === 'Done') return 'green'
        return '#0096ff'
      }
      let incidents = ref([])
      const sortedIncidents = computed(() => lodash.orderBy(incidents.value, ['incidentCategoryName'], ['asc']))

      onMounted(async () => {
        try {
          const fetchedPoi = await getPoiById(router.currentRoute.value.params.poi_id)
          let isPIA = await isStaingOrQa()
          originalPoi.value = fetchedPoi
          let buildings = await getBuildings()
          selectedBuilding.value = buildings.find(building => building.id === fetchedPoi.buildingId)
          const fetchedCategory = await getPoiCategoryById(fetchedPoi.poiCategories.ids[0])
          if (
            fetchedCategory.name?.toLowerCase()?.includes('rest') ||
            fetchedCategory.name?.toLowerCase()?.includes('toilet') ||
            fetchedCategory.name?.toLowerCase()?.includes('wc')
          ) {
            isRestroom.value = true
          }

          let floor = await getFloorById(fetchedPoi.floors.ids[0])
          fetchedPoi['floor'] = floor
          const localTimezone = 'Europe/Berlin'
          let today = moment().tz(localTimezone).format('YYYY-MM-DD')
          if (isPIA) {
            fetchedPoi.availableFrom = '06:00'
            fetchedPoi.availableUntil = '20:00'
          }
          fetchedPoi.availableFrom = momentTZ
            .tz(`${today} ${fetchedPoi.availableFrom}`, 'YYYY-MM-DD HH:mm', localTimezone)
            .utc()
            .add(2, 'hours')
            .toDate()
          fetchedPoi.availableUntil = momentTZ
            .tz(`${today} ${fetchedPoi.availableUntil}`, 'YYYY-MM-DD HH:mm', localTimezone)
            .utc()
            .add(2, 'hours')
            .toDate()

          slotsStart.value = fetchedPoi.availableFrom
          slotsEnd.value = fetchedPoi.availableUntil
          if (fetchedPoi?.configs['room-control'] !== undefined && fetchedPoi?.configs['room-control'] === 'true') {
            const hardwareTypes = ['BLINDS', 'VENTILATOR', 'LIGHT', 'HEATER']
            const hardwarePromises = hardwareTypes.map(type => getHardwareByType(fetchedPoi.buildingId, type).catch(() => []))
            const hardwareArrays = await Promise.all(hardwarePromises)
            const hardwareData = Object.fromEntries(hardwareTypes.map((type, index) => [type, hardwareArrays[index]]))
            fetchedPoi['LIGHT'] = hardwareData['LIGHT'].filter(light => light.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['VENTILATOR'] = hardwareData['VENTILATOR'].filter(ventilator => ventilator.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['HEATER'] = hardwareData['HEATER'].filter(heater => heater.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['BLINDS'] = hardwareData['BLINDS'].filter(blind => blind.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['hasHardware'] = true
          }
          let elevators = await getElevators(fetchedPoi.buildingId)
          let entrances = []
          elevators.forEach((elevator, index) => {
            let ENTRANCES = elevator.entrances
            ENTRANCES.forEach(entrance => {
              if (entrance?.location?.floorId === floor.id) {
                entrance['elevator'] = elevator
                entrances.push(entrance)
              }
            })
          })
          fetchedPoi['entrances'] = entrances
          if (entrances?.length > 0) {
            fetchedPoi['hasEntrances'] = true
          }

          poi.value = fetchedPoi
          poiCategory.value = fetchedCategory

          // Fetch and set POI image
          await fetchPoiImage(fetchedCategory)

          // Set description text
          if (poi.value.description.length > 100) {
            longDescription.value = true
            trimmedDescription.value = poi.value.description.substring(0, 100) + '... '
          }
          fullDescription.value = poi.value.description

          // Additional setup logic as needed...
        } catch (error) {
          console.error(error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        goBack,
        getPoiName,
        poiImage,
        openMap,
        poi,
        goToBooking,
        readMore,
        longDescription,
        toggleReadMore,
        trimmedDescription,
        fullDescription,
        showIncidents,
        formatDate,
        getIncidentColor,
        sortedIncidents,
        showCalendarModal,
        getAvailabiltyText,
        mapView,
        slotsStart,
        slotsEnd,
        originalPoi,
        disabledDates,
        selectedBuilding,
        isRestroom,
        showLoader,
        availabilityDialog,
        selectedDate,
        maxDate,
        handleAvailability,
        openDatePicker,
        discardDate,
        confirmDate,
        bookings,
        freeBookings,
        incidents,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .poi-details {
    .disabled-timeslot {
      pointer-events: none;
      opacity: 0.6;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    .modal-content {
      background: #1a202c;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      max-width: 500px;
      width: 100%;
    }
    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .cancel-button,
    .ok-button {
      margin: 0 10px;
      padding: 10px 20px;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
    .cancel-button {
      background-color: #f44336;
      color: white;
    }
    .cancel-button:hover {
      background-color: #d32f2f;
    }
    .ok-button {
      background-color: #4caf50;
      color: white;
    }
    .ok-button:hover {
      background-color: #388e3c;
    }
    .no-shadow {
      box-shadow: none !important;
    }
    .v-dialog .v-dialog--active {
      box-shadow: none;
    }
    .v-dialog:not(.v-dialog--fullscreen) {
      max-height: 100%;
      box-shadow: none;
    }
  }
</style>
