<template>
  <div class="slider-container">
    <span class="thermometer-icon blue-thermometer">🌡</span>
    <div class="slider-wrapper">
      <input
        type="range"
        min="0"
        max="100"
        :step="stepValue"
        class="slider"
        v-model.number="temperature"
        @input="updateTrackBackground"
      />
      <div class="slider-track-background"></div>
    </div>
    <span class="thermometer-icon yellow-thermometer">🌡️</span>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue'

  export default {
    name: 'TemperatureSlider',
    props: {
      value: {
        type: Number,
        default: 50,
      },
      steps: {
        type: Number,
        default: 1,
      },
    },
    setup(props, { emit }) {
      const temperature = ref(props.value)

      const updateTrackBackground = () => {
        emit('input', temperature.value)
        emit('temperature-change', temperature.value)
      }

      watch(
        () => props.value,
        newValue => {
          temperature.value = Number(newValue)
          updateTrackBackground()
        }
      )

      onMounted(() => {
        updateTrackBackground()
      })

      return {
        temperature,
        updateTrackBackground,
        stepValue: props.steps,
      }
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  .slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    background-color: azure;
    border-radius: 1em;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1em;
  }

  .slider-wrapper {
    position: relative;
    width: calc(100% - 3em);
    margin: 0 1em;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 1em;
    background: transparent;
    outline: none;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  .slider-track-background {
    position: absolute;
    top: 0.5em;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.5em;
    border-radius: 0.25em;
    background: linear-gradient(
      to right,
      rgba(0, 0, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 0, 1) 100%
    );
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    background: #555;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }

  .slider::-moz-range-thumb {
    width: 1.5em;
    height: 1.5em;
    background: #555;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }

  .thermometer-icon {
    font-size: 2em;
  }

  .blue-thermometer {
    color: #3498db;
  }

  .yellow-thermometer {
    color: #f1c40f;
  }

  @media (max-width: 600px) {
    .slider::-webkit-slider-thumb,
    .slider::-moz-range-thumb {
      width: 1em;
      height: 1em;
    }

    .thermometer-icon {
      font-size: 1.5em;
    }
  }
</style>
