<template>
  <div class="homeview">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Chat' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid class="py-6 chat-container">
      <vue-advanced-chat
        :current-user-id="ownUser?.email"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        :show-footer="true"
        :show-send-icon="true"
        :show-audio="false"
        :single-room="true"
        :show-files="false"
        :show-add-room="false"
        :rooms="JSON.stringify(rooms)"
        :show-new-messages-divider="true"
        :show-reaction-emojis="false"
        @send-message-reaction="sendMessageReaction"
        :show-emojis="false"
        @send-message="sendMessage"
        @edit-message="editMessage"
        @delete-message="deleteMessage"
        height="calc(100vh - 200px)"
      />
    </v-container>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted } from 'vue'
  import Loader from '@/components/general/Loader.vue'
  import { getStoredItem, requestNotificationPermission } from '@/services/utils'
  import { useRoute } from 'vue-router'
  import { register } from 'vue-advanced-chat'
  import lodash from 'lodash'
  import user_avatar from '@/assets/General/user_avatar.png'
  import user_avatar2 from '@/assets/General/user_avatar2.png'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { getChatOfUser, getAllChats, postChatMessage, getChatById, deleteChatById, updateChatById } from '@/controllers/BaseController'
  import moment from 'moment'

  register()

  export default defineComponent({
    name: 'Chat',
    components: {
      Loader,
    },
    setup() {
      const logo = ref('')
      const showLoader = ref(false)
      const selectedUser = ref(null)
      const ownUser = ref(null)
      const messages = ref([])
      const messagesLoaded = ref(false)
      const route = useRoute()
      const rooms = ref([])
      let apiChatItems = ref([])

      const sendMessageReaction = content => {
        let reaction = content?.detail[0]
        let SelectedUser = JSON.parse(JSON.stringify(selectedUser.value))
        let OwnUser = JSON.parse(JSON.stringify(ownUser.value))
        let reactionMessage = reaction?.reaction?.unicode
        let Messages = JSON.parse(JSON.stringify(messages.value))

        let messageIndex = Messages.findIndex(message => message._id === reaction?.messageId)

        if (messageIndex !== -1) {
          if (!Messages[messageIndex].reactions) {
            Messages[messageIndex].reactions = {}
          }

          if (!Messages[messageIndex].reactions[reactionMessage]) {
            Messages[messageIndex].reactions[reactionMessage] = []
          }

          const userReactionIndex = Messages[messageIndex].reactions[reactionMessage].indexOf(OwnUser.email)

          if (userReactionIndex === -1) {
            Messages[messageIndex].reactions[reactionMessage].push(OwnUser.email)
          } else {
            Messages[messageIndex].reactions[reactionMessage].splice(userReactionIndex, 1)

            if (Messages[messageIndex].reactions[reactionMessage].length === 0) {
              delete Messages[messageIndex].reactions[reactionMessage]
            }
          }

          messages.value = Messages
          console.log(reactionMessage)
        }
      }

      const sendMessage = content => {
        let message = content?.detail[0]
        let SelectedUser = JSON.parse(JSON.stringify(selectedUser.value))
        let OwnUser = JSON.parse(JSON.stringify(ownUser.value))
        let isReplyMessage = message?.replyMessage === null ? false : true
        const newMessage = {
          _id: Math.floor(1000 + Math.random() * 9000).toString(),
          content: message.content,
          senderId: OwnUser.email,
          replyMessage: isReplyMessage ? JSON.parse(JSON.stringify(message?.replyMessage)) : null,
          username: OwnUser.title,
          avatar: user_avatar2,
          timestamp: new Date().toLocaleTimeString(),
          seen: true,
          disableReactions: true,
        }
        messages.value.push(newMessage)
        try {
          let response = postChatMessage(SelectedUser?.profile?.id, message.content)
          console.log(response)
        } catch (error) {
          console.error('Failed to send message:', error)
        }
      }

      const editMessage = async content => {
        try {
          const { messageId, newContent } = content.detail[0]
          const Messages = JSON.parse(JSON.stringify(messages.value))

          const messageIndex = Messages.findIndex(message => message._id === messageId)

          if (messageIndex !== -1) {
            Messages[messageIndex].content = newContent
            let chatMessages = JSON.parse(JSON.stringify(apiChatItems.value))

            let chatId = Messages[messageIndex]['_id']

            let originalChatObj = lodash.find(chatMessages, chat => chat.id === chatId)
            originalChatObj['message'] = newContent
            let response = await updateChatById(chatId, originalChatObj)
            console.log(response)
            messages.value = Messages
          }
        } catch (error) {
          console.log('Failed to edit message:', error)
        }
      }

      const deleteMessage = async content => {
        try {
          const { message } = content.detail[0]
          const Messages = JSON.parse(JSON.stringify(messages.value))

          const messageIndex = Messages.findIndex(Message => Message._id === message?._id)
          if (messageIndex !== -1) {
            Messages[messageIndex].deleted = true
            let chatId = Messages[messageIndex]['_id']
            let response = await deleteChatById(chatId)
            console.log(response)
            messages.value = Messages
          }
        } catch (error) {
          console.error('Failed to delete message:', error)
        }
      }

      onMounted(async () => {
        try {
          showLoader.value = true
          logo.value = await getToolbarLogoImage()
          let user = await getStoredItem('selectedUser')
          let ownUserProfile = await getStoredItem('ownUser')
          selectedUser.value = user
          ownUser.value = ownUserProfile

          await requestNotificationPermission() // Request notification permissions here

          let chats = await getAllChats()
          console.log(chats)
          if (chats.length > 0) {
            let chat = lodash.find(chats, chat => chat?.userProfileId2 === user?.profile?.id || chat?.userProfileId1 === user?.profile?.id)

            if (chat) {
              let chatItems = []
              let chatMessages = await getChatById(chat?.id)
              chatMessages?.chatItems.items.forEach(chatItem => {
                chatItems.push(chatItem)
              })

              apiChatItems.value = chatItems

              messages.value = chatItems.map(chatItem => ({
                _id: chatItem.id,
                indexId: chatItem.version, // Assuming version is unique
                content: chatItem.message,
                senderId: chatItem.creatorProfile.id === user?.profile?.id ? user?.email : ownUserProfile?.email,
                username: chatItem.creatorProfile.id === ownUserProfile?.profile?.id ? ownUserProfile.title : user.title,
                avatar: chatItem.creatorProfile.id === ownUserProfile.id ? user_avatar2 : user_avatar,
                date: moment(chatItem.createdAt).format('YYYY-MM-DD'),
                timestamp: moment(chatItem.createdAt).format('hh:mm:ss A'),
                system: false,
                saved: true,
                distributed: true,
                seen: true,
                deleted: false,
                failure: false,
                disableActions: false,
                disableReactions: true,
              }))
            }
          }

          messagesLoaded.value = true

          rooms.value = [
            {
              roomId: '1',
              roomName: user?.title,
              avatar: user_avatar,
              users: [
                {
                  _id: user?.email,
                  username: user?.title,
                  avatar: user_avatar,
                },
                {
                  _id: ownUserProfile?.email,
                  username: ownUserProfile?.title,
                  avatar: user_avatar2,
                },
              ],
              typingUsers: [ownUserProfile?.email],
            },
          ]
        } catch (error) {
          console.error('Failed to retrieve user data:', error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        logo,
        showLoader,
        selectedUser,
        ownUser,
        messages,
        messagesLoaded,
        sendMessage,
        sendMessageReaction,
        editMessage,
        deleteMessage,
        route,
        rooms,
      }
    },
  })
</script>

<style scoped>
  .custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
  }

  .custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px); /* Adjust according to your toolbar height */
  }
</style>
