export const categories = [
  {
    id: '135f7873-5ced-4c6c-b5b6-95388e4292f6',
    version: '3fec6740-7a56-41ca-9305-25375e8611a4',
    tags: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Lager',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        'd1409ea3-0303-4011-825d-d573d1cf0815',
        '6c693d68-01d8-4acc-9867-8dbba2d04d94',
        '20b2704c-f40c-4d70-8e31-a728df241501',
        '06e82361-5dd4-4ecc-b381-29d214f49ef8',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  {
    id: '5f175c67-4032-4ec5-a1e8-8c74ce1b0536',
    version: '124fc82c-c4f9-453e-950b-77bfd1b8a8c9',
    tags: {
      'poi-category-icon-name': 'wc',
      'poi-category-color': '950006',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'b2b36fc8-5151-4240-b7a9-c434423a4b6a',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Restrooms',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        '1f0ed459-c9e3-4fc1-899c-c66ef37550a2',
        'bca835cb-a88e-461e-8b71-04dbd4938c89',
        '57d04fcd-1bbf-4ef3-aefd-9af2daf6cd7d',
        'd601270d-21b5-467f-aa7f-379de0c7dd85',
        'e190b91c-8843-4296-a8b1-8b819c80038e',
        'ac049029-7f2a-4958-bc7a-6ad1352b7148',
        '8e920bbe-041f-46e9-a845-0a989a1160cf',
        '83e6cf4c-d5d5-4441-9bb0-00276357eff1',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  {
    id: '64e664f6-0417-4bf1-b4af-f5eda7e2e741',
    version: '96e3d391-196d-4706-a763-db368c5307f6',
    tags: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '7c118035-5c4f-4d75-ab62-b3adfb891977',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Studio',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        '941165a6-1ede-47dd-8ae8-c1e78bef1f95',
        '865889fe-eb3c-4adb-b3d4-0414ca378522',
        '45c4ae5d-da4e-474c-9bfd-d502984868e4',
        '6a2354a7-1aa6-4dcf-a3ff-7b7c526ce7c8',
        'fd8fcb9c-0a4e-4d5c-a868-526f25c5f57e',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  {
    id: '88482232-8e5d-4598-b885-c026d93fd983',
    version: 'c33771c7-ef24-455b-a236-6f20e57840b8',
    tags: {
      'poi-category-icon-name': 'drafts',
      'poi-category-color': '3FBEC6',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'a6800906-bc0a-4062-863a-4b876083e15a',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Printer',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: ['a0789dce-287f-420a-ba7e-981e8f3efea1', '532597fd-c0bd-40b3-8e2b-947630bbe3ce'],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  {
    id: '92be7d4e-2321-424c-872a-d02fce970ca6',
    version: '8ac7073b-7a5c-402f-ad7f-9e4e9785e456',
    tags: {
      'poi-category-icon-name': 'restaurant',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '676be7c5-43c6-47e5-af39-8ee8cfb075c7',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Food and Beverages',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        '5a6bc4a9-21be-4110-aa2e-70edafde8371',
        'b41280c8-194b-41f1-82e3-31ef7e6f72c0',
        '315586d7-8cb6-49b8-9a50-33700c589f13',
        '0335946c-784a-48fe-96b5-06e214ad7d5e',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  // {
  //   id: 'a389e635-632c-4f9f-811e-21ce079376c5',
  //   version: '2f893377-268d-42c1-aaa1-64af21b2f66e',
  //   tags: {
  //     'poi-category-icon-name': 'pool',
  //     'poi-category-color': '0160BA',
  //   },
  //   files: {
  //     type: 'files',
  //     entities: [
  //       {
  //         id: 'e12c60fb-245f-422b-9bcd-ddf349bd26ee',
  //         type: 'image',
  //       },
  //     ],
  //   },
  //   customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
  //   name: 'Showers',
  //   active: true,
  //   subCategories: [],
  //   pois: {
  //     type: 'pois',
  //     ids: [],
  //   },
  //   campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
  //   type: 'poicategories',
  // },
  {
    id: 'bfcaea7c-17a5-4434-a729-dbcbccdc58ba',
    version: 'f4a4a21f-9967-4e73-bbbb-7f39cc372591',
    tags: {
      'poi-category-icon-name': 'supervisor_account',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: '20e3ad74-a560-4f5b-8373-c4b988726542',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Meeting room',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        'ef51ec3f-c608-49e7-8543-7a053fa19243',
        '875d660d-7d76-4b47-9117-c057c023e274',
        '2658ac2b-1273-4632-9e40-04e53f87cf88',
        '88eb9395-36c4-4bff-9353-95c515aa1541',
        'f57539bf-5038-474c-bff5-a574d8ea20ba',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  {
    id: 'cec94c70-0f3b-44a1-b5f9-11e030a6d542',
    version: '63d846db-6bfc-4eee-93d7-d7db03d328a1',
    tags: {
      'poi-category-icon-name': 'accessibility',
      'poi-category-color': 'A825A4',
    },
    files: {
      type: 'files',
      entities: [
        {
          id: 'ce7ce95e-30a9-4002-8b1c-48e62bc622e2',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'LAB',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        'bee02ecb-fa87-45de-a02e-9df13b3a6e12',
        '821e64e4-a7c3-402a-96c0-ae5b1c8c00c2',
        'e1757257-feec-40aa-bcb9-73f8a6efab8c',
        'cba5c05a-37af-4bfd-92df-43da68292a07',
        'bae09d78-e67c-4580-bc7e-9aa244009c7f',
        'cadb8d4b-7cde-462a-b2b1-1748414fa35a',
        '1e98fddb-6250-49c7-8201-1f3a8faf230c',
        '57dcd420-cbc8-408c-a455-d4081bd5b87d',
        'b49e36c9-789d-4d77-b3b8-f4cf0f51e38a',
        '961a2864-237b-4eca-802e-10275721a6b3',
        'ec0188f0-9ea9-4559-aa9b-abed6ec33a22',
        '31c6dce9-d111-486a-ace9-fe7eebe2b176',
        'f8e5af9f-dbd2-4b84-a902-d8b795195eda',
        'a5664723-8330-487b-931d-70d176867c5e',
        'd4a66b1f-facf-43b6-95c9-796f29b1bc8c',
        '5c1b5eea-5325-471c-925e-3e1dd08b681f',
        'f0a11c45-16a1-4b21-b854-2a3460f439d0',
        '11df972e-2e3f-4233-8af9-9203b34be8ec',
        'b55a56ac-be16-4164-9816-9730ebe71525',
        '1f27a3bd-89e4-49c4-aa91-cdc2f8599151',
        'b857902a-9f6a-4414-b03b-7bd7118f4f02',
        'ebbd1f67-32d5-464a-b3fd-67f91ac688a4',
        '286eb731-0703-4c95-a547-0205a5025fea',
        '49263f4f-6ce3-41f6-9c32-15e470caa246',
        'c3969a1e-8af6-4bd1-90c6-38012a28cd6a',
        'a68aa145-3f45-4e8f-8ae7-3d5dcbc2ad97',
        'f0656377-7a5f-4bcd-b6fd-0c8092036014',
        '443ee6f9-7cef-420b-a8d7-91dec848df4e',
        '87d04688-fd5c-4ca7-a25c-5c6002228453',
        '1aec822c-9da3-4ae8-8ec3-40b435250d37',
        'ddf52128-9ff3-405a-bbbe-8033c1390f2f',
        '964fd8a7-af3c-406a-b1f3-1b1227cdff23',
        '6c6ede9f-1113-4cfb-a42d-12b05ffcd30a',
        '5e306f9c-b4d8-4e47-9ff6-66dc63fd5d56',
        '3262f6be-c114-4339-8f24-d2b9e29ff501',
        '5c8b2e76-366c-4dfa-b751-56ca3dc9058a',
        'a617c9a6-5ae4-42b4-9ec5-9c8d22f84267',
        '92d2a842-d0fc-45b1-b49b-9134c44c3749',
        '40863863-b98c-4180-a2cd-7b283c65d0de',
        'ed40fd39-1a2b-418e-89b2-1b21df5d3368',
        'db6712f0-6874-4d01-b32b-ee9fc1b7861a',
        'd395946f-370b-4aa4-b54a-dc828669340a',
        '32656a1c-631e-411f-8240-03784fc2b2fd',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
  {
    id: 'ec1699ca-d7bb-4c6f-8873-34a53a9b9dcd',
    version: '6e6c705d-ca29-43e2-ba1d-5e0407f8385b',
    tags: {},
    files: {
      type: 'files',
      entities: [
        {
          id: '5ecbb652-821f-48a1-b2d5-21e54c045e12',
          type: 'image',
        },
      ],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Event space',
    active: true,
    subCategories: [],
    pois: {
      type: 'pois',
      ids: [
        '62091d04-8a16-4d23-b152-ac45fbbc2e63',
        '85def8f2-679a-45f9-9911-91372b8732bd',
        '759b3008-fb81-4a06-b9eb-33363eaa0e64',
        '4ff91bf2-0f19-48f2-bd5e-75d4678b2176',
        '0a8da651-4563-469c-840f-2fcb913fad90',
        '6344992c-b455-4305-8757-d37c98e170d9',
        'b14c4453-e5fd-4ce0-9d14-5df67accbdd2',
        'c9a97b81-1e15-486c-9918-bafc9f5bc660',
      ],
    },
    campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
    type: 'poicategories',
  },
]

export const getPoiCategoryById = id => {
  const foundCategory = categories.find(category => category.id === id)
  if (foundCategory) {
    return foundCategory
  } else {
    return null
  }
}
