<template>
  <v-card class="pa-4 mb-4 pyramid-card" :style="{ opacity: showLoader ? 0 : 1, transition: 'opacity 0.5s' }">
    <apexchart
      v-if="!showLoader"
      ref="pyramidChart"
      type="bar"
      :options="pyramidChartOptions"
      :series="pyramidChartData"
      width="100%"
      :height="computedChartHeight"
    ></apexchart>
  </v-card>
</template>

<script>
  import { ref, defineComponent, watch, onMounted, computed } from 'vue'
  import VueApexCharts from 'vue3-apexcharts'
  import moment from 'moment'

  export default defineComponent({
    name: 'ReservationsPyramidChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      reservations: {
        type: Array,
        required: true,
      },
      selectedTimeRange: {
        type: String,
        required: true,
      },
      selectedCategory: {
        type: Object,
        required: true,
      },
      selectedFloor: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const showLoader = ref(true)
      const pyramidChartData = ref([])
      const pyramidChartOptions = ref({
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [], // Will be dynamically updated
          labels: {
            style: {
              colors: [],
              fontSize: '12px',
            },
          },
        },
        title: {
          text: 'Reservations Distribution',
          align: 'center',
        },
        colors: [], // Will be dynamically updated
      })

      const makeColor = (colorNum, colors) => {
        if (colors < 1) colors = 1 // avoid divide by zero
        return `hsl(${(colorNum * (360 / colors)) % 360}, 100%, 50%)`
      }

      const processPyramidData = () => {
        const filteredReservations = filterReservations()

        if (!filteredReservations || filteredReservations.length === 0) {
          pyramidChartData.value = []
          pyramidChartOptions.value.xaxis.categories = []
          return
        }

        const dataByGroup = {}

        if (props.selectedCategory.name === 'All Categories') {
          filteredReservations.forEach(reservation => {
            const category = reservation.poiCategory
            if (!dataByGroup[category]) {
              dataByGroup[category] = 0
            }
            dataByGroup[category] += 1
          })
        } else {
          filteredReservations.forEach(reservation => {
            const poi = reservation.poiName
            if (!dataByGroup[poi]) {
              dataByGroup[poi] = 0
            }
            dataByGroup[poi] += 1
          })
        }

        const sortedData = Object.entries(dataByGroup)
          .sort((a, b) => b[1] - a[1])
          .map(([group, count]) => ({ group, count }))

        pyramidChartData.value = [
          {
            name: 'Reservations',
            data: sortedData.map(item => item.count),
          },
        ]

        const colorsCount = sortedData.length
        pyramidChartOptions.value.xaxis.categories = sortedData.map(item => item.group)
        pyramidChartOptions.value.colors = sortedData.map((_, index) => makeColor(index, colorsCount))
        pyramidChartOptions.value.xaxis.labels.colors = pyramidChartOptions.value.colors
      }

      const filterReservations = () => {
        let filteredReservations = props.reservations || []
        let startDate, endDate

        switch (props.selectedTimeRange) {
          case 'today':
            startDate = moment().startOf('day')
            endDate = moment().endOf('day')
            break
          case 'yesterday':
            startDate = moment().subtract(1, 'day').startOf('day')
            endDate = moment().subtract(1, 'day').endOf('day')
            break
          case 'tomorrow':
            startDate = moment().add(1, 'day').startOf('day')
            endDate = moment().add(1, 'day').endOf('day')
            break
          case 'thisWeek':
            startDate = moment().startOf('week')
            endDate = moment().endOf('week')
            break
          case 'lastWeek':
            startDate = moment().subtract(1, 'week').startOf('week')
            endDate = moment().subtract(1, 'week').endOf('week')
            break
          case 'currentMonth':
            startDate = moment().startOf('month')
            endDate = moment().endOf('month')
            break
          case 'lastMonth':
            startDate = moment().subtract(1, 'month').startOf('month')
            endDate = moment().subtract(1, 'month').endOf('month')
            break
          case 'nextMonth':
            startDate = moment().add(1, 'month').startOf('month')
            endDate = moment().add(1, 'month').endOf('month')
            break
        }

        filteredReservations = filteredReservations.filter(reservation => {
          const reservationDate = moment(reservation.startTime)
          return reservationDate.isBetween(startDate, endDate, null, '[]')
        })

        if (props.selectedCategory.name !== 'All Categories') {
          filteredReservations = filteredReservations.filter(reservation => reservation.poiCategory === props.selectedCategory.name)
        }

        if (props.selectedFloor.name !== 'All Floors') {
          filteredReservations = filteredReservations.filter(reservation => reservation.floorName === props.selectedFloor.name)
        }

        return filteredReservations
      }

      const computedChartHeight = computed(() => {
        const categoriesCount = pyramidChartOptions.value.xaxis.categories.length
        if (categoriesCount > 16) {
          return 'auto'
        } else if (categoriesCount > 8) {
          return 700
        } else {
          return 350
        }
      })

      watch([() => props.selectedTimeRange, () => props.selectedCategory, () => props.selectedFloor], () => {
        showLoader.value = true
        setTimeout(() => {
          processPyramidData()
          showLoader.value = false
        }, 500)
      })

      onMounted(() => {
        processPyramidData()
        showLoader.value = false
      })

      return {
        showLoader,
        pyramidChartOptions,
        pyramidChartData,
        computedChartHeight,
      }
    },
  })
</script>

<style scoped>
  .pyramid-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
</style>
