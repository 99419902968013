// userOfficeData.js

export const vouchers = [
  {
    id: 1,
    name: 'Free Lunch',
    description: 'Get a free lunch',
    expired: false,
    daysLeft: 15,
    type: 'lunch',
  },
  {
    id: 2,
    name: 'Early Leave',
    description: 'Leave 2 hours early',
    expired: false,
    daysLeft: 12,
    type: 'leave',
  },
  {
    id: 3,
    name: 'Extra Break',
    description: 'Additional 15-minute break',
    expired: false,
    daysLeft: 20,
    type: 'break',
  },
  {
    id: 4,
    name: 'Work from Home Day',
    description: 'Work from home for a day',
    expired: false,
    daysLeft: 5,
    type: 'work_from_home',
  },
  {
    id: 5,
    name: 'Gym Access',
    description: 'One month gym access',
    expired: true,
    daysLeft: 0,
    type: 'gym',
  },
  {
    id: 6,
    name: 'Parking Spot',
    description: 'Reserved parking spot for a week',
    expired: true,
    daysLeft: 0,
    type: 'parking',
  },
]
