<template>
  <div>
    <v-row justify="center" v-for="(chunk, index) in chunkedPoiData" :key="index">
      <v-col cols="12">
        <v-card class="pa-4 mb-4 heatmap-card">
          <apexchart
            :ref="'heatmapChart' + index"
            width="100%"
            height="350"
            type="heatmap"
            :options="getHeatmapOptions(index)"
            :series="chunk"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue'
  import VueApexCharts from 'vue3-apexcharts'
  import moment from 'moment'

  export default {
    name: 'PoiHeatMap',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      reservations: {
        type: Array,
        required: true,
      },
      selectedTimeRange: {
        type: String,
        required: true,
      },
      selectedCategory: {
        type: Object,
        required: true,
      },
      selectedFloor: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const chunkedPoiData = ref([])
      const poiHeatmapData = ref([])

      const chunkPoiData = () => {
        const chunkSize = 8
        const result = []
        for (let i = 0; i < poiHeatmapData.value.length; i += chunkSize) {
          result.push(poiHeatmapData.value.slice(i, i + chunkSize))
        }
        chunkedPoiData.value = result
      }

      const processPoiHeatmapData = () => {
        const filteredReservations = props.reservations.filter(reservation => reservation.poiCategory === props.selectedCategory.name)

        if (!filteredReservations.length) {
          poiHeatmapData.value = []
          return
        }

        const dataByPoi = {}
        if (['today', 'yesterday', 'tomorrow'].includes(props.selectedTimeRange)) {
          // Handle hourly slots
          filteredReservations.forEach(reservation => {
            const poiName = reservation.poiName
            if (!dataByPoi[poiName]) {
              dataByPoi[poiName] = Array(15).fill(0) // 15-hour slots assuming 6:00 AM to 9:00 PM
            }
            const hour = moment(reservation.startTime).hour() - 6 // Assuming starting at 6:00 AM
            if (hour >= 0 && hour < 15) {
              dataByPoi[poiName][hour] += 1
            }
          })

          poiHeatmapData.value = Object.keys(dataByPoi).map(poi => ({
            name: poi,
            data: dataByPoi[poi].map((count, index) => ({
              x: `${index + 6}:00`, // Display hour as "6:00", "7:00", etc.
              y: count,
            })),
          }))
        } else {
          // Handle daily slots
          filteredReservations.forEach(reservation => {
            const poiName = reservation.poiName
            const date = moment(reservation.startTime).format('DD-MM')
            if (!dataByPoi[poiName]) {
              dataByPoi[poiName] = {}
            }
            if (!dataByPoi[poiName][date]) {
              dataByPoi[poiName][date] = 0
            }
            dataByPoi[poiName][date] += 1
          })

          poiHeatmapData.value = Object.keys(dataByPoi).map(poi => ({
            name: poi,
            data: Object.keys(dataByPoi[poi]).map(date => ({
              x: date, // Display date as "DD-MM"
              y: dataByPoi[poi][date],
            })),
          }))
        }

        chunkPoiData()
      }

      const getHeatmapOptions = index => ({
        chart: { id: `heatmap-chart-${index}` },
        xaxis: {
          type: 'category',
          title: {
            text: ['today', 'yesterday', 'tomorrow'].includes(props.selectedTimeRange) ? 'Time Slots' : 'Dates',
          },
        },
        yaxis: { title: { text: 'POI Names' }, type: 'category' },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                { from: 0, to: 3, color: '#00A100', name: 'Low' },
                { from: 4, to: 7, color: '#FFB200', name: 'Medium' },
                { from: 8, to: 100, color: '#FF0000', name: 'High' },
              ],
            },
          },
        },
      })

      onMounted(() => {
        processPoiHeatmapData()
      })

      watch(
        () => [props.reservations, props.selectedCategory, props.selectedTimeRange],
        () => {
          processPoiHeatmapData()
        }
      )

      return {
        chunkedPoiData,
        getHeatmapOptions,
      }
    },
  }
</script>

<style scoped>
  .heatmap-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
</style>
