<template>
  <!-- eslint-disable -->
  <v-dialog v-model="showMap" :max-width="dialogWidth" :max-height="dialogHeight">
    <v-card class="elegant-card">
      <v-card-title class="headline controls" style="margin-top: 0.5rem">
        {{ 'Floor Plan' }}
        <v-spacer></v-spacer>
        <v-btn class="floor-btn" @click="toggleFloorDropdown" prepend-icon="mdi-layers" variant="tonal">
          <span class="floor-text">{{ selectedFloorNumber }}</span>
        </v-btn>
        <div v-if="floorDropdown" class="floor-dropdown">
          <div
            v-for="floor in lodash.sortBy(floors, 'number')"
            :key="floor.number"
            class="floor-item"
            :class="{ selected: floor.number === selectedFloorNumber }"
            @click="selectFloor(floor)"
          >
            {{ floor.number }}
          </div>
        </div>
        <v-btn style="background-color: lightgray" icon @click="checkPresence" class="polygon-toggle-btn">
          <v-icon style="color: black">mdi-calendar</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div id="map-container"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="showMap = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { ref, defineComponent, onMounted, nextTick } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getFile, getDataFromBlob, getFloors } from '@/controllers/BaseController'
  import lodash from 'lodash'

  export default defineComponent({
    name: 'GoogleMapsView',
    setup() {
      const showMap = ref(false)
      const zoom = ref(20)
      const markers = ref([])
      const polygons = ref([])
      const selectedFloor = ref({})
      const selectedFloorNumber = ref(null)
      const showLoader = ref(false)
      const floors = ref([])
      const selectedBuilding = ref({})
      const categories = ref([])
      const pois = ref([])
      const route = useRoute()
      const router = useRouter()
      const map = ref(null)
      const floorDropdown = ref(false)
      const polygonsVisible = ref(false)
      const dialogWidth = ref('calc(100vw - 10px)') // 5px margin on each side
      const dialogHeight = ref('calc(100vh - 10px)') // 5px margin on each side

      const apiKey = 'AIzaSyDxDRhw0SKKTzxzidZu0FJCeZtk1ooMQKo'

      async function openMap(selectedBuildingParam, poisParam, categoriesParam) {
        showLoader.value = true
        floors.value = []
        selectedBuilding.value = selectedBuildingParam
        pois.value = lodash.filter(poisParam, item => item.buildingId === selectedBuildingParam.id)
        categories.value = categoriesParam

        try {
          let fetchedFloors = await getFloors()
          fetchedFloors = lodash.filter(fetchedFloors, floor => {
            if (!floor.building || !floor.northEastCorner || !floor.southWestCorner) {
              return false
            }
            return floor.building.id === selectedBuildingParam.id && floor.active
          })

          if (categories.value?.length === 1) {
            const categoryPoiIds = new Set(categories.value[0].pois.ids)
            fetchedFloors = fetchedFloors.filter(floor => floor.pois.ids.some(poiId => categoryPoiIds.has(poiId)))
          }

          fetchedFloors = fetchedFloors.sort((a, b) => a.number - b.number)

          const floorIds = new Set(pois.value.map(poi => poi?.floors?.ids[0]))
          fetchedFloors = fetchedFloors.filter(floor => floorIds.has(floor.id))

          if (route.name === 'poiDetails') {
            fetchedFloors = [pois.value[0]?.floor]
            floors.value = fetchedFloors
          }

          const floorPromises = fetchedFloors.map(async (floor, index) => {
            let floorPlan = floor.configs?.floorplan
            if (!floor.northEastCorner || !floor.southWestCorner) {
              console.error('Missing corner coordinates for floor:', floor)
              return
            }
            floor['bounds'] = {
              north: floor.northEastCorner.lat,
              south: floor.southWestCorner.lat,
              east: floor.northEastCorner.long,
              west: floor.southWestCorner.long,
            }
            floor['center'] = {
              lat: (floor.northEastCorner.lat + floor.southWestCorner.lat) / 2,
              lng: (floor.northEastCorner.long + floor.southWestCorner.long) / 2,
            }
            if (floorPlan) {
              floorPlan = await getFile(floorPlan)
              floorPlan = await getDataFromBlob(floorPlan)
              floor['floorPlan'] = floorPlan
              floors.value.push(floor)
            } else {
              floor['floorPlan'] = null
              floors.value.push(floor)
            }
            if (index === 0) {
              selectedFloor.value = floor
              selectedFloorNumber.value = floor.number
              getMarkers()
            }
          })

          await Promise.all(floorPromises)
        } catch (error) {
          console.error('Error in openMap:', error)
        } finally {
          showLoader.value = false
          showMap.value = true
          console.log('Map visibility set to true.')
          initializeMap()
        }
      }

      function getMarkers() {
        let poisData = pois.value
        let categoriesData = categories.value
        markers.value = []
        polygons.value = []
        poisData.forEach(poi => {
          if (!poi.locations || !poi.locations[0]) {
            console.error('Skipping POI due to missing location data:', poi)
            return
          }
          let category = lodash.find(categoriesData, category => category?.id === poi?.poiCategories?.ids[0])
          let marker = {
            poiId: poi?.id,
            poi: poi,
            category: category,
            latlong: {
              lat: poi.locations[0].center.lat,
              lng: poi.locations[0].center.long,
            },
          }
          if (marker && poi?.floors?.ids[0] === selectedFloor.value?.id) {
            markers.value.push(marker)
          }

          poi.locations.forEach(location => {
            if (!location.polygon) {
              console.error('Skipping location due to missing polygon data:', location)
              return
            }
            let polygon = location.polygon
            let poiPolygon = {
              poi: poi,
              latLng: polygon
                .map(point => {
                  if (!point.lat || !point.long) {
                    console.error('Skipping point due to missing lat/long:', point)
                    return null
                  }
                  return { lat: point.lat, lng: point.long }
                })
                .filter(Boolean),
              color: '#3EB39F',
              poiId: poi?.id,
              weight: 2,
              fillColor: '#3EB39F',
            }

            if (poiPolygon && poi?.floors?.ids[0] === selectedFloor.value?.id) {
              const latLngExists = polygons.value.some(
                existingPolygon => JSON.stringify(existingPolygon.latLng) === JSON.stringify(poiPolygon.latLng)
              )

              if (!latLngExists) {
                polygons.value.push(poiPolygon)
              }
            }
          })
        })

        polygons.value = lodash.uniq(polygons.value, 'poiId')
      }

      function initializeMap() {
        // eslint-disable-next-line no-undef
        nextTick(() => {
          const mapContainer = document.getElementById('map-container')
          if (!mapContainer) {
            console.error('Map container not found')
            return
          }

          // eslint-disable-next-line no-undef
          map.value = new google.maps.Map(mapContainer, {
            center: selectedFloor.value.center,
            zoom: zoom.value,
          })

          // Add markers
          markers.value.forEach(marker => {
            // eslint-disable-next-line no-undef
            new google.maps.Marker({
              position: marker.latlong,
              map: map.value,
              title: marker.poi.name,
              label: {
                text: marker.poi.name,
                fontSize: '8px', // Smaller font size for labels
              },
              icon: {
                url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                // eslint-disable-next-line no-undef
                scaledSize: new google.maps.Size(16, 16), // Smaller marker size
              },
            })
          })

          // Add image overlay
          if (selectedFloor.value && selectedFloor.value.floorPlan && selectedFloor.value.bounds) {
            // eslint-disable-next-line no-undef
            const overlay = new google.maps.GroundOverlay(
              selectedFloor.value.floorPlan,
              // eslint-disable-next-line no-undef
              new google.maps.LatLngBounds(
                // eslint-disable-next-line no-undef
                new google.maps.LatLng(selectedFloor.value.bounds.south, selectedFloor.value.bounds.west),
                // eslint-disable-next-line no-undef
                new google.maps.LatLng(selectedFloor.value.bounds.north, selectedFloor.value.bounds.east)
              )
            )
            overlay.setMap(map.value)
          }

          // Initialize polygons (hidden by default)
          checkPresence()
        })
      }

      function checkPresence() {
        polygonsVisible.value = !polygonsVisible.value

        // Remove existing polygons
        polygons.value.forEach(polygon => {
          if (polygon.googlePolygon) {
            polygon.googlePolygon.setMap(null)
          }
        })

        // Add new polygons based on visibility state
        polygons.value.forEach(polygon => {
          // eslint-disable-next-line no-undef
          const googlePolygon = new google.maps.Polygon({
            paths: polygon.latLng,
            strokeColor: polygon.color,
            fillColor: polygon.fillColor,
            fillOpacity: polygonsVisible.value ? 0.3 : 0, // Light green fill when visible
            strokeWeight: polygon.weight,
          })
          googlePolygon.setMap(polygonsVisible.value ? map.value : null)
          polygon.googlePolygon = googlePolygon
        })
      }

      function toggleFloorDropdown() {
        floorDropdown.value = !floorDropdown.value
      }

      function selectFloor(floor) {
        selectedFloor.value = floor
        selectedFloorNumber.value = floor.number
        getMarkers() // Update markers and polygons for the selected floor
        initializeMap()
        floorDropdown.value = false
      }

      onMounted(() => {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`
        script.async = true
        script.defer = true
        document.head.appendChild(script)
        window.initMap = initializeMap
      })

      return {
        showMap,
        zoom,
        markers,
        polygons,
        selectedFloor,
        selectedFloorNumber,
        showLoader,
        floors,
        selectedBuilding,
        categories,
        pois,
        openMap,
        getMarkers,
        initializeMap,
        toggleFloorDropdown,
        selectFloor,
        checkPresence,
        floorDropdown,
        polygonsVisible,
        lodash,
        dialogWidth,
        dialogHeight,
      }
    },
  })
</script>

<style scoped>
  .elegant-card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  .elegant-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  #map-container {
    height: 600px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background: #f0f0f0;
  }

  .v-btn {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .v-btn:hover {
    transform: translateY(-2px);
  }

  .v-btn:active {
    transform: translateY(1px);
  }

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px;
    width: 30px;
    box-shadow: none;
  }

  .controls {
    display: flex;
    align-items: center;
  }

  .floor-btn {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .floor-text {
    margin-left: 5px;
    font-weight: bold;
  }

  .polygon-toggle-btn {
    margin-left: 10px;
  }

  .floor-dropdown {
    position: absolute;
    top: 50px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
  }

  .floor-item {
    padding: 8px 16px;
    cursor: pointer;
  }

  .floor-item:hover {
    background: #f0f0f0;
  }

  .floor-item.selected {
    background: #e0e0e0;
  }

  @media (max-width: 600px) {
    .v-dialog__content--active {
      width: calc(100% - 10px) !important;
      max-width: calc(100% - 10px) !important;
      height: calc(100% - 10px) !important;
      max-height: calc(100% - 10px) !important;
    }

    .elegant-card {
      width: 100%;
      max-width: 100%;
      margin: 0 5px;
    }

    #map-container {
      height: 500px;
    }
  }
</style>
