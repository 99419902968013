import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/style.css'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import VueApexCharts from 'vue3-apexcharts'
import { TranslationService, i18n } from '@/services/TranslationService'

loadFonts()

const app = createApp(App)

// Make SweetAlert2 globally available
app.config.globalProperties.$swal = Swal

app
  .use(setupCalendar, {})
  .component('calendar', Calendar)
  .component('date-picker', DatePicker)
  .use(router)
  .use(store)
  .use(VueApexCharts)
  .use(vuetify)
  .use(i18n)
  .mount('#app')

// Translation Service Initialization
TranslationService.initialize(app)
