<template>
  <v-card class="pa-4 mb-4 treemap-card" :style="{ opacity: showLoader ? 0 : 1, transition: 'opacity 0.5s' }">
    <apexchart
      v-if="!showLoader"
      ref="treemapChart"
      width="100%"
      height="350"
      type="treemap"
      :options="treemapChartOptions"
      :series="treemapChartData"
    ></apexchart>
  </v-card>
</template>

<script>
  import { ref, defineComponent, watch, onMounted } from 'vue'
  import VueApexCharts from 'vue3-apexcharts'
  import moment from 'moment'

  export default defineComponent({
    name: 'ReservationsTreeMap',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      reservations: {
        type: Array,
        required: true,
      },
      selectedTimeRange: {
        type: String,
        required: true,
      },
      selectedCategory: {
        type: Object,
        required: true,
      },
      selectedFloor: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const showLoader = ref(true)
      const treemapChart = ref(null)
      const treemapChartData = ref([])

      const treemapChartOptions = ref({
        chart: {
          id: 'treemap-chart',
          height: 350,
          type: 'treemap',
          toolbar: {
            show: true,
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        plotOptions: {
          treemap: {
            enableShades: false,
            distributed: true,
          },
        },
        title: {
          text: 'Bookings by POI',
        },
        legend: {
          show: false,
        },
      })

      const processTreeMapData = () => {
        const filteredReservations = filterReservations()
        if (!filteredReservations || filteredReservations.length === 0) {
          treemapChartData.value = [{ data: [] }]
          return
        }

        const dataByGroup = {}

        if (props.selectedCategory.name === 'All Categories') {
          filteredReservations.forEach(reservation => {
            const category = reservation.poiCategory
            if (!dataByGroup[category]) {
              dataByGroup[category] = 0
            }
            dataByGroup[category] += 1
          })
        } else {
          filteredReservations.forEach(reservation => {
            const poi = reservation.poiName
            if (!dataByGroup[poi]) {
              dataByGroup[poi] = 0
            }
            dataByGroup[poi] += 1
          })
        }

        const colors = generateUniqueColors(Object.keys(dataByGroup).length)

        treemapChartData.value = [
          {
            data: Object.keys(dataByGroup).map((group, index) => ({
              x: group,
              y: dataByGroup[group],
              color: colors[index],
            })),
          },
        ]
      }

      const generateUniqueColors = count => {
        const colors = []
        for (let i = 0; i < count; i++) {
          const hue = (i * 137.508) % 360 // 137.508° is the golden angle in degrees
          colors.push(`hsl(${hue}, 70%, 50%)`)
        }
        return colors
      }

      const filterReservations = () => {
        let filteredReservations = props.reservations || []
        let startDate, endDate

        switch (props.selectedTimeRange) {
          case 'today':
          case 'yesterday':
          case 'tomorrow':
            startDate =
              props.selectedTimeRange === 'today'
                ? moment().startOf('day')
                : props.selectedTimeRange === 'yesterday'
                ? moment().subtract(1, 'day').startOf('day')
                : moment().add(1, 'day').startOf('day')
            endDate = startDate.clone().endOf('day')
            break
          case 'thisWeek':
            startDate = moment().startOf('week')
            endDate = moment().endOf('week')
            break
          case 'lastWeek':
            startDate = moment().subtract(1, 'week').startOf('week')
            endDate = moment().subtract(1, 'week').endOf('week')
            break
          case 'currentMonth':
            startDate = moment().startOf('month')
            endDate = moment().endOf('month')
            break
          case 'lastMonth':
            startDate = moment().subtract(1, 'month').startOf('month')
            endDate = moment().subtract(1, 'month').endOf('month')
            break
          case 'nextMonth':
            startDate = moment().add(1, 'month').startOf('month')
            endDate = moment().add(1, 'month').endOf('month')
            break
        }

        filteredReservations = filteredReservations.filter(reservation => {
          const reservationDate = moment(reservation.startTime)
          return reservationDate.isBetween(startDate, endDate, null, '[]') && ![6, 7].includes(reservationDate.isoWeekday())
        })

        if (props.selectedCategory.name !== 'All Categories') {
          filteredReservations = filteredReservations.filter(reservation => reservation.poiCategory === props.selectedCategory.name)
        }

        if (props.selectedFloor.name !== 'All Floors') {
          filteredReservations = filteredReservations.filter(reservation => reservation.floorName === props.selectedFloor.name)
        }

        return filteredReservations
      }

      watch([() => props.selectedTimeRange, () => props.selectedCategory, () => props.selectedFloor], () => {
        showLoader.value = true
        setTimeout(() => {
          processTreeMapData()
          if (treemapChart.value) {
            treemapChart.value.updateSeries(treemapChartData.value)
          }
          showLoader.value = false
        }, 500)
      })

      onMounted(() => {
        processTreeMapData() // Process the data when the component is first mounted
        if (treemapChart.value) {
          treemapChart.value.updateSeries(treemapChartData.value) // Ensure the chart is updated with the processed data
        }
        showLoader.value = false
      })

      return {
        showLoader,
        treemapChartOptions,
        treemapChartData,
        treemapChart,
      }
    },
  })
</script>

<style scoped>
  .treemap-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
</style>
