<template>
  <div class="feedback-view">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-icon color="black" class="toolbar-icon" size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      <v-toolbar-title class="custom-toolbar-title">{{ isAdmin ? 'Feedback Dashboard' : 'Feedback Form' }}</v-toolbar-title>
    </v-toolbar>

    <v-container v-if="noPermissions" class="no-permissions-container">
      <v-card class="no-permissions-card elevation-4">
        <v-card-title class="text-h5 no-permissions-title">No Permissions</v-card-title>
        <v-card-subtitle class="text-subtitle-1 no-permissions-subtitle">
          You do not have the necessary permissions to view this page.
        </v-card-subtitle>
        <v-btn color="primary" @click="goBack" class="mt-4">Go Back</v-btn>
      </v-card>
    </v-container>

    <v-container v-if="!noPermissions && !isAdmin && !showLoader" class="feedback-container">
      <v-card class="feedback-card elevation-4">
        <v-card-title class="text-h5 feedback-card-title">Submit Your Feedback</v-card-title>
        <v-card-subtitle class="text-subtitle-1 feedback-card-subtitle">We value your feedback to improve our app</v-card-subtitle>

        <v-form class="feedback-form">
          <v-select
            :menu-props="{ maxHeight: '300px' }"
            v-model="feedback.category"
            :items="categories"
            label="Select Category"
            variant="outlined"
            class="mt-4"
            required
          ></v-select>

          <v-rating
            v-model="feedback.rating"
            background-color="yellow lighten-4"
            color="yellow darken-2"
            large
            hover
            label="Rate the app"
            required
            class="star-rating mt-4"
          ></v-rating>

          <v-checkbox v-model="isAnonymous" label="Submit anonymously" @change="toggleAnonymous" class="anonymous-checkbox mt-4"></v-checkbox>
          <v-text-field v-model="feedback.name" label="Your Name" variant="solo" :disabled="isAnonymous" class="name-field mt-4"></v-text-field>

          <v-textarea
            variant="solo"
            v-model="feedback.message"
            label="Your Feedback"
            auto-grow
            rows="5"
            dense
            required
            class="feedback-textarea mt-4"
          ></v-textarea>

          <v-btn :disabled="!canSubmit" color="primary" @click="submitFeedback" class="mt-4 submit-button">Submit</v-btn>
        </v-form>
      </v-card>
    </v-container>

    <AdminFeedbacks
      v-if="!noPermissions && isAdmin && !showLoader"
      :feedbacks="filteredFeedbacks"
      :categories="categories"
      :timeFrames="timeFrames"
    />
  </div>
</template>
<script>
  import { ref, computed, onMounted } from 'vue'
  import Swal from 'sweetalert2'
  import { getPrimaryColor } from '@/assets/Branding/branding.js'
  import { useOAuthService } from '@/services/OAuthService'
  import Loader from '@/components/general/Loader.vue'
  import AdminFeedbacks from '@/components/general/ChildComponents/AdminFeedbacks.vue' // Import the new component
  import moment from 'moment'
  import _ from 'lodash'
  import { useRoute, useRouter } from 'vue-router'

  export default {
    name: 'FeedbackForm',
    components: {
      Loader,
      AdminFeedbacks,
    },
    setup() {
      const isAnonymous = ref(false)
      const OAuthService = useOAuthService()
      const noPermissions = ref(false)
      const showLoader = ref(false)
      const userProfile = ref(null)
      const isAdmin = ref(false)
      const feedbacks = ref([]) // List to hold feedback data
      const searchQuery = ref('')
      const selectedCategory = ref('All Categories')
      const primaryColor = ref('')
      const selectedTimeFrame = ref('Current Year')
      const router = useRouter()
      const route = useRoute()

      const categories = ref([
        'All Categories',
        'UI/UX',
        'Performance',
        'Bug Report',
        'Feature Request',
        'Room Booking',
        'Building View',
        'Hardware Control',
        'Elevator Control',
        'News Center',
        'Motivation Center',
        'Chat/Communication',
        'Analytics',
        'Booking Management',
        'Help Section',
        'Login/Logout',
        'Privacy & GDPR',
        'Language Switching',
        'Accessibility',
        'Security',
        'Notifications',
        'User Profiles',
        'Integration with Outlook',
        'Vouchers',
        'General Feedback',
        'Other',
      ])

      const timeFrames = ref(['Today', 'Current Week', 'Last Week', 'Current Month', 'Last Month', 'Current Year'])

      const feedback = ref({
        category: '',
        rating: 0,
        name: '',
        message: '',
      })

      const toggleAnonymous = () => {
        if (isAnonymous.value) {
          feedback.value.name = 'Anonymous'
        } else {
          feedback.value.name = ''
        }
      }

      const canSubmit = computed(() => {
        return (
          feedback.value.category !== '' &&
          feedback.value.rating > 0 &&
          (isAnonymous.value || feedback.value.name.trim() !== '') &&
          feedback.value.message.trim() !== ''
        )
      })

      const goBack = () => {
        window.history.back()
      }

      const submitFeedback = async () => {
        try {
          const formData = new FormData()
          formData.append('entry.1647756974', feedback.value.category)
          formData.append('entry.1134755108', feedback.value.rating)
          formData.append('entry.92267303', feedback.value.name)
          formData.append('entry.1394086581', feedback.value.message)

          await fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLSfvVcA4HxgHqOoOhIqRJuIxJ-dA6lMvBKTGzvGzIyc9DHY-xQ/formResponse', {
            method: 'POST',
            body: formData,
            mode: 'no-cors',
          })

          Swal.fire({
            icon: 'success',
            title: 'Thank you!',
            text: 'Your feedback has been submitted successfully.',
          }).then(() => {
            feedback.value.category = ''
            feedback.value.rating = 0
            feedback.value.name = ''
            feedback.value.message = ''
            isAnonymous.value = false
          })
        } catch (error) {
          console.error('Error submitting feedback:', error)
          Swal.fire({
            icon: 'error',
            title: 'Failed to submit feedback',
            text: 'An error occurred while submitting your feedback. Please try again later.',
          })
        }
      }

      const fetchFeedbacks = async () => {
        showLoader.value = true
        let attempts = 0
        let success = false
        const proxyUrl = 'https://api.allorigins.win/get?url='
        const targetUrl = encodeURIComponent(
          'https://docs.google.com/spreadsheets/d/1kDJweZx-gnq_PWvWaqewno_BeMk33pluUZKASW161NE/pub?gid=631496770&single=true&output=csv'
        )

        while (attempts < 5 && !success) {
          try {
            attempts++
            const response = await fetch(proxyUrl + targetUrl)
            if (!response.ok) throw new Error('Failed to fetch')
            const data = await response.json()

            // Decode the Base64 string
            const decodedData = atob(data.contents.split(',')[1])

            // Parse the CSV data
            feedbacks.value = parseCSV(decodedData)
            success = true
          } catch (error) {
            console.error(`Attempt ${attempts} failed: ${error.message}`)
          }
        }

        if (!success) {
          Swal.fire({
            icon: 'error',
            title: 'Failed to Load Feedbacks',
            text: 'Unable to load feedbacks after multiple attempts. Please try again later.',
          })
        }
        showLoader.value = false
      }

      const parseCSV = str => {
        const rows = str.split('\n')
        const headers = rows[0].split(',')
        return rows.slice(1).map(row => {
          const values = row.split(',')
          return headers.reduce((obj, header, index) => {
            obj[header.trim()] = values[index].trim()
            return obj
          }, {})
        })
      }

      const filteredFeedbacks = computed(() => {
        return feedbacks.value.filter(feedback => {
          const matchesSearch = feedback['Your Feedback'].toLowerCase().includes(searchQuery.value.toLowerCase())
          const matchesCategory = selectedCategory.value === 'All Categories' || feedback.Category === selectedCategory.value
          const feedbackDate = moment(feedback.Timestamp, 'M/D/YYYY H:mm:ss')

          let matchesTimeFrame = true
          switch (selectedTimeFrame.value) {
            case 'Today':
              matchesTimeFrame = feedbackDate.isSame(moment(), 'day')
              break
            case 'Current Week':
              matchesTimeFrame = feedbackDate.isSame(moment(), 'week')
              break
            case 'Last Week':
              matchesTimeFrame = feedbackDate.isSame(moment().subtract(1, 'weeks'), 'week')
              break
            case 'Current Month':
              matchesTimeFrame = feedbackDate.isSame(moment(), 'month')
              break
            case 'Last Month':
              matchesTimeFrame = feedbackDate.isSame(moment().subtract(1, 'months'), 'month')
              break
            case 'Current Year':
              matchesTimeFrame = feedbackDate.isSame(moment(), 'year')
              break
          }

          return matchesSearch && matchesCategory && matchesTimeFrame
        })
      })

      onMounted(async () => {
        let profile = await OAuthService.getUserProfile()
        let roles = profile?.realm_access?.roles || []
        primaryColor.value = await getPrimaryColor()

        const hasAdminRole = roles.some(role => role?.toLowerCase()?.includes('admin'))
        const isAdminRoute = router.currentRoute.value.path === '/menu/feedbacks/admin'

        if (hasAdminRole && isAdminRoute) {
          isAdmin.value = true
          fetchFeedbacks() // Load feedbacks only if user is admin and route is correct
        } else {
          noPermissions.value = true // Set noPermissions to true if either condition is false
        }
      })

      return {
        isAnonymous,
        primaryColor,
        categories,
        feedback,
        toggleAnonymous,
        canSubmit,
        submitFeedback,
        goBack,
        userProfile,
        isAdmin,
        feedbacks,
        searchQuery,
        selectedCategory,
        selectedTimeFrame,
        timeFrames,
        showLoader,
        filteredFeedbacks,
      }
    },
  }
</script>

<style scoped>
  .feedback-view {
    display: flex;
    flex-direction: column;
    background-color: #f5f8fa;
  }

  .custom-toolbar {
    background-color: white;
    color: black;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 8px 16px;
  }

  .custom-toolbar-title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .toolbar-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
    color: black;
  }
  .toolbar-icon:hover {
    transform: scale(1.1);
  }

  .feedback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-grow: 1;
  }

  .feedback-card {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: white;
  }

  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .v-select {
    transition: border-color 0.3s ease;
  }

  .star-rating {
    display: flex;
    justify-content: space-between;
  }

  .anonymous-checkbox {
    transition: color 0.3s ease;
  }

  .name-field {
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .name-field:disabled {
    background-color: #f5f5f5;
    border-color: #ddd;
  }

  .feedback-textarea {
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .submit-button {
    background-color: #007bff;
    color: white;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .admin-feedback-container {
    padding: 20px;
  }

  .feedback-rating {
    margin: 10px 0;
  }

  @media (max-width: 600px) {
    .feedback-card {
      padding: 15px;
    }
    .custom-toolbar-title {
      font-size: 1.2rem;
    }
    .submit-button {
      width: 100%;
    }
  }
</style>
