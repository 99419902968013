<template>
  <v-container class="blinds-control">
    <v-card class="window-card">
      <div id="window_container" ref="windowContainer">
        <div v-for="n in 10" :key="n" class="blind_slat"></div>
      </div>
      <Switch
        :state="switchState"
        :updateSwitch="toggleBlinds"
        style="margin-top: 1rem"
      />
    </v-card>
    <v-chip variant="outlined" style="margin-top: 1rem">
      {{ switchState ? 'Blinds Open' : 'Blinds Closed' }}
    </v-chip>
  </v-container>
</template>

<script setup>
  import { ref, onMounted, watch, defineProps } from 'vue'
  import Switch from '@/components/general/ChildComponents/Switch.vue'

  const props = defineProps({
    updateBlindShutter: Function,
    initialSwitchState: Boolean,
  })

  const switchState = ref(props.initialSwitchState)
  const windowContainer = ref(null)
  const slats = ref([])

  const updateBlindsPosition = perc => {
    const slatHeight = slats.value[0].offsetHeight
    slats.value.forEach((elm, index) => {
      elm.style.transition = 'all 0.5s ease'
      elm.style.top = `${index * slatHeight * (perc / 100)}px`
    })
  }

  const toggleBlinds = () => {
    switchState.value = !switchState.value
    if (switchState.value) {
      updateBlindsPosition(0)
    } else {
      updateBlindsPosition(100)
    }
    props.updateBlindShutter(switchState.value)
  }

  onMounted(() => {
    slats.value = windowContainer.value.querySelectorAll('.blind_slat')
    updateBlindsPosition(switchState.value ? 0 : 100)
  })
</script>

<style scoped>
  .blinds-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50vh;
  }

  .window-card {
    width: 100%;
    max-width: 600px;
    height: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: url('https://www.transparenttextures.com/patterns/wood.png');
    position: relative;
  }

  #window_container {
    position: relative;
    width: 100%;
    height: 80%;
    background: #000;
    overflow: hidden;
  }

  .blind_slat {
    width: 100%;
    height: 10%;
    background: radial-gradient(ellipse, lightgray 75%, transparent 75%),
      radial-gradient(ellipse, lightgray 75%, transparent 75%),
      url('https://www.transparenttextures.com/patterns/retina-wood.png'),
      linear-gradient(to bottom, gray 5%, ivory 20%, #aaa);
    background-size: 10px 35px, 10px 35px, auto auto, auto auto;
    background-repeat: no-repeat, no-repeat, repeat, repeat;
    background-position: 0.75em 60%, calc(100% - 0.65em) 60%, 0 0, 0 0;
    backdrop-filter: blur(8px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    transition: all 0.5s ease;
  }

  #hero-text {
    display: none;
  }

  .switch-container {
    margin-top: 20px;
  }
</style>
