<template>
  <div class="office-analytics">
    <v-toolbar color="#222222" flat dense class="mb-4">
      <v-toolbar-title style="font-family: 'Material Design Icons'; font-size: 25px !important">Office Analytics</v-toolbar-title>
      <v-spacer></v-spacer>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            v-model="selectedFilter"
            :items="filters"
            label="Filter"
            dense
            outlined
            class="mb-4"
            style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="pa-4 mb-4" title="Office Days">
            <apexchart width="100%" height="350" type="bar" :options="officeChartOptions" :series="officeChartData"></apexchart>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-4 mb-4" title="Vacation Days">
            <DatePicker
              v-if="selectedFilter !== 'Current Year'"
              v-model.range="vacationDatesRange"
              mode="date"
              :view="selectedFilter === 'Current Week' || selectedFilter === 'Last Week' ? 'weekly' : 'monthly'"
              expanded
              borderless
              :color="'red'"
            />
            <apexchart v-else width="100%" height="350" type="bar" :options="vacationChartOptions" :series="vacationChartData"></apexchart>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-4 mb-4" title="Sick Days">
            <apexchart width="100%" height="350" type="donut" :options="sickChartOptions" :series="sickChartData"></apexchart>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { ref, defineComponent, watch, onMounted } from 'vue'
  import { officeDaysData } from '@/dummydata/officeDays'
  import moment from 'moment'
  import VueApexCharts from 'vue3-apexcharts'
  import { DatePicker } from 'v-calendar'

  export default defineComponent({
    name: 'OfficeAnalytics',
    components: {
      apexchart: VueApexCharts,
      DatePicker,
    },
    setup() {
      const selectedFilter = ref('Last Month')
      const vacationDatesRange = ref({ start: null, end: null })
      const filters = ['Current Week', 'Current Month', 'Last Week', 'Last Month', 'Current Year']
      const officeChartData = ref([])
      const vacationChartData = ref([])
      const sickChartData = ref([])
      const logo = ref(require('@/assets/logo.png'))

      const officeChartOptions = ref({
        chart: {
          id: 'office-days-chart',
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'Hours',
          },
        },
        stroke: {
          curve: 'stepline',
        },

        legend: {
          position: 'top',
        },
      })

      const vacationChartOptions = ref({
        chart: {
          id: 'vacation-days-chart',
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          title: {
            text: 'Month',
          },
        },
        yaxis: {
          title: {
            text: 'Vacation Days',
          },
        },

        legend: {
          position: 'top',
        },
      })

      const sickChartOptions = ref({
        chart: {
          id: 'sick-days-chart',
          toolbar: {
            show: true,
          },
        },
        labels: ['Sick Days', 'Other Days'],

        legend: {
          position: 'top',
        },
      })

      const processData = () => {
        let filteredData
        const endDate = moment()
        let startDate

        switch (selectedFilter.value) {
          case 'Current Week':
            startDate = moment().startOf('week')
            break
          case 'Current Month':
            startDate = moment().startOf('month')
            break
          case 'Last Week':
            startDate = moment().subtract(1, 'week').startOf('week')
            endDate.subtract(1, 'week').endOf('week')
            break
          case 'Last Month':
            startDate = moment().subtract(1, 'month').startOf('month')
            endDate.subtract(1, 'month').endOf('month')
            break
          case 'Current Year':
            startDate = moment().startOf('year')
            break
          default:
            startDate = moment().subtract(2, 'months').startOf('month')
            break
        }

        filteredData = officeDaysData.filter(item => moment(item.date).isBetween(startDate, endDate, null, '[]'))

        const officeSeries = { name: 'Office Hours', data: [] }
        const vacationRanges = []
        const vacationSeries = new Array(12).fill(0) // For months
        const sickSeries = [0, 0]

        let currentVacationRange = null

        for (const item of filteredData) {
          const date = moment(item.date).format('YYYY-MM-DD')
          if (item.status === 'Office') {
            officeSeries.data.push({ x: date, y: item.hours })
          } else if (item.status === 'Vacation') {
            const month = moment(item.date).month()
            vacationSeries[month] += 1

            if (!currentVacationRange) {
              currentVacationRange = { start: date, end: date }
            } else {
              currentVacationRange.end = date
            }
          } else if (item.status === 'Sick') {
            sickSeries[0] += 1
          }
          if (item.status !== 'Sick') {
            sickSeries[1] += 1
          }

          if (item.status !== 'Vacation' && currentVacationRange) {
            vacationRanges.push({ start: new Date(currentVacationRange.start), end: new Date(currentVacationRange.end) })
            currentVacationRange = null
          }
        }

        if (currentVacationRange) {
          vacationRanges.push({ start: new Date(currentVacationRange.start), end: new Date(currentVacationRange.end) })
        }

        officeChartData.value = [officeSeries]
        vacationDatesRange.value = vacationRanges.length ? vacationRanges[0] : { start: null, end: null }
        vacationChartData.value = [{ name: 'Vacation Days', data: vacationSeries }]
        sickChartData.value = sickSeries
      }

      watch(selectedFilter, processData, { immediate: true })

      onMounted(() => {
        processData()
      })

      return {
        selectedFilter,
        filters,
        officeChartOptions,
        vacationChartOptions,
        sickChartOptions,
        officeChartData,
        vacationChartData,
        sickChartData,
        logo,
        vacationDatesRange,
      }
    },
  })
</script>

<style scoped>
  .office-analytics {
    padding: 16px;
    background: #f5f5f5;
    border-radius: 8px;
  }
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }
  .v-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
</style>
