<template>
  <v-card class="pa-4 mb-4 donut-chart-card" :style="{ opacity: showLoader ? 0 : 1, transition: 'opacity 0.5s' }">
    <apexchart
      v-if="!showLoader"
      ref="donutChart"
      type="donut"
      :options="donutChartOptions"
      :series="donutChartData"
      width="100%"
      height="350"
    ></apexchart>
  </v-card>
</template>

<script>
  import { ref, defineComponent, watch, onMounted } from 'vue'
  import VueApexCharts from 'vue3-apexcharts'
  import moment from 'moment'

  export default defineComponent({
    name: 'ReservationsDonutChart',
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      reservations: {
        type: Array,
        required: true,
      },
      selectedTimeRange: {
        type: String,
        required: true,
      },
      selectedCategory: {
        type: Object,
        required: true,
      },
      selectedFloor: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const showLoader = ref(true)
      const donutChartData = ref([])

      const donutChartOptions = ref({
        chart: {
          id: 'donut-chart',
          height: 350,
          type: 'donut',
        },
        colors: [], // This will be dynamically filled with unique colors
        labels: [], // Will be filled with the category names
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
            },
          },
        },
        legend: {
          position: 'bottom',
        },
      })

      const generateUniqueColors = count => {
        // Generates a palette of unique colors
        const colors = []
        for (let i = 0; i < count; i++) {
          const color = `hsl(${Math.floor((i * 360) / count)}, 70%, 50%)`
          colors.push(color)
        }
        return colors
      }

      const processDonutChartData = () => {
        const filteredReservations = filterReservations()
        if (!filteredReservations || filteredReservations.length === 0) {
          donutChartData.value = []
          return
        }

        const dataByCategory = {}
        filteredReservations.forEach(reservation => {
          const category = reservation.poiCategory
          if (!dataByCategory[category]) {
            dataByCategory[category] = 0
          }
          dataByCategory[category] += 1
        })

        donutChartData.value = Object.values(dataByCategory)
        donutChartOptions.value.labels = Object.keys(dataByCategory)
        donutChartOptions.value.colors = generateUniqueColors(donutChartOptions.value.labels.length)
      }

      const filterReservations = () => {
        let filteredReservations = props.reservations || []
        let startDate, endDate

        switch (props.selectedTimeRange) {
          case 'today':
          case 'yesterday':
          case 'tomorrow':
            startDate =
              props.selectedTimeRange === 'today'
                ? moment().startOf('day')
                : props.selectedTimeRange === 'yesterday'
                ? moment().subtract(1, 'day').startOf('day')
                : moment().add(1, 'day').startOf('day')
            endDate = startDate.clone().endOf('day')
            break
          case 'thisWeek':
            startDate = moment().startOf('week')
            endDate = moment().endOf('week')
            break
          case 'lastWeek':
            startDate = moment().subtract(1, 'week').startOf('week')
            endDate = moment().subtract(1, 'week').endOf('week')
            break
          case 'currentMonth':
            startDate = moment().startOf('month')
            endDate = moment().endOf('month')
            break
          case 'lastMonth':
            startDate = moment().subtract(1, 'month').startOf('month')
            endDate = moment().subtract(1, 'month').endOf('month')
            break
          case 'nextMonth':
            startDate = moment().add(1, 'month').startOf('month')
            endDate = moment().add(1, 'month').endOf('month')
            break
        }

        filteredReservations = filteredReservations.filter(reservation => {
          const reservationDate = moment(reservation.startTime)
          return reservationDate.isBetween(startDate, endDate, null, '[]')
        })

        if (props.selectedCategory.name !== 'All Categories') {
          filteredReservations = filteredReservations.filter(reservation => reservation.poiCategory === props.selectedCategory.name)
        }

        if (props.selectedFloor.name !== 'All Floors') {
          filteredReservations = filteredReservations.filter(reservation => reservation.floorName === props.selectedFloor.name)
        }

        return filteredReservations
      }

      watch([() => props.selectedTimeRange, () => props.selectedCategory, () => props.selectedFloor], () => {
        showLoader.value = true
        setTimeout(() => {
          processDonutChartData()
          showLoader.value = false
        }, 500)
      })

      onMounted(() => {
        processDonutChartData()
        showLoader.value = false
      })

      return {
        showLoader,
        donutChartOptions,
        donutChartData,
      }
    },
  })
</script>

<style scoped>
  .donut-chart-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
</style>
