<template>
  <v-container>
    <v-card elevation="10" class="control-card">
      <v-card-title class="text-center">Adjust Blinds</v-card-title>

      <v-card-text>
        <v-select
          v-if="blindsHardware?.length > 1"
          label="Select Blind"
          :items="blindsHardware"
          return-object
          item-value="serialNumber"
          item-title="serialNumber"
          v-model="selectedBlind"
          variant="solo-filled"
        ></v-select>
        <template v-if="selectedBlind">
          <Switch
            v-if="selectedBlind.hasSwitch"
            :state="switchState"
            :updateSwitch="updateBlindSwitchState"
          />
          <div v-if="selectedBlind.hasPosition" class="slider-section">
            <div class="slider-heading">
              <v-icon class="slider-icon">mdi-window-shutter</v-icon>
              <span>Adjust Position</span>
            </div>
            <div class="slider-container">
              <button
                @click="decreasePosition"
                :disabled="!switchState"
                class="icon-btn"
              >
                <v-icon>mdi-minus</v-icon>
              </button>
              <v-slider
                v-model="positionValue"
                :step="positionSteps"
                color="#50C878"
                :min="positionMin"
                :max="positionMax"
                class="slider"
                thumb-label="always"
                :disabled="!switchState"
              ></v-slider>
              <button
                @click="increasePosition"
                :disabled="!switchState"
                class="icon-btn"
              >
                <v-icon>mdi-plus</v-icon>
              </button>
            </div>
          </div>
          <div v-if="selectedBlind.hasScene">
            <BlindShutter
              :initialSwitchState="sceneSwitchState"
              :updateBlindShutter="handleBlindShutterUpdate"
            />
          </div>
          <div v-if="selectedBlind.hasAngle" class="slider-section">
            <div class="slider-heading">
              <v-icon class="slider-icon">mdi-angle-acute</v-icon>
              <span>Adjust Angle</span>
            </div>
            <div class="slider-container">
              <button
                @click="decreaseAngle"
                :disabled="!switchState"
                class="icon-btn"
              >
                <v-icon>mdi-minus</v-icon>
              </button>
              <v-slider
                v-model="angleValue"
                :step="angleSteps"
                :min="angleMin"
                :max="angleMax"
                color="#00A36C"
                class="slider"
                thumb-label="always"
                :disabled="!switchState"
              ></v-slider>
              <button
                @click="increaseAngle"
                :disabled="!switchState"
                class="icon-btn"
              >
                <v-icon>mdi-plus</v-icon>
              </button>
            </div>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { defineComponent, ref, computed, onMounted, watch } from 'vue'
  import Switch from '@/components/general/ChildComponents/Switch.vue'
  import BlindShutter from '@/components/general/ChildComponents/BlindShutter.vue'

  export default defineComponent({
    name: 'BlindsControl',
    components: {
      Switch,
      BlindShutter,
    },
    props: {
      blinds: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
    setup(props) {
      const selectedBlind = ref(null)
      const switchState = ref(false) // Default switch state
      const positionValue = ref(50) // Default position slider value
      const angleValue = ref(90) // Default angle slider value
      const positionSteps = ref(10) // Adjust step value as needed
      const angleSteps = ref(10) // Adjust step value as needed
      const positionMin = ref(0)
      const positionMax = ref(100)
      const angleMin = ref(0)
      const angleMax = ref(180)
      const sceneSwitchState = ref(false)

      const blindsHardware = computed(() => props.blinds)

      const handleBlindShutterUpdate = state => {
        sceneSwitchState.value = state
      }

      const decreasePosition = () => {
        positionValue.value = Math.max(
          positionMin.value,
          positionValue.value - positionSteps.value
        )
      }

      const increasePosition = () => {
        positionValue.value = Math.min(
          positionMax.value,
          positionValue.value + positionSteps.value
        )
      }

      const decreaseAngle = () => {
        angleValue.value = Math.max(
          angleMin.value,
          angleValue.value - angleSteps.value
        )
      }

      const increaseAngle = () => {
        angleValue.value = Math.min(
          angleMax.value,
          angleValue.value + angleSteps.value
        )
      }

      const updateBlindSwitchState = state => {
        switchState.value = state
      }

      const updateFeatures = blind => {
        blind = JSON.parse(JSON.stringify(blind))
        if (blind?.hasPosition) {
          positionValue.value = blind.positionOptions.min
          positionMin.value = blind.positionOptions.min
          positionMax.value = blind.positionOptions.max
          positionSteps.value = blind.positionOptions.steps
        }
        if (blind?.hasAngle) {
          angleValue.value = blind.angleOptions.min
          angleMin.value = blind.angleOptions.min
          angleMax.value = blind.angleOptions.max
          angleSteps.value = blind.angleOptions.steps
        }
      }

      onMounted(() => {
        if (blindsHardware.value?.length > 0) {
          let blinds = JSON.parse(JSON.stringify(blindsHardware.value))
          selectedBlind.value = blinds[0]
          updateFeatures(blinds[0])
        }
      })

      watch(
        selectedBlind,
        newBlind => {
          if (newBlind) {
            updateFeatures(newBlind)
          }
        },
        { immediate: true }
      )

      return {
        blindsHardware,
        selectedBlind,
        switchState,
        positionValue,
        angleValue,
        decreasePosition,
        increasePosition,
        decreaseAngle,
        increaseAngle,
        updateBlindSwitchState,
        positionSteps,
        angleSteps,
        positionMin,
        positionMax,
        angleMin,
        angleMax,
        sceneSwitchState,
        handleBlindShutterUpdate,
      }
    },
  })
</script>

<style scoped>
  .control-card {
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
    background-color: #f5f5f5;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }

  .control-card:hover {
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }

  .slider-section {
    margin-top: 20px;
  }

  .slider-heading {
    display: flex;
    align-items: center;
    margin-bottom: 35px; /* Increased margin for better spacing */
  }

  .slider-heading .slider-icon {
    margin-right: 8px;
    font-size: 24px;
  }

  .slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Changed to space-between for better alignment */
    gap: 10px;
  }

  .slider {
    flex: 1; /* Added flex property to make slider take up remaining space */
    margin: 0 10px;
  }

  .icon-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .icon-btn:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }

  .icon-btn v-icon {
    font-size: 18px;
  }

  .icon-btn:hover:enabled {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    .slider {
      width: 200px;
    }

    .icon-btn {
      font-size: 10px;
      padding: 3px 5px;
    }

    .icon-btn v-icon {
      font-size: 14px;
    }

    .slider-heading .slider-icon {
      font-size: 18px;
    }
  }
</style>
