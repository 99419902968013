import { createI18n } from 'vue-i18n'
import translations from '@/translation/translations.js'
import moment from 'moment'
import 'moment/locale/en-gb'
import { getCookie, setCookie } from '@/services/utils' // Import cookie utilities

const STORAGE_KEY = 'appLanguage'

function getBrowserLanguage() {
  const browserLang = navigator.language || navigator.userLanguage
  return ['de', 'en'].includes(browserLang.substr(0, 2)) ? browserLang.substr(0, 2) : 'en'
}

async function getDefaultLanguage() {
  const storedLang = await getCookie(STORAGE_KEY) // Use getCookie instead of vue3-cookies
  return storedLang && ['de', 'en'].includes(storedLang) ? storedLang : getBrowserLanguage()
}

const i18n = createI18n({
  legacy: false, // Use Composition API
  locale: 'en', // Default to English initially, and update in `initialize` method.
  fallbackLocale: 'en',
  messages: translations,
})

moment.locale(i18n.global.locale.value) // Set moment locale based on initial locale

const data = [
  {
    locale: 'en-gb',
    code: 'en',
    name: { de: 'Englisch', en: 'English' },
  },
  {
    locale: 'de-de',
    code: 'de',
    name: { de: 'Deutsch', en: 'German' },
  },
]

const TranslationService = {
  data: data,
  dataByCode: {},

  translate(text, placeholders) {
    const { t } = i18n.global
    return t(text, placeholders)
  },

  translatePlural(text, count) {
    const { tc } = i18n.global
    return tc(text, count)
  },

  async setLanguage(code) {
    if (!this.dataByCode[code]) {
      console.error(`Language code ${code} does not exist in data.`)
      return
    }

    i18n.global.locale.value = code
    moment.locale(this.dataByCode[code].locale)

    await setCookie(STORAGE_KEY, code, 365) // Set the language cookie for 365 days

    const htmlTag = document.getElementsByTagName('html')[0]
    if (htmlTag) {
      htmlTag.setAttribute('lang', code)
    } else {
      console.warn("Couldn't find <html> tag to set 'lang' attribute.")
    }

    const contentLanguageMetaTag = document.querySelector('[http-equiv="content-language"]')
    if (contentLanguageMetaTag) {
      contentLanguageMetaTag.setAttribute('content', code)
    } else {
      console.warn("Couldn't find meta tag with '[http-equiv=\"content-language\"]' to set 'content' attribute.")
    }
  },

  async initialize() {
    for (let lang of this.data) {
      this.dataByCode[lang.code] = lang
    }

    // Set language after fetching the default language
    const defaultLang = await getDefaultLanguage()
    this.setLanguage(defaultLang)
  },

  getLanguage() {
    return i18n.global.locale.value
  },
}

// Initialize the language setting at application startup
TranslationService.initialize()

export { i18n, TranslationService }
