<template>
  <div id="form-wrapper">
    <form @submit.prevent="submitForm">
      <div id="scene-amount-slider">
        <input
          v-for="option in scenes"
          :key="option.id"
          type="radio"
          name="scene-amount"
          :id="option.id"
          :value="option.value"
          v-model="selectedValue"
          required
          @change="handleSelectionChange"
        />
        <label
          v-for="option in scenes"
          :key="option.id + '-label'"
          :for="option.id"
          :data-scene-amount="option.label"
          :class="{ selected: selectedValue === option.value }"
        ></label>
      </div>
    </form>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue'
  import lodash from 'lodash'

  export default {
    name: 'SceneSlider',
    props: {
      sceneOptions: {
        type: Array,
        required: true,
      },
      updateSceneValue: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const selectedValue = ref(null)
      const scenes = ref([])

      const handleSelectionChange = () => {
        console.log('Selected Value:', selectedValue.value)
      }

      const setSceneValue = newValue => {
        selectedValue.value = newValue
        handleSelectionChange()
      }

      onMounted(() => {
        let SCENE_OPTIONS = JSON.parse(JSON.stringify(props.sceneOptions))
        let isEmptyScenes = lodash.isEmpty(SCENE_OPTIONS)
        if (!isEmptyScenes) {
          let SCENES = []
          let id = 0
          for (let scene in SCENE_OPTIONS) {
            let option = {
              id: id++,
              value: scene,
              label:
                scene?.includes('auto') || scene?.includes('Auto')
                  ? 'Auto'
                  : scene,
              hwValue: SCENE_OPTIONS[scene].value,
            }
            SCENES.push(option)
          }

          // Custom sorting logic
          const customSort = (a, b) => {
            const autoValues = ['automatic', 'auto', 'Auto', 'automated']
            const onValues = ['on', 'On', 'ON', 'activated']
            const offValues = ['off', 'Off', 'OFF', 'deactivate', 'deactivated']

            const getLabel = item => item?.label || ''

            const isAutoA = autoValues.includes(getLabel(a))
            const isAutoB = autoValues.includes(getLabel(b))
            const isOnA = onValues.includes(getLabel(a))
            const isOnB = onValues.includes(getLabel(b))
            const isOffA = offValues.includes(getLabel(a))
            const isOffB = offValues.includes(getLabel(b))

            if (isAutoA && !isAutoB) return -1
            if (!isAutoA && isAutoB) return 1
            if (isOffA && !isOffB) return 1
            if (!isOffA && isOffB) return -1
            if (isOnA && !isOnB) return 1
            if (!isOnA && isOnB) return -1

            const valueA = parseFloat(getLabel(a))
            const valueB = parseFloat(getLabel(b))

            if (!isNaN(valueA) && !isNaN(valueB)) {
              return valueA - valueB
            }

            return 0
          }

          SCENES = SCENES.sort(customSort)

          selectedValue.value = SCENES[0]?.value
          scenes.value = SCENES
        }
      })

      watch(
        () => props.updateSceneValue,
        newVal => {
          if (typeof newVal === 'function') {
            newVal(setSceneValue)
          }
        },
        { immediate: true }
      )

      return {
        selectedValue,
        handleSelectionChange,
        scenes,
      }
    },
  }
</script>

<style scoped>
  html,
  body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito', sans-serif;
    color: #000;
    user-select: none;
  }

  #form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  form {
    width: 100%; /* Set form width to 100% to fill the container */
    max-width: 500px;
  }

  #scene-amount-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%; /* Use full width of the container */
    height: 50px;
    user-select: none;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  #scene-amount-slider::before {
    content: ' ';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000;
  }

  input,
  label {
    box-sizing: border-box;
    flex: 1;
    user-select: none;
    cursor: pointer;
  }

  label {
    display: inline-block;
    position: relative;
    width: calc(100% / var(--options-length));
    height: 100%;
    user-select: none;
  }

  label::before {
    content: attr(data-scene-amount);
    position: absolute;
    left: 50%;
    padding-top: 10px;
    transform: translate(-50%, 45px);
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0.85;
    transition: all 0.15s ease-in-out;
  }

  label::after {
    content: ' ';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    background: #fff;
    border-radius: 50%;
    pointer-events: none;
    user-select: none;
    z-index: 1;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
  }

  label:hover::after {
    transform: translate(-50%, -50%) scale(1.25);
  }

  input {
    display: none;
  }

  input:checked + label::before {
    font-weight: 800;
    opacity: 1;
  }

  input:checked + label::after {
    border-width: 4px;
  }

  .selected::after {
    background: #4caf50; /* Green color for selected */
  }

  /* Responsive styles */
  @media (max-width: 600px) {
    form {
      width: 100%; /* Ensure the form takes full width */
    }

    #scene-amount-slider {
      width: 100%; /* Ensure the slider takes full width */
    }

    label {
      flex: 1 1 10%; /* Increase flex-grow to provide more space between labels */
    }

    label::before {
      font-size: 12px; /* Reduce font size for smaller screens */
    }

    label::after {
      width: 25px;
      height: 25px; /* Adjust size of the circles */
    }
  }
</style>
