<template>

	<div class="logged-out">

		<h1>Logged Out</h1>

	</div>

</template>

<script>

	export default {
		name: 'logged-out',
		data() {
			return {
			};
		},
		created() {
		}
	};

</script>

<style lang="scss">

	.logged-out {
		width: 100%;
		height: 100%;
		overflow: auto;

		.logo {
			margin-top: 50px;
			margin-bottom: 70px;
			width: 200px;
		}
		.headline {
			font-size: 34px;
			letter-spacing: -0.5px;
			line-height: 41px;
		}
		.bold {
			font-weight: 700;
		}
		.back {
			margin-bottom: 50px;
		}
		.text {
			font-size: 15px;
			line-height: 22px;
			.larger {
				font-size: 17px;
				line-height: 25px;
			}
		}
		.default-margin {
			margin-bottom: 20px;
		}
		.small-margin {
			margin-bottom: 10px;
		}
		.no-margin {
			margin: 0;
		}
		.causes {
			margin-bottom: 40px;
		}
		.margin-after {
			margin-bottom: 120px;
		}
		.image {
			margin-top: 120px;
			width: 100%;
		}
		

		.iframe {
			width: 1px;
			height: 1px;
			display: none;
		}
	}

</style>
