import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { dbPromise } from '@/services/utils'

// Function to store a reservation in IndexedDB
export async function storeReservation(reservation) {
  const db = await dbPromise
  const tx = db.transaction('reservations', 'readwrite')
  tx.objectStore('reservations').put(reservation)
  await tx.done
}

// Function to store an appointment in IndexedDB and update the corresponding reservation
export async function storeAppointment(appointment) {
  const db = await dbPromise
  const tx = db.transaction('appointments', 'readwrite')
  tx.objectStore('appointments').put(appointment)
  await tx.done

  // After storing the appointment, update the corresponding reservation
  const reservationId = appointment.reservation.ids[0]

  if (reservationId) {
    const reservation = await getReservationById(reservationId)

    if (reservation) {
      reservation.appointment = {
        type: 'appointments',
        id: appointment.id,
      }

      // Store the updated reservation back to IndexedDB
      await storeReservation(reservation)
    } else {
      console.error(`Reservation with ID ${reservationId} not found.`)
    }
  } else {
    console.error('Reservation ID is missing from the appointment.')
  }
}

// Function to update a reservation
// Function to update a reservation in IndexedDB manually
export async function updateReservation(reservationId, payload) {
  try {
    // Retrieve the reservation by ID
    const db = await dbPromise
    const tx = db.transaction('reservations', 'readwrite')
    const store = tx.objectStore('reservations')
    const reservation = await store.get(reservationId)

    if (!reservation) {
      throw new Error(`Reservation with ID ${reservationId} not found.`)
    }

    // Update the reservation fields with the new data from the payload
    reservation.title = payload.title || reservation.title
    reservation.poiId = payload.poiId || reservation.poiId
    reservation.startTime = payload.startTime || reservation.startTime
    reservation.endTime = payload.endTime || reservation.endTime
    reservation.personCount = payload.personCount || reservation.personCount
    reservation.version = uuidv4() // Create a new version for the updated reservation
    console.log('reservation indexdb', reservation)

    // Update the reservation back in IndexedDB
    store.put(reservation)

    // Commit the transaction
    await tx.done

    return reservation
  } catch (error) {
    console.error('Error updating reservation:', error)
    throw error
  }
}

// Function to update an appointment in IndexedDB manually
export async function updateAppointment(appointmentId, payload) {
  try {
    // Retrieve the appointment by ID
    const db = await dbPromise
    const tx = db.transaction('appointments', 'readwrite')
    const store = tx.objectStore('appointments')
    const appointment = await store.get(appointmentId)

    if (!appointment) {
      throw new Error(`Appointment with ID ${appointmentId} not found.`)
    }

    // Update the appointment fields with the new data from the payload
    appointment.title = payload.title || appointment.title
    appointment.startTime = payload.startTime || appointment.startTime
    appointment.endTime = payload.endTime || appointment.endTime
    appointment.description = payload.description || appointment.description
    appointment.invitees = payload.invitees || appointment.invitees
    appointment.version = uuidv4() // Create a new version for the updated appointment
    console.log('appointment indexdb', appointment)

    // Update the appointment back in IndexedDB
    store.put(appointment)

    // Commit the transaction
    await tx.done

    return appointment
  } catch (error) {
    console.error('Error updating appointment:', error)
    throw error
  }
}

// Function to retrieve a reservation by ID
export async function getReservationById(reservationId) {
  if (!reservationId) {
    throw new Error('Reservation ID is required to retrieve a reservation.')
  }

  const db = await dbPromise
  const tx = db.transaction('reservations', 'readonly')
  const store = tx.objectStore('reservations')
  const reservation = await store.get(reservationId)
  await tx.done
  return reservation
}

// Function to mock a reservation creation response
export async function mockCreateReservation(data) {
  data = JSON.parse(data)

  const recurringId = data.recurrence ? uuidv4() : null

  const reservation = {
    id: uuidv4(),
    version: uuidv4(),
    tags: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: uuidv4(),
    startTime: data.startTime,
    endTime: data.endTime,
    creationTime: moment().toISOString(),
    creatorId: null,
    creatorName: data.creatorName,
    creatorEmail: data.creatorEmail,
    reservedForId: null,
    poiId: data.poiId,
    poiName: data.poiName,
    poiCategory: data.poiCategory,
    floorNumber: data.floorNumber,
    floorName: data.floorName,
    buildingName: data.buildingName,
    campusName: 'PIA Campus',
    checkoutTime: null,
    recurringId: recurringId,
    price: null,
    canceled: false,
    bookableType: null,
    appointment: {
      type: 'appointments',
      id: null,
    },
    title: data.title,
    personCount: data.personCount,
    type: 'reservations',
    recurrence: data.recurrence
      ? {
          until: data.recurrence.until,
          frequency: data.recurrence.frequency,
        }
      : null,
  }

  await storeReservation(reservation)

  return reservation
}

// Function to mock an appointment creation response
export async function mockCreateAppointment(data) {
  data = JSON.parse(data)

  const appointment = {
    id: uuidv4(),
    version: uuidv4(),
    tags: {},
    files: {
      type: 'files',
      entities: [],
    },
    customerId: data.customerId,
    creatorEmail: data.moderatorEmail,
    moderatorEmail: data.moderatorEmail,
    startTime: data.startTime,
    endTime: data.endTime,
    reservation: {
      type: 'reservations',
      ids: [data.reservationId],
    },
    invitees: [],
    title: data.title,
    description: data.description,
    organizerName: data.organizerName,
    location: data.location,
    recurrence: data.recurrence
      ? {
          until: data.recurrence.until,
          frequency: data.recurrence.frequency,
        }
      : null,
    recurringId: data.reservationRecurringId || null,
    campusId: data.campusId,
    type: 'appointments',
  }

  // Store the appointment and update the reservation
  await storeAppointment(appointment)

  return appointment
}

// Function to get all reservations that fall within a given date range, including recurring reservations
export async function getAllReservations(from, until) {
  const db = await dbPromise
  const tx = db.transaction('reservations', 'readonly')
  const store = tx.objectStore('reservations')
  const reservations = await store.getAll()
  await tx.done

  return reservations.filter(reservation => {
    const startTime = moment(reservation.startTime)
    const endTime = moment(reservation.endTime)
    const requestStart = moment(from)
    const requestEnd = moment(until)

    // Check if the reservation overlaps with the requested date range
    const overlaps = startTime.isBefore(requestEnd) && endTime.isAfter(requestStart)

    // Check if the reservation has recurrence and extends beyond the original dates
    const recurring = reservation.recurrence ? moment(reservation.recurrence.until).isSameOrAfter(requestStart) : false

    return overlaps || recurring
  })
}

// Function to get all appointments that fall within a given date range, including recurring appointments
export async function getAllAppointments(from, until) {
  const db = await dbPromise
  const tx = db.transaction('appointments', 'readonly')
  const store = tx.objectStore('appointments')
  const appointments = await store.getAll()
  await tx.done

  return appointments.filter(appointment => {
    const startTime = moment(appointment.startTime)
    const endTime = moment(appointment.endTime)
    const requestStart = moment(from)
    const requestEnd = moment(until)

    // Check if the appointment overlaps with the requested date range
    const overlaps = startTime.isBefore(requestEnd) && endTime.isAfter(requestStart)

    // Check if the appointment has recurrence and extends beyond the original dates
    const recurring = appointment.recurrence ? moment(appointment.recurrence.until).isSameOrAfter(requestStart) : false

    return overlaps || recurring
  })
}

// Function to get an appointment by ID from IndexedDB
export async function getAppointmentById(appointmentId) {
  const db = await dbPromise
  const tx = db.transaction('appointments', 'readonly')
  const store = tx.objectStore('appointments')
  const appointment = await store.get(appointmentId)
  await tx.done
  return appointment
}

// Function to delete a reservation by ID from IndexedDB
export async function deleteReservationById(reservationId) {
  try {
    const db = await dbPromise
    const tx = db.transaction('reservations', 'readwrite')
    const store = tx.objectStore('reservations')
    await store.delete(reservationId) // Delete the reservation
    await tx.done
    console.log(`Reservation with ID ${reservationId} deleted.`)
  } catch (error) {
    console.error('Error deleting reservation:', error)
    throw error
  }
}

// Function to delete an appointment by ID from IndexedDB
export async function deleteAppointmentById(appointmentId) {
  try {
    const db = await dbPromise
    const tx = db.transaction('appointments', 'readwrite')
    const store = tx.objectStore('appointments')
    await store.delete(appointmentId) // Delete the appointment
    await tx.done
    console.log(`Appointment with ID ${appointmentId} deleted.`)
  } catch (error) {
    console.error('Error deleting appointment:', error)
    throw error
  }
}

// Function to delete recurring reservations by recurringId from IndexedDB
export async function deleteRecurrenceReservationById(recurringId) {
  try {
    const db = await dbPromise
    const tx = db.transaction('reservations', 'readwrite')
    const store = tx.objectStore('reservations')
    const allReservations = await store.getAll()

    // Filter and delete all reservations that match the recurringId
    for (const reservation of allReservations) {
      if (reservation.recurringId === recurringId) {
        await store.delete(reservation.id)
        console.log(`Reservation with recurringId ${recurringId} deleted.`)
      }
    }

    await tx.done
  } catch (error) {
    console.error('Error deleting recurring reservations:', error)
    throw error
  }
}

// Function to delete recurring appointments by recurringId from IndexedDB
export async function deleteRecurrenceAppointmentById(recurringId) {
  try {
    const db = await dbPromise
    const tx = db.transaction('appointments', 'readwrite')
    const store = tx.objectStore('appointments')
    const allAppointments = await store.getAll()

    // Filter and delete all appointments that match the recurringId
    for (const appointment of allAppointments) {
      if (appointment.recurringId === recurringId) {
        await store.delete(appointment.id)
        console.log(`Appointment with recurringId ${recurringId} deleted.`)
      }
    }

    await tx.done
  } catch (error) {
    console.error('Error deleting recurring appointments:', error)
    throw error
  }
}
