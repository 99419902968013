import L from 'leaflet'

// Define icons and colors for each new category
const iconMap = {
  lager: { icon: 'mdi-warehouse', color: 'black' },
  restrooms: { icon: 'mdi-toilet', color: 'black' },
  studio: { icon: 'mdi-video-vintage', color: 'black' },
  printer: { icon: 'mdi-printer', color: 'black' },
  'food and beverages': { icon: 'mdi-food', color: 'black' },
  'meeting room': { icon: 'mdi-account-group-outline', color: 'black' },
  lab: { icon: 'mdi-flask', color: 'black' },
  'event space': { icon: 'mdi-calendar-multiselect', color: 'black' },
}

export const getMarkerIcon = marker => {
  // If the marker or category is missing, return a default icon
  if (!marker || !marker.category) {
    return createDivIcon('mdi-map-marker', 'black')
  }

  const categoryType = marker.category.name.toLowerCase()

  // Find a matching icon configuration based on the category name
  const matchedIcon = Object.keys(iconMap).find(key => categoryType.includes(key))

  // Get the icon and color, or use the default if not found
  const { icon, color } = iconMap[matchedIcon] || { icon: 'mdi-map-marker', color: 'black' }

  // Return the Leaflet divIcon with the matched or default icon and color
  return createDivIcon(icon, color)
}

// Helper function to create a Leaflet divIcon
const createDivIcon = (icon, color) => {
  return L.divIcon({
    className: 'custom-div-icon',
    html: `<span class="mdi ${icon}" style="font-size: 24px; color: ${color};"></span>`,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  })
}
