<template>
  <div class="custom-switch">
    <label class="switch">
      <input type="checkbox" :checked="internalValue" @change="updateValue" />
      <span class="slider"></span>
    </label>
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'

  export default {
    name: 'CustomSwitch',
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
    },
    setup(props, { emit }) {
      const internalValue = ref(props.modelValue)

      watch(
        () => props.modelValue,
        newValue => {
          internalValue.value = newValue
        }
      )

      const updateValue = event => {
        internalValue.value = event.target.checked
        emit('update:modelValue', internalValue.value)
      }

      return {
        internalValue,
        updateValue,
      }
    },
  }
</script>

<style scoped>
  .custom-switch {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #4caf50;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .label {
    font-size: 16px;
    color: #333;
  }
</style>
