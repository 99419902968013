<template>
	<v-container style="height: 100vh" fluid class="no-permission-page fill-height">
	  <v-row class="fill-height" justify="center" align="center">
		<v-col cols="12" sm="8" md="6">
		  <v-card dark class="elevation-12 no-permission-card">
			<v-toolbar dark color="red">
			  <v-toolbar-title>
				<v-icon large class="mr-3">mdi-alert-circle-outline</v-icon>
				No Permission
			  </v-toolbar-title>
			</v-toolbar>
			<v-card-text class="text-center white--text">
			  <div class="display-1 mb-4">Oops!</div>
			  <div class="title mb-2">It seems you don't have permission to access this page.</div>
			  <div class="caption">If you believe this is an error, please contact your administrator.</div>
			</v-card-text>
			<v-card-actions class="justify-center">
			  <v-btn large color="primary" dark @click="logout">
				<v-icon left>mdi-logout</v-icon>
				Logout
			  </v-btn>
			</v-card-actions>
		  </v-card>
		</v-col>
	  </v-row>
	</v-container>
  </template>
  
  <script>
  import { useOAuthService } from "@/services/OAuthService"; // Assuming you have an OAuth service for handling authentication
  
  export default {
	name: "NoPermission",
	setup() {
	  const { logOut } = useOAuthService(); // Using the composition API to import logout function
  
	  const logout = () => {
		logOut(); // Call logout function
	  };
  
	  return {
		logout,
	  };
	},
  };
  </script>
  
  <style scoped>
  .no-permission-page {
	background: #000;
  }
  
  .no-permission-card {
	background: #222;
	border-radius: 10px;
  }
  
  /* Further styling */
  </style>
  