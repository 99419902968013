<template>
  <div class="slider-container">
    <span class="moon-icon">🌜</span>
    <div class="slider-wrapper">
      <input
        type="range"
        min="0"
        max="100"
        :step="stepValue"
        class="slider"
        v-model.number="brightness"
        @input="updateTrackBackground"
      />
      <div class="slider-track-background" :style="trackBackgroundStyle"></div>
    </div>
    <span class="sun-icon">☀️</span>
  </div>
</template>

<script>
  import { ref, watch, computed, onMounted } from 'vue'

  export default {
    name: 'BrightnessSlider',
    props: {
      value: {
        type: Number,
        default: 50,
      },
      steps: {
        type: Number,
        default: 1,
      },
    },
    setup(props, { emit }) {
      const brightness = ref(props.value)

      const updateTrackBackground = () => {
        emit('input', brightness.value)
        emit('brightness-change', brightness.value)
      }

      watch(
        () => props.value,
        newValue => {
          brightness.value = Number(newValue)
          updateTrackBackground()
        }
      )

      const trackBackgroundStyle = computed(() => {
        const colorIntensity = brightness.value / 100
        return {
          background: `linear-gradient(to right, 
          rgba(255, 223, 0, ${colorIntensity}) 0%, 
          rgba(255, 223, 0, ${colorIntensity}) ${brightness.value}%, 
          #ddd ${brightness.value}%, 
          #ddd 100%)`,
        }
      })

      onMounted(() => {
        updateTrackBackground()
      })

      return {
        brightness,
        updateTrackBackground,
        trackBackgroundStyle,
        stepValue: props.steps,
      }
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  .slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    background-color: azure;
    border-radius: 1em;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1em;
  }

  .slider-wrapper {
    position: relative;
    width: calc(100% - 3em);
    margin: 0 1em;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 1em;
    background: transparent;
    outline: none;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  .slider-track-background {
    position: absolute;
    top: 0.5em;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.5em;
    border-radius: 0.25em;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    background: #555;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }

  .slider::-moz-range-thumb {
    width: 1.5em;
    height: 1.5em;
    background: #555;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease-in-out;
  }

  .moon-icon,
  .sun-icon {
    font-size: 2em;
  }

  @media (max-width: 600px) {
    .slider::-webkit-slider-thumb,
    .slider::-moz-range-thumb {
      width: 1em;
      height: 1em;
    }

    .moon-icon,
    .sun-icon {
      font-size: 1.5em;
    }
  }
</style>
