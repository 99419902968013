<template>
  <div class="booking-poi-list">
    <Loader :loading="showLoader" />
    <v-container v-if="showBookingView">
      <v-row dense class="mx-2">
        <!-- Combined Card for Start and End -->
        <v-col cols="12" sm="5" xl="5" lg="5" md="5" @click="openRangeDatePicker">
          <v-card class="date-picker-card" max-width="300" elevation="11" @click.stop="openRangeDatePicker">
            <v-card flat class="my-2 mx-2 pa-3 d-flex flex-row flex-wrap align-center justify-space-between">
              <!-- Start Section -->
              <div class="date-section" style="flex: 1; max-width: 50%; text-align: left">
                <div class="mb-2 d-flex align-center" style="margin-left: 5px">
                  <v-icon class="booking-poi-icon" color="black">mdi-calendar-month-outline</v-icon>
                  <span class="ml-2" style="color: black">{{ $t('booking.start') }}</span>
                </div>
                <div class="my-1 subtitle-2">
                  <v-chip class="ma-2" color="dark" label>
                    {{ moment(range.start).format('ddd. DD.MMM') }}
                  </v-chip>
                </div>
                <div class="mt-1 caption" style="font-size: 0.9rem !important">
                  <v-chip class="ma-2" color="dark" label>
                    {{ moment(range.start).format('HH:mm') }}
                  </v-chip>
                </div>
              </div>

              <!-- End Section -->
              <div class="date-section" style="flex: 1; max-width: 50%; text-align: right">
                <div class="mb-2 d-flex align-center justify-center" style="margin-left: 20px">
                  <v-icon class="booking-poi-icon ml-5" color="black">mdi-calendar-month-outline</v-icon>
                  <span class="ml-2" style="color: black">{{ 'End' }}</span>
                </div>
                <div class="my-1 subtitle-2">
                  <v-chip class="ma-2" color="dark" label>
                    {{ moment(range.end).format('ddd. DD.MMM') }}
                  </v-chip>
                </div>
                <div class="mt-1 caption" style="font-size: 0.9rem !important">
                  <v-chip class="ma-2" color="dark" label>
                    {{ moment(range.end).format('HH:mm') }}
                  </v-chip>
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <div class="d-flex justify-flex-start align-center" v-if="showBookingView">
        <v-chip class="ma-2" color="dark" label>Available Pois : {{ availablePois?.length }}</v-chip>
        <v-btn color="black" class="ml-4" text @click="openDialog">Filters</v-btn>
      </div>
      <v-row dense v-if="availablePois?.length !== 0">
        <v-col @click="goToPoi(poi)" v-for="poi in availablePois" :key="poi.id" cols="12" sm="6" md="4" lg="3">
          <v-card class="poi-card mx-auto my-2" elevation="4" :max-width="300" outlined>
            <v-card-title class="title">{{ poi?.name }}</v-card-title>

            <v-card-subtitle class="subtitle">
              <v-chip small color="primary" text-color="white">
                {{ poi?.category?.name }}
              </v-chip>
            </v-card-subtitle>

            <v-card-text class="content">
              <div class="d-flex align-center mb-2">
                <v-icon small class="mr-1">mdi-format-list-numbered</v-icon>
                <span>{{ 'Floor Number: ' }} {{ poi?.floor?.number }}</span>
              </div>
              <div class="d-flex align-center mb-2">
                <v-icon small class="mr-1">mdi-format-list-bulleted-type</v-icon>
                <span>{{ 'Floor Name: ' }} {{ poi?.floor?.name }}</span>
              </div>
              <div class="d-flex align-center" v-if="poi.capacity">
                <v-icon small class="mr-1">mdi-tag</v-icon>
                <span>{{ 'Capacity: ' }} {{ poi.capacity }}</span>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click.stop="goToPoi(poi)">Book</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Filter Dialog -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ 'Filters' }}</v-card-title>
        <v-card-text>
          <!-- Filter components here -->
          <v-select
            :items="buildings"
            item-value="name"
            item-title="name"
            v-model="selectedBuilding"
            label="Building"
            class="mb-4"
            disabled
            return-object
          ></v-select>
          <v-select v-model="selectedFloor" :items="floors" item-title="name" item-value="name" return-object label="Floor" class="mb-4"></v-select>
          <v-select
            v-model="selectedCategory"
            :items="poiCategories"
            item-title="name"
            item-value="name"
            return-object
            label="Category"
            class="mb-4"
          ></v-select>
          <v-select v-model="selectedPersonCount" :items="personCount" item-title="count" label="Person Count" class="mb-4"></v-select>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancelFilter">Cancel</v-btn>
          <v-btn text @click="applyFilters">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="datePickerDialog" persistent max-width="300px">
      <v-card style="background-color: #0f172a">
        <v-card-title></v-card-title>
        <v-card-text>
          <p v-if="isRangeEqual" class="error-message">Start time cannot be equal to end time.</p>
          <DatePicker
            is-range
            :min-date="new Date()"
            :max-date="moment().add(59, 'days').toDate()"
            v-model.range="tempRange"
            :rules="timeRules"
            mode="dateTime"
            :disabled-dates="disabledDates"
            :is-dark="true"
            is24hr
          ></DatePicker>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn plain color="white" style="background-color: #ff5252" text @click="discardRange()">
            {{ 'Cancel' }}
          </v-btn>
          <v-btn color="white" style="background-color: darkcyan" :disabled="isRangeEqual" @click="confirmRange">
            {{ 'Confirm' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, onMounted, watch } from 'vue'
  import Loader from '@/components/general/Loader.vue'
  import { DatePicker } from 'v-calendar'
  import moment from 'moment'
  import {
    getReservationsByPoi,
    getPoiCategoryById,
    getFloorById,
    getFloors,
    getPoiCategories,
    getBuildings,
    getPoisByCategoryId,
    isStaingOrQa,
  } from '@/controllers/BaseController'
  import lodash from 'lodash'
  import { getStoredItem } from '@/services/utils.js'
  import router from '@/router'
  import { pois } from '@/dummydata/pois'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'BookingPoisList',
    components: { Loader, DatePicker },
    setup() {
      const range = ref({
        start: new Date(),
        end: new Date(),
      })
      const route = useRoute()
      const showLoader = ref(true)
      const allPois = ref([])
      const poiCategories = ref([])
      const buildings = ref([])
      const floors = ref([])
      const selectedBuilding = ref(null)
      const selectedFloor = ref(null)
      const selectedCategory = ref(null)
      const selectedPersonCount = ref(null)
      const disabledDates = ref([
        {
          repeat: {
            weekdays: [7, 1], // 1 is Monday, 7 is Sunday
          },
        },
      ])
      const isRangeEqual = computed(() => {
        return moment(tempRange.value.start).isSame(tempRange.value.end, 'minute')
      })
      const showBookingView = ref(true) // Adjust according to your logic
      const tempRange = ref({ ...range.value }) // Temporary range for the date picker dialog
      const availablePoisLength = ref(0)
      const datePickerDialog = ref(false)
      const dialog = ref(false)
      const poiMinTime = ref('00:00')
      const poiMaxTime = ref('23:59')
      const availablePois = ref([])
      const isPIA = ref(false)
      const personCount = ref([
        { count: 1 },
        { count: 2 },
        { count: 3 },
        { count: 4 },
        { count: 5 },
        { count: 6 },
        { count: 7 },
        { count: 8 },
        { count: 9 },
        { count: 10 },
      ])
      const originalFilters = ref({
        selectedFloor: '',
        selectedCategory: '',
        selectedPersonCount: '',
      })

      const handleRouteChange = async () => {
        let routeName = route.name
        if (routeName === 'BookingPoisList') {
          await fetchInitialData()
          showBookingView.value = true
        } else {
          showLoader.value = false
          showBookingView.value = false
        }
      }

      const fetchAvailablePois = async () => {
        try {
          showLoader.value = true
          let startDateTIme = moment(range.value.start).toDate()
          let endDateTIme = moment(range.value.end)

          let pois = JSON.parse(JSON.stringify(allPois.value))
          let floorSelected = selectedFloor.value
          let categorySelected = selectedCategory.value
          let personCountSelected = selectedPersonCount.value

          if (floorSelected.id !== 1) {
            pois = pois.filter(poi => poi.floors.ids[0] === floorSelected.id)
          }
          if (categorySelected.id !== 1) {
            pois = pois.filter(poi => poi.poiCategories.ids[0] === categorySelected.id)
          }
          if (personCountSelected !== 1) {
            pois = pois.filter(poi => poi.capacity >= personCountSelected)
          }
          let reservationPromises = pois.map(poi => getReservationsByPoi(poi.id, startDateTIme, endDateTIme))

          let reservationsResults = await Promise.all(reservationPromises)
          let freePois = []

          let detailsPromises = reservationsResults.map(async (reservations, index) => {
            if (reservations.length === 0) {
              let poi = pois[index]
              let [poiCategory, floor] = await Promise.all([getPoiCategoryById(poi.poiCategories.ids[0]), getFloorById(poi.floors.ids[0])])
              poi.category = poiCategory
              poi.floor = floor
              return poi
            }
            return null
          })
          freePois = (await Promise.all(detailsPromises)).filter(poi => poi !== null)
          availablePois.value = []
          availablePois.value = freePois
        } catch (error) {
          console.error('Failed to load available pois:', error)
        } finally {
          showLoader.value = false
        }
      }

      const goToPoi = async poi => {
        let POI = JSON.parse(JSON.stringify(poi))
        try {
          showLoader.value = true
          await router.push({
            name: 'createManageBooking',
            params: {
              building_id: POI.buildingId,
              category_id: POI.category.id,
              poi_id: POI.id,
            },
          })
        } catch (error) {
          console.error('Failed to navigate to POI:', error)
        } finally {
          showLoader.value = false
        }
      }

      const timeRules = computed(() => {
        return [
          {
            hours: hour => {
              const now = moment()
              const startMoment = moment(tempRange.value.start)
              const endMoment = moment(tempRange.value.end)

              const minHour = moment(poiMinTime.value, 'HH:mm').hour()
              const maxHour = moment(poiMaxTime.value, 'HH:mm').hour()

              if (startMoment.isSame(now, 'day')) {
                return hour >= now.hour() && hour >= minHour && hour <= maxHour
              }

              if (startMoment.isSame(endMoment, 'day')) {
                return hour >= minHour && hour <= maxHour && hour >= startMoment.hour()
              }

              return hour >= minHour && hour <= maxHour
            },
            minutes: minute => minute % 15 === 0,
          },
        ]
      })

      const openRangeDatePicker = () => {
        tempRange.value = { ...range.value } // Store the current range
        datePickerDialog.value = true
      }

      const confirmRange = async () => {
        range.value = { ...tempRange.value } // Update the range with the confirmed range
        datePickerDialog.value = false
        await fetchAvailablePois()
      }

      const discardRange = () => {
        datePickerDialog.value = false // Close the dialog without updating the range
      }

      const checkInitialTime = () => {
        let start = moment(range.value.start)
        let end = moment(range.value.end)

        const moveToNextWeekday = date => {
          while (['Saturday', 'Sunday'].includes(date.format('dddd'))) {
            date.add(1, 'days')
          }
        }

        const setTimes = (start, end, minTime, maxTime) => {
          start.set({
            hour: minTime.hour(),
            minute: minTime.minute(),
            second: 0,
            millisecond: 0,
          })
          end.set({
            hour: maxTime.hour(),
            minute: maxTime.minute(),
            second: 0,
            millisecond: 0,
          })
        }

        let minTime = moment(poiMinTime.value, 'HH:mm')
        let maxTime = moment(poiMaxTime.value, 'HH:mm')

        const now = moment()

        // If current date is today and time exceeds max time, move to next day
        if (start.isSame(now, 'day') && now.isAfter(maxTime)) {
          start.add(1, 'days')
        }

        // Move start to next weekday if it's a weekend
        moveToNextWeekday(start)

        // Ensure end is also a weekday
        moveToNextWeekday(end)

        // Ensure end date is not before start date
        if (end.isBefore(start)) {
          end = moment(start).add(1, 'days')
          moveToNextWeekday(end)
        }

        setTimes(start, end, minTime, maxTime)

        range.value.start = start.toDate()
        range.value.end = end.toDate()
      }

      const fetchInitialData = async () => {
        try {
          showLoader.value = true

          // Simultaneously fetch categories, stored building, and floors
          let [poiCategoriesResult, storedBuildingName, poiFloors, apiBuildings] = await Promise.all([
            getPoiCategories(),
            getStoredItem('selectedBuilding'),
            getFloors(),
            getBuildings(),
          ])

          const storedBuilding = lodash.find(apiBuildings, {
            name: storedBuildingName,
          })
          selectedBuilding.value = storedBuilding
          buildings.value = apiBuildings

          const allFloors = lodash
            .chain(poiFloors)
            .filter(floor => floor.building.id === storedBuilding?.id && floor.active)
            .orderBy('name')
            .value()

          allFloors.unshift({ id: 1, name: 'All' })
          floors.value = allFloors
          selectedFloor.value = allFloors[0]

          if (!isPIA.value) {
            poiCategoriesResult = lodash.filter(poiCategoriesResult, category => category.name !== 'Showers' && category.name !== 'Restrooms')
          }
          const poisPromises = poiCategoriesResult.map(category =>
            getPoisByCategoryId(category.id).then(pois => pois.filter(poi => poi.buildingId === storedBuilding?.id))
          )

          const filteredPoisArrays = await Promise.all(poisPromises)
          let POIS = []
          if (isPIA.value) {
            POIS = lodash.flatten(filteredPoisArrays)
          } else {
            POIS = lodash
              .flatten(filteredPoisArrays)
              .filter(poi => poi?.availableFrom !== undefined && poi?.availableUntil !== undefined && poi?.bookable === true)
          }

          const categories = await Promise.all(
            POIS.map(async poi => {
              poi.category = await getPoiCategoryById(poi.poiCategories.ids[0])
              return poi.category
            })
          )

          allPois.value = POIS
          pois.value = POIS

          // Convert time and calculate min and max
          const timeConvertedPois = POIS.map(poi => ({
            ...poi,
            availableFrom: moment.utc(poi.availableFrom, 'HH:mm'),
            availableUntil: moment.utc(poi.availableUntil, 'HH:mm'),
          }))

          poiMinTime.value = lodash.minBy(timeConvertedPois, 'availableFrom')?.availableFrom.format('HH:mm') || '00:00'
          poiMaxTime.value = lodash.maxBy(timeConvertedPois, 'availableUntil')?.availableUntil.format('HH:mm') || '23:59'

          checkInitialTime()

          let uniqueCategories = lodash.uniqBy(lodash.orderBy(categories, 'name'), 'id')

          if (!isPIA.value) {
            uniqueCategories.unshift({ id: 1, name: 'All' })
            poiCategories.value = uniqueCategories
            selectedCategory.value = uniqueCategories[0]
            selectedPersonCount.value = 1
          } else {
            poiCategories.value = uniqueCategories
            selectedCategory.value = lodash.find(uniqueCategories, category => category.name?.toLowerCase() === 'meeting room')
            selectedPersonCount.value = 1
          }

          await fetchAvailablePois()
        } catch (error) {
          console.error('Failed to initialize POIs:', error)
        } finally {
          showLoader.value = false
        }
      }

      const openDialog = () => {
        let filters = JSON.parse(JSON.stringify(originalFilters.value))
        dialog.value = true
        filters.selectedFloor = selectedFloor.value
        filters.selectedCategory = selectedCategory.value
        filters.selectedPersonCount = selectedPersonCount.value
        originalFilters.value = filters
      }

      const cancelFilter = () => {
        dialog.value = false
        selectedFloor.value = originalFilters.value.selectedFloor
        selectedCategory.value = originalFilters.value.selectedCategory
        selectedPersonCount.value = originalFilters.value.selectedPersonCount
      }

      const applyFilters = async () => {
        try {
          dialog.value = false
          showLoader.value = true
          let floor = JSON.parse(JSON.stringify(selectedFloor.value))
          let category = JSON.parse(JSON.stringify(selectedCategory.value))
          let personCount = JSON.parse(JSON.stringify(selectedPersonCount.value))

          await fetchAvailablePois()
        } catch (error) {
          console.error('Failed to apply filters:', error)
        } finally {
          dialog.value = false
        }
      }

      onMounted(async () => {
        let routeName = route.name
        isPIA.value = await isStaingOrQa()
        if (routeName === 'BookingPoisList') {
          await fetchInitialData()
          showBookingView.value = true
        } else {
          showLoader.value = false
          showBookingView.value = false
        }
      })
      watch(route, handleRouteChange, { immediate: true })

      return {
        showLoader,
        range,
        poiCategories,
        buildings,
        floors,
        selectedBuilding,
        selectedFloor,
        selectedCategory,
        selectedPersonCount,
        disabledDates,
        showBookingView,
        tempRange,
        availablePoisLength,
        datePickerDialog,
        dialog,
        poiMinTime,
        poiMaxTime,
        availablePois,
        personCount,
        timeRules,
        openRangeDatePicker,
        confirmRange,
        discardRange,
        checkInitialTime,
        fetchAvailablePois,
        fetchInitialData,
        moment,
        openDialog,
        cancelFilter,
        applyFilters,
        goToPoi,
        isRangeEqual,
        isPIA,
      }
    },
  })
</script>

<style scoped>
  .booking-poi-list {
    background: linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%);
    padding: 16px;
    border-radius: 12px;
    height: auto;
  }

  .date-picker-card {
    background: #ffffff;
    border-radius: 12px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .date-picker-card:hover {
    transform: translateY(-5px);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }

  .poi-card {
    background: #ffffff;
    border-radius: 12px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .poi-card:hover {
    transform: translateY(-5px);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }

  .v-card-title.title {
    font-weight: 600;
    font-size: 1.2rem;
    color: #333;
  }

  .v-card-subtitle.subtitle {
    color: #007bff;
  }

  .v-card-actions .v-btn {
    color: #4caf50;
    text-transform: uppercase;
  }

  .error-message {
    color: red;
    margin-bottom: 10px;
  }

  .booking-show .v-chip {
    margin-right: 8px;
  }
</style>
