import { Device } from '@capacitor/device'
import { loadConfig } from '@/configLoader'

// Centralized configuration for branding assets, simplifying import paths
const brandingAssets = {
  artinvest: {
    background: {
      web: require('@/assets/Branding/Artinvest/artivest-login-background_web.jpg'),
      mobile: require('@/assets/Branding/Artinvest/artivest-login-background_mobile.jpg'),
    },
    logo: require('@/assets/Branding/Artinvest/artinvest-logo.png'),
    loaderLogo: require('@/assets/Branding/Artinvest/artinvest-loader.png'),
    toolbarLogo: require('@/assets/Branding/Artinvest/artinvest-loader.png'),
  },
  hammerbrooklyn: {
    background: {
      web: require('@/assets/Branding/FactoryHammerbrooklyn/hammerbrooklyn_desktop_login.jpg'),
      mobile: require('@/assets/Branding/FactoryHammerbrooklyn/hammerbrooklyn_mobile_login.png'),
    },
    logo: require('@/assets/Branding/FactoryHammerbrooklyn/hammerbrooklyn_login_logo.png'),
    loaderLogo: require('@/assets/Branding/FactoryHammerbrooklyn/hammerbrooklyn_loader.png'),
    toolbarLogo: require('@/assets/Branding/FactoryHammerbrooklyn/hammerbrooklyn_logo_white.png'),
  },
  pia: {
    background: {
      web: require('@/assets/Branding/Pia/pia-login-background_web.jpg'),
      mobile: require('@/assets/Branding/Pia/pia-login-background_mobile.jpg'),
    },
    logo: require('@/assets/Branding/Pia/pia_logo_dark.png'),
    loaderLogo: require('@/assets/Branding/Pia/pinestack_logo.png'),
    toolbarLogo: require('@/assets/Branding/Pia/pinestack_logo.png'),
  },
}

// Utility function to determine the customer based on API key
export const getCustomer = async () => {
  const { apiKey } = await loadConfig()
  if (apiKey.includes('staging')) return 'pia'
  if (apiKey.includes('qa')) return 'pia'
  if (apiKey.includes('hammerbrooklyn')) return 'hammerbrooklyn'
  if (apiKey.includes('artinvest')) return 'artinvest'
  return null
}

// Determines the appropriate branding image based on customer and platform
export const getBrandingImage = async type => {
  const customer = await getCustomer()
  const { platform } = await Device.getInfo()
  const isWeb = platform === 'web'

  if (!customer || !brandingAssets[customer]) return null
  const asset = brandingAssets[customer][type]
  return isWeb ? asset.web : asset.mobile
}

// Get login background image
export const getLoginBackgroundImage = () => {
  let loginImage = getBrandingImage('background')
  return loginImage
}

// Get login logo image
export const getLoginLogoImage = async () => {
  const customer = await getCustomer()
  console.log(brandingAssets[customer]?.logo)
  return brandingAssets[customer]?.logo
}

// Get loader logo image
export const getLoaderLogoImage = async () => {
  const customer = await getCustomer()
  return brandingAssets[customer]?.loaderLogo
}

export const getToolbarLogoImage = async () => {
  const customer = await getCustomer()
  return brandingAssets[customer]?.toolbarLogo
}

export const getToolBarCustomerName = async () => {
  const customer = await getCustomer()
  return customer === 'pia' ? 'Pia' : 'FactoryHammerbrooklyn'
}

export const getPrimaryColor = async () => {
  const customer = await getCustomer()
  if (customer === 'pia') return '#43A7F9'
  if (customer === 'artinvest') return '#50C878'
  if (customer === 'hammerbrooklyn') return 'yellow'
}

export const getBottomBarColor = async () => {
  const customer = await getCustomer()
  if (customer === 'artinvest') return '#50C878'
  if (customer === 'hammerbrooklyn') return 'yellow'
}
