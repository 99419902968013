<template>
  <v-container v-if="showDetail" class="info-detail">
    <v-dialog v-model="showDetail" max-width="800px">
      <v-card class="elevation-3">
        <v-toolbar flat class="grey lighten-3">
          <v-toolbar-title class="text-h5 black--text">
            {{ localInfoItem.title || 'No Title' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDetail">
            <v-icon class="black--text">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container class="my-2">
          <v-btn elevation="3" large outlined>
            {{ localInfoItem.infoCategory?.name || 'No Category' }}
          </v-btn>
        </v-container>

        <v-container v-if="localInfoItem.titleImage" class="my-4">
          <v-img
            :src="localInfoItem.titleImage"
            width="100%"
            max-height="300"
            contain
          ></v-img>
        </v-container>

        <v-card-text
          class="my-2"
          style="font-weight: bold; font-size: 1rem; font-style: italic"
        >
          {{ localInfoItem.shortText || 'No short text available' }}
        </v-card-text>

        <v-container v-if="localInfoItem.text">
          <v-card elevation="0">
            <v-card-text>
              <div v-html="infoItemText"></div>
            </v-card-text>
          </v-card>
        </v-container>

        <v-container id="images" class="my-4" v-if="localInfoItem.images">
          <v-row>
            <v-col
              v-for="(image, index) in localInfoItem.images"
              :key="index"
              cols="12"
              md="4"
            >
              <v-img
                :src="image"
                width="100%"
                max-height="300"
                contain
                class="elevation-2"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>

        <v-container id="files" class="my-4" v-if="localInfoItem.filesArray">
          <v-row>
            <v-col
              v-for="file in localInfoItem.filesArray"
              :key="file.source"
              cols="12"
              md="4"
            >
              <v-btn
                style="font-size: 0.7rem !important"
                class="file-download-btn"
                @click="onActivateFileClick(file)"
              >
                {{ file.fileName }}
                <v-icon right dark>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          id="button"
          class="my-4"
          v-if="
            localInfoItem.displayButton === 'true' &&
            localInfoItem.buttonUrl !== ''
          "
        >
          <v-btn
            color="#EC2C55"
            :href="localInfoItem.buttonUrl"
            target="_blank"
            class="ma-2 white--text elevation-2"
          >
            {{ localInfoItem.buttonText }}
            <v-icon right dark>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-container>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { ref, toRefs, watch } from 'vue'

  export default {
    name: 'InfoItemDetails',
    props: {
      infoItem: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    setup(props) {
      const { infoItem } = toRefs(props)
      const showDetail = ref(false)
      const localInfoItem = ref({ ...infoItem.value })
      const infoItemText = ref('')

      watch(
        infoItem,
        newVal => {
          localInfoItem.value = { ...newVal }
          localInfoItem.value.titleImage = localInfoItem.value.image || null
          localInfoItem.value.images =
            localInfoItem.value.attachedImages || null
          infoItemText.value = localInfoItem.value.text || ''
        },
        { immediate: true }
      )

      const onActivateFileClick = file => {
        const a = document.createElement('a')
        a.href = file.source
        a.download = file.fileName
        a.click()
      }

      const openInfoItemDetail = item => {
        localInfoItem.value = JSON.parse(JSON.stringify(item))
        localInfoItem.value.titleImage = localInfoItem.value.image || null
        localInfoItem.value.images = localInfoItem.value.attachedImages || null
        infoItemText.value = localInfoItem.value.text || ''
        showDetail.value = true
      }

      const closeDetail = () => {
        showDetail.value = false
      }

      return {
        showDetail,
        localInfoItem,
        infoItemText,
        onActivateFileClick,
        openInfoItemDetail,
        closeDetail,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .info-detail {
    .v-dialog {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;

      @media (max-width: 600px) {
        .v-card {
          margin: 0;
          border-radius: 0;
          height: 100%;
          max-height: none;
        }
      }
    }

    .v-btn {
      width: auto;
      min-width: 120px;
      padding: 0.5rem 1rem;

      &.long-text {
        width: auto;
      }
    }

    .info-detail .file-download-btn {
      background: linear-gradient(145deg, #6a11cb, #2575fc) !important;
      color: white;
      text-transform: none;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      padding: 0.5rem 1rem;
      white-space: normal;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none; /* Ensure no default browser styles are applied */
      cursor: pointer; /* Make it clear it's clickable */
      border-radius: 4px; /* Optional: rounded corners for aesthetics */

      &:hover {
        background: linear-gradient(145deg, #2575fc, #6a11cb);
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.35);
        transform: translateY(-3px);
      }
    }

    .secondary {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 20px;
    }

    .v-card-text {
      font-size: 1rem;
      font-weight: bold;
      font-style: italic;
    }

    #text,
    #images,
    #files,
    #button {
      margin-top: 2rem;
      margin-bottom: 2rem;

      .v-col {
        margin-bottom: 1rem;
      }
    }
  }
</style>
