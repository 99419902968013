<template>
  <div class="switch-wrapper">
    <input
      type="checkbox"
      :checked="isChecked"
      @change="toggleSwitch"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'Switch',
  props: {
    state: {
      type: Boolean,
      required: true
    },
    updateSwitch: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const isChecked = ref(props.state);

    const toggleSwitch = () => {
      isChecked.value = !isChecked.value;
      props.updateSwitch(isChecked.value);
    };

    watch(() => props.state, (newValue) => {
      isChecked.value = newValue;
    });

    return {
      isChecked,
      toggleSwitch
    };
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: auto; /* Ensure the wrapper only takes necessary space */
}

input[type="checkbox"] {
  height: 50px; /* Adjust the height */
  width: 50px; /* Adjust the width */
  -webkit-appearance: none;
  box-shadow: 
    -5px -5px 7.5px rgba(255, 255, 255, 0.5),
    5px 5px 7.5px rgba(70, 70, 70, 0.12);
  position: relative;
  border-radius: 50%;
  border: 5px solid #ececec; /* Adjust the border */
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent; /* Ensure no background color */
}

input[type="checkbox"]::after {
  font-family: FontAwesome;
  content: "\f011";
  color: #7a7a7a;
  font-size: 25px; /* Adjust the font size */
}

input[type="checkbox"]:checked {
  box-shadow: 
    -5px -5px 7.5px rgba(255, 255, 255, 0.5),
    5px 5px 7.5px rgba(70, 70, 70, 0.12),
    inset -5px -5px 7.5px rgba(255, 255, 255, 0.5),
    inset 5px 5px 7.5px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked::after {
  color: #15e38a;
}
</style>
