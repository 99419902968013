// src/services/mqtt.js
import mqtt from 'mqtt';
import { loadConfig } from '@/configLoader';

class MqttService {
  constructor() {
    this.client = null;
    this.isConnected = false;
  }

  async connect() {
    try {
      const config = await loadConfig();
      return new Promise((resolve, reject) => {
        const { host, userName, password } = config.mqtt;
        this.client = mqtt.connect(host, {
          username: userName,
          password: password
        });

        this.client.on('connect', () => {
          this.isConnected = true;
          console.log('Connected to MQTT broker');
          resolve();
        });

        this.client.on('error', (err) => {
          this.isConnected = false;
          console.error('Connection error:', err);
          reject(err);
        });
      });
    } catch (error) {
      console.error('Error loading configuration:', error);
    }
  }

  subscribe(topic, callback) {
    if (this.isConnected) {
      this.client.subscribe(topic, (err) => {
        if (err) {
          console.error(`Subscription error: ${err}`);
        }
      });

      this.client.on('message', (msgTopic, message) => {
        if (msgTopic === topic) {
          callback(message.toString());
        }
      });
    } else {
      console.error('Client is not connected.');
    }
  }

  publish(topic, message) {
    if (this.isConnected) {
      this.client.publish(topic, message);
    } else {
      console.error('Client is not connected.');
    }
  }

  disconnect() {
    if (this.client) {
      this.client.end();
      this.isConnected = false;
      console.log('Disconnected from MQTT broker');
    }
  }
}

export default new MqttService();
