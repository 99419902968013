<template>
  <v-app class="app-background">
    <v-main :style="{
      paddingBottom: showBottomNav ? `${bottomNavHeight}px` : '0px',
    }">
      <BottomNavigation ref="bottomNavRef" v-if="showBottomNav" :selected="selected"
        @update:selected="selected = $event" />
      <router-view v-slot="{ Component }">
        <v-slide-x-transition mode="out-in">
          <component :is="Component" />
        </v-slide-x-transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BottomNavigation from '@/components/general/BottomNavigation'
import { getStoredItem, setStoredItem } from '@/services/utils'
import { getCustomer } from '@/assets/Branding/branding.js'

export default {
  components: {
    BottomNavigation,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const selected = ref(0)
    const bottomNavRef = ref(null)
    const bottomNavHeight = ref(0)

    const showBottomNav = computed(() => route.name !== 'Login' && !window.location.href.includes('/oauth2'))

    onMounted(async () => {
      try {
        let storedBuilding = await getStoredItem('selectedBuilding')
        const { getBuildings } = await import('@/controllers/BaseController')
        let abc = await getBuildings()
        console.log('abc', abc)
        if (!storedBuilding) {
          let customerName = await getCustomer()
          const { getBuildings } = await import('@/controllers/BaseController')
          const apiBuildings = await getBuildings()
          console.log('apiBuildings', apiBuildings)
          if (customerName === 'pia') {
            let building = apiBuildings.find(building => building.id === 'e1e1c326-f8e5-472b-9ea7-673c59550d46')
            if (building) {
              storedBuilding = building.name
            }
          } else {
            storedBuilding = apiBuildings[0]?.name || null
          }
          await setStoredItem('selectedBuilding', storedBuilding)
        }
      } catch (error) {
        console.log(error)
      }

      if (bottomNavRef.value) {
        bottomNavHeight.value = bottomNavRef.value.$el.clientHeight
      }
    })

    watch(
      () => bottomNavRef.value,
      (newVal, oldVal) => {
        if (newVal) {
          bottomNavHeight.value = newVal.$el.clientHeight
        }
      },
      { immediate: true }
    )
    watch(
      () => route.path,
      newPath => {
        if (newPath)
          if (newPath.includes('/infocenter')) {
            selected.value = 0
          }
        if (newPath.includes('/building')) {
          selected.value = 1
        }
        if (newPath.includes('/booking')) {
          selected.value = 2
        }
        if (newPath.includes('/chat')) {
          selected.value = 3
        }
        if (newPath.includes('/marketing')) {
          selected.value = 4
        }
        if (newPath.includes('/menu')) {
          selected.value = 5
        }
      },
      { immediate: true }
    )

    return {
      selected,
      showBottomNav,
      bottomNavRef,
      bottomNavHeight,
    }
  },
}
</script>

<style scoped>
.app-background {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
}

.unchecked .btn-title {
  visibility: visible !important;
  margin-top: 2.4rem;
}
</style>
