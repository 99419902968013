<template>
  <div class="time-slots-wrapper">
    <!-- Color Legend -->
    <div class="legend">
      <v-chip class="legend-chip" color="#e53935" text-color="white">
        <v-icon left>mdi-calendar-remove</v-icon>
        Booked
      </v-chip>
      <v-chip class="legend-chip" color="#4caf50" text-color="white">
        <v-icon left>mdi-calendar-check</v-icon>
        Available
      </v-chip>
      <v-chip class="legend-chip" color="#b0bec5" text-color="white">
        <v-icon left>mdi-clock-outline</v-icon>
        Past
      </v-chip>
    </div>

    <!-- Time Slots -->
    <div class="time-slots">
      <div
        v-for="(slot, index) in slots"
        :key="index"
        :class="['slot', { booked: isBooked(slot), own: isOwnBooking(slot), past: isPast(slot), available: isAvailable(slot) }]"
        :style="isAvailable(slot) ? { '--booking-percentage': getBookingPercentage() + '%' } : {}"
      >
        <span v-if="isOwnBooking(slot)" class="own-icon">👤</span>
        {{ slot.time }}
        <div v-if="isBooked(slot) !== false" class="tooltip">
          <div v-for="(name, idx) in getBookingNames(slot)" :key="idx">{{ idx + 1 }}. {{ name }}</div>
          <div v-if="isAvailable(slot)">{{ freeBookings.length }} places available</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue'
  import lodash from 'lodash'
  import moment from 'moment'

  export default {
    name: 'TimeSlot',
    props: {
      startTime: {
        type: Date,
        required: true,
      },
      endTime: {
        type: Date,
        required: true,
      },
      bookings: {
        type: Array,
        default: () => [],
      },
      freeBookings: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const slots = computed(() => {
        const slotsArray = []
        let currentTime = new Date(props.startTime)

        // Generate slots in 15-minute intervals between startTime and endTime
        while (currentTime <= new Date(props.endTime)) {
          slotsArray.push({
            time: currentTime.toTimeString().substr(0, 5),
            name: `Slot ${currentTime.toTimeString().substr(0, 5)}`,
          })
          currentTime.setMinutes(currentTime.getMinutes() + 15)
        }

        return slotsArray
      })

      const isToday = computed(() => {
        const today = new Date().setHours(0, 0, 0, 0)
        return new Date(props.startTime).setHours(0, 0, 0, 0) === today
      })

      const getBookingNames = slot => {
        const overlappingBookings = props.bookings.filter(booking => {
          const [slotHour, slotMinute] = slot.time.split(':').map(Number)
          const slotTimeInMinutes = slotHour * 60 + slotMinute

          const bookingStart = new Date(booking.start)
          const bookingEnd = new Date(booking.end)

          const bookingStartTimeInMinutes = bookingStart.getHours() * 60 + bookingStart.getMinutes()
          const bookingEndTimeInMinutes = bookingEnd.getHours() * 60 + bookingEnd.getMinutes()

          return (
            slotTimeInMinutes === bookingStartTimeInMinutes ||
            slotTimeInMinutes === bookingEndTimeInMinutes ||
            (slotTimeInMinutes > bookingStartTimeInMinutes && slotTimeInMinutes < bookingEndTimeInMinutes)
          )
        })

        const uniqueNames = lodash.uniq(overlappingBookings.map(booking => booking.name))

        return uniqueNames
      }

      const isBooked = slot => {
        const [slotHour, slotMinute] = slot.time.split(':').map(Number)
        const slotTimeInMinutes = slotHour * 60 + slotMinute

        const isBookedSlot = props.bookings.some(booking => {
          const bookingStart = new Date(booking.start)
          const bookingEnd = new Date(booking.end)

          const bookingStartTimeInMinutes = bookingStart.getHours() * 60 + bookingStart.getMinutes()
          const bookingEndTimeInMinutes = bookingEnd.getHours() * 60 + bookingEnd.getMinutes()

          return slotTimeInMinutes >= bookingStartTimeInMinutes && slotTimeInMinutes < bookingEndTimeInMinutes
        })

        if (isBookedSlot) {
          const isFree = props.freeBookings.some(booking => {
            const bookingStart = new Date(booking.start)
            const bookingEnd = new Date(booking.end)

            const bookingStartTimeInMinutes = bookingStart.getHours() * 60 + bookingStart.getMinutes()
            const bookingEndTimeInMinutes = bookingEnd.getHours() * 60 + bookingEnd.getMinutes()

            return slotTimeInMinutes >= bookingStartTimeInMinutes && slotTimeInMinutes < bookingEndTimeInMinutes
          })

          return isFree ? null : true
        }

        return false
      }

      const isOwnBooking = slot => {
        const [slotHour, slotMinute] = slot.time.split(':').map(Number)
        const slotTimeInMinutes = slotHour * 60 + slotMinute

        return props.bookings.some(booking => {
          const bookingStart = new Date(booking.start)
          const bookingEnd = new Date(booking.end)

          const bookingStartTimeInMinutes = bookingStart.getHours() * 60 + bookingStart.getMinutes()
          const bookingEndTimeInMinutes = bookingEnd.getHours() * 60 + bookingEnd.getMinutes()

          return booking.own && slotTimeInMinutes >= bookingStartTimeInMinutes && slotTimeInMinutes < bookingEndTimeInMinutes
        })
      }

      const isPast = slot => {
        if (!isToday.value) {
          return false
        }
        const [slotHour, slotMinute] = slot.time.split(':').map(Number)
        const slotDate = new Date()
        slotDate.setHours(slotHour)
        slotDate.setMinutes(slotMinute)
        slotDate.setSeconds(0)
        slotDate.setMilliseconds(0)

        return slotDate < new Date()
      }

      const isAvailable = slot => isBooked(slot) === null && !isPast(slot)

      const getBookingPercentage = () => {
        const totalSlots = props.bookings.length + props.freeBookings.length
        const bookedSlots = totalSlots - props.freeBookings.length
        return (bookedSlots / totalSlots) * 100
      }

      return {
        slots,
        isToday,
        getBookingNames,
        isBooked,
        isOwnBooking,
        isPast,
        isAvailable,
        getBookingPercentage,
      }
    },
  }
</script>

<style scoped>
  .time-slots-wrapper {
    padding: 20px;
  }

  /* Legend styling */
  .legend {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .legend-chip {
    margin: 0 10px;
    font-weight: bold;
  }

  /* Time slots grid styling */
  .time-slots {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 15px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  /* Slot styling */
  .slot {
    position: relative;
    background-color: #4caf50;
    color: white;
    font-weight: bold;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .slot.available::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--booking-percentage);
    height: 100%;
    background-color: #e53935;
    z-index: 1;
  }

  .slot.booked {
    background-color: #e53935;
  }

  .slot.available {
    position: relative;
  }

  .slot.past {
    background-color: #b0bec5;
    color: #607d8b;
    cursor: not-allowed;
  }

  .slot:hover:not(.past) {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  .slot.past.booked,
  .slot.past.available {
    background-color: #b0bec5;
    color: #607d8b;
    cursor: not-allowed;
  }

  .slot .own-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 16px;
  }

  .tooltip {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .slot:hover .tooltip {
    opacity: 1;
  }

  /* Responsive Styling */
  @media (max-width: 600px) {
    .time-slots {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 10px;
    }

    .slot {
      padding: 15px;
      font-size: 12px;
    }
  }

  @media (max-width: 400px) {
    .time-slots {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      gap: 8px;
    }

    .slot {
      padding: 10px;
      font-size: 10px;
    }
  }
</style>
