export const floors = [
  {
    id: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
    version: '1c55e7f6-290d-4814-b0ee-067ee1ff8374',
    tags: {},
    configs: {
      'room-control-radius': '10',
      externalId: '200971',
      floorplan: '9a3023f1-f4d5-4d94-9172-c45c979fca3f',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Etage 1',
    active: true,
    number: '1',
    northEastCorner: {
      lat: 53.54605086217426,
      long: 10.009156962813059,
    },
    southWestCorner: {
      lat: 53.544765951278606,
      long: 10.0066339219768,
    },
    pois: {
      type: 'pois',
      ids: [
        '1f27a3bd-89e4-49c4-aa91-cdc2f8599151',
        'ebbd1f67-32d5-464a-b3fd-67f91ac688a4',
        '286eb731-0703-4c95-a547-0205a5025fea',
        'bae09d78-e67c-4580-bc7e-9aa244009c7f',
        '759b3008-fb81-4a06-b9eb-33363eaa0e64',
        'f73cdf85-4ce4-4a19-9257-1b0990bee123',
        '315586d7-8cb6-49b8-9a50-33700c589f13',
        'cadb8d4b-7cde-462a-b2b1-1748414fa35a',
        '443ee6f9-7cef-420b-a8d7-91dec848df4e',
        '06e82361-5dd4-4ecc-b381-29d214f49ef8',
        'ddf52128-9ff3-405a-bbbe-8033c1390f2f',
        '1f0ed459-c9e3-4fc1-899c-c66ef37550a2',
        '964fd8a7-af3c-406a-b1f3-1b1227cdff23',
        '6c6ede9f-1113-4cfb-a42d-12b05ffcd30a',
        '5c8b2e76-366c-4dfa-b751-56ca3dc9058a',
        '40863863-b98c-4180-a2cd-7b283c65d0de',
        'd4a66b1f-facf-43b6-95c9-796f29b1bc8c',
        'db6712f0-6874-4d01-b32b-ee9fc1b7861a',
        'f155a1a0-e870-473e-abec-97203ef5d979',
        '5c1b5eea-5325-471c-925e-3e1dd08b681f',
      ],
    },
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    type: 'floors',
  },
  {
    id: 'adca44eb-e1ba-4653-b60d-9c6b44ba5196',
    version: '29aa8d07-eae0-4c6d-974f-a58b3aca4c7b',
    tags: {},
    configs: {
      'room-control-radius': '10',
      externalId: '200970',
      floorplan: '36198ef7-dbbc-4736-843d-f4322541fd6e',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Etage 0',
    active: true,
    number: '0',
    northEastCorner: {
      lat: 53.545778651537915,
      long: 10.008963594082424,
    },
    southWestCorner: {
      lat: 53.54491078172688,
      long: 10.007244047528099,
    },
    pois: {
      type: 'pois',
      ids: [
        'b55a56ac-be16-4164-9816-9730ebe71525',
        '941165a6-1ede-47dd-8ae8-c1e78bef1f95',
        'd601270d-21b5-467f-aa7f-379de0c7dd85',
        '45c4ae5d-da4e-474c-9bfd-d502984868e4',
        '6a2354a7-1aa6-4dcf-a3ff-7b7c526ce7c8',
        'd2f149a7-8ddf-434a-860f-9a5d1b732bcc',
        'b49e36c9-789d-4d77-b3b8-f4cf0f51e38a',
        '3262f6be-c114-4339-8f24-d2b9e29ff501',
        '865889fe-eb3c-4adb-b3d4-0414ca378522',
        '961a2864-237b-4eca-802e-10275721a6b3',
        '57d04fcd-1bbf-4ef3-aefd-9af2daf6cd7d',
        'ec0188f0-9ea9-4559-aa9b-abed6ec33a22',
        'a617c9a6-5ae4-42b4-9ec5-9c8d22f84267',
        '88eb9395-36c4-4bff-9353-95c515aa1541',
        '8e920bbe-041f-46e9-a845-0a989a1160cf',
        'f8e5af9f-dbd2-4b84-a902-d8b795195eda',
        'fd8fcb9c-0a4e-4d5c-a868-526f25c5f57e',
        '32656a1c-631e-411f-8240-03784fc2b2fd',
      ],
    },
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    type: 'floors',
  },
  {
    id: '365a50d0-a10a-4586-b79d-3a22577f85ca',
    version: '5740dd85-9988-4940-ae38-6580bd14ac6c',
    tags: {},
    configs: {
      'room-control-radius': '10',
      'has-live-occupancy': 'true',
      externalId: '200972',
      floorplan: '40c76da0-c187-408b-a43a-12982b9e21b9',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Etage 2',
    active: true,
    number: '2',
    northEastCorner: {
      lat: 53.5459084479133,
      long: 10.00897590946902,
    },
    southWestCorner: {
      lat: 53.54487831380667,
      long: 10.006931814357717,
    },
    pois: {
      type: 'pois',
      ids: [
        '5a6bc4a9-21be-4110-aa2e-70edafde8371',
        '11df972e-2e3f-4233-8af9-9203b34be8ec',
        'e5295b4a-41e9-4f4a-948f-4805699c5cf7',
        'bca835cb-a88e-461e-8b71-04dbd4938c89',
        '8d6599db-dd94-4544-a36c-8d9e68ac6954',
        'e190b91c-8843-4296-a8b1-8b819c80038e',
        '57dcd420-cbc8-408c-a455-d4081bd5b87d',
        '6c693d68-01d8-4acc-9867-8dbba2d04d94',
        '87d04688-fd5c-4ca7-a25c-5c6002228453',
        '62091d04-8a16-4d23-b152-ac45fbbc2e63',
        '5e306f9c-b4d8-4e47-9ff6-66dc63fd5d56',
        'f263d457-444f-48da-83f9-eed411146ecd',
        '4ff91bf2-0f19-48f2-bd5e-75d4678b2176',
        '2658ac2b-1273-4632-9e40-04e53f87cf88',
        'ed40fd39-1a2b-418e-89b2-1b21df5d3368',
        '532597fd-c0bd-40b3-8e2b-947630bbe3ce',
        '20b2704c-f40c-4d70-8e31-a728df241501',
      ],
    },
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    type: 'floors',
  },
  {
    id: '213d352e-abd0-499d-85ac-16f9aaf0a741',
    version: 'f840fb92-d2ec-446d-b6b4-fe87944cecba',
    tags: {},
    configs: {
      'room-control-radius': '10',
      externalId: '200974',
      floorplan: 'e798a60d-443e-49ec-ac44-11e578a7d85e',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Etage 4',
    active: true,
    number: '4',
    northEastCorner: {
      lat: 53.54592349550206,
      long: 10.008976907759433,
    },
    southWestCorner: {
      lat: 53.5448799612787,
      long: 10.006934423876233,
    },
    pois: {
      type: 'pois',
      ids: [
        'b41280c8-194b-41f1-82e3-31ef7e6f72c0',
        'd1409ea3-0303-4011-825d-d573d1cf0815',
        '821e64e4-a7c3-402a-96c0-ae5b1c8c00c2',
        '0a8da651-4563-469c-840f-2fcb913fad90',
        '0335946c-784a-48fe-96b5-06e214ad7d5e',
        '6344992c-b455-4305-8757-d37c98e170d9',
        '1e98fddb-6250-49c7-8201-1f3a8faf230c',
        'c9a97b81-1e15-486c-9918-bafc9f5bc660',
        '83e6cf4c-d5d5-4441-9bb0-00276357eff1',
        '85def8f2-679a-45f9-9911-91372b8732bd',
        'ac049029-7f2a-4958-bc7a-6ad1352b7148',
        '5b1026f8-a81b-4342-81fb-daf23078835b',
        'd395946f-370b-4aa4-b54a-dc828669340a',
        'b14c4453-e5fd-4ce0-9d14-5df67accbdd2',
      ],
    },
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    type: 'floors',
  },
  {
    id: 'de907753-e052-49d7-a962-c287630367fd',
    version: 'ce16c8eb-c988-4571-badf-63c45d97797a',
    tags: {},
    configs: {
      'room-control-radius': '10',
      externalId: '200973',
      floorplan: '03101ffd-27f8-4d74-92b3-e064662e9479',
    },
    files: {
      type: 'files',
      entities: [],
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    name: 'Etage 3',
    active: true,
    number: '3',
    northEastCorner: {
      lat: 53.54591545749205,
      long: 10.008971515829005,
    },
    southWestCorner: {
      lat: 53.544881996152284,
      long: 10.006945530976033,
    },
    pois: {
      type: 'pois',
      ids: [
        'f0a11c45-16a1-4b21-b854-2a3460f439d0',
        'b857902a-9f6a-4414-b03b-7bd7118f4f02',
        '8aabac39-9dd3-4ee6-9870-3abff2ee19a5',
        'bee02ecb-fa87-45de-a02e-9df13b3a6e12',
        'ef51ec3f-c608-49e7-8543-7a053fa19243',
        '49263f4f-6ce3-41f6-9c32-15e470caa246',
        'e1757257-feec-40aa-bcb9-73f8a6efab8c',
        'cba5c05a-37af-4bfd-92df-43da68292a07',
        'c3969a1e-8af6-4bd1-90c6-38012a28cd6a',
        'a68aa145-3f45-4e8f-8ae7-3d5dcbc2ad97',
        'f0656377-7a5f-4bcd-b6fd-0c8092036014',
        '1aec822c-9da3-4ae8-8ec3-40b435250d37',
        'f57539bf-5038-474c-bff5-a574d8ea20ba',
        '875d660d-7d76-4b47-9117-c057c023e274',
        '31c6dce9-d111-486a-ace9-fe7eebe2b176',
        '92d2a842-d0fc-45b1-b49b-9134c44c3749',
        'a5664723-8330-487b-931d-70d176867c5e',
        'a0789dce-287f-420a-ba7e-981e8f3efea1',
      ],
    },
    building: {
      type: 'buildings',
      id: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
    },
    type: 'floors',
  },
]

export const getFloorById = id => {
  let floor = floors.find(floor => floor.id === id)
  if (floor) return floor
  else return null
}
