import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import { loadConfig } from '@/configLoader'
import { Preferences } from '@capacitor/preferences'
import { PushNotifications } from '@capacitor/push-notifications'
import { CapacitorCookies } from '@capacitor/core'

import * as idb from 'idb'

/**
 * Decodes a JWT token to retrieve its payload.
 * @param {string} jwtToken - The JWT token to be decoded.
 * @returns {Object|null} - The decoded payload or null if the token is invalid.
 */
/**
 * Set a cookie with CapacitorCookies for mobile and document.cookie for web.
 * @param {string} key - The cookie name.
 * @param {string} value - The cookie value.
 * @param {number} days - Days until the cookie expires.
 */

/**
 * Get the current domain (host) for both mobile and web.
 * @returns {string} - The current domain.
 */

function getDomain() {
  return window.location.origin // This will return the full domain, e.g., 'http://example.com'
}
async function setCookie(key, value, days) {
  const isWeb = await isWebPlatform()
  const expires = days ? `; expires=${new Date(Date.now() + days * 86400000).toUTCString()}` : ''
  const domain = getDomain()

  if (isWeb) {
    // Web: Use document.cookie
    document.cookie = `${key}=${value || ''}${expires}; path=/`
  } else {
    // Mobile: Use CapacitorCookies
    await CapacitorCookies.setCookie({
      url: domain, // Dynamically use the current domain
      key: key,
      value: value,
      expires: new Date(Date.now() + days * 86400000).toUTCString(), // Setting expiration as UTC string
    })
  }
}
/**
 * Get a cookie value by key for both web and mobile platforms.
 * @param {string} key - The cookie name.
 * @returns {Promise<string|null>} - The cookie value or null if not found.
 */
async function getCookie(key) {
  const isWeb = await isWebPlatform()
  const domain = getDomain()

  if (isWeb) {
    // Web: Use document.cookie
    const nameEQ = `${key}=`
    const cookies = document.cookie.split(';')
    for (let cookie of cookies) {
      cookie = cookie.trim()
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length)
    }
    return null
  } else {
    // Mobile: Use CapacitorCookies
    const cookies = await CapacitorCookies.getCookies({ url: domain })
    return cookies[key] || null
  }
}

/**
 * Delete a cookie for both web and mobile platforms.
 * @param {string} key - The cookie name.
 */
async function deleteCookie(key) {
  const isWeb = await isWebPlatform()
  const domain = getDomain()

  if (isWeb) {
    // Web: Delete the cookie by setting its expiration date to a past date
    document.cookie = `${key}=; Max-Age=-99999999; path=/`
  } else {
    // Mobile: Use CapacitorCookies to delete the cookie
    await CapacitorCookies.deleteCookie({
      url: domain,
      key: key,
    })
  }
}

/**
 * Clear all cookies for the mobile platform or web platform.
 */
async function clearAllCookies() {
  const isWeb = await isWebPlatform()
  const domain = getDomain()

  if (isWeb) {
    // Web: Clear all cookies
    document.cookie.split(';').forEach(cookie => {
      document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
  } else {
    // Mobile: Use CapacitorCookies to clear all cookies
    await CapacitorCookies.clearCookies({ url: domain })
  }
}

function parseJwt(jwtToken) {
  if (!jwtToken) {
    console.error('Token is not valid.')
    return null
  }

  try {
    const base64Url = jwtToken.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (e) {
    console.error('Error parsing JWT:', e)
    return null
  }
}

/**
 * Checks if a decoded JWT token has expired.
 * @param {Object|null} decodedToken - The decoded JWT token.
 * @returns {boolean} - True if the token has expired, false otherwise.
 */
function isTokenExpired(decodedToken) {
  if (!decodedToken || !decodedToken.exp) {
    return true
  }
  const now = new Date().getTime() / 1000 // Current time in seconds since epoch
  return decodedToken.exp < now
}

const getAppPackageName = async () => {
  const { platform } = await Device.getInfo()
  if (platform === 'web') {
    const config = await loadConfig()
    return config?.clientId
  } else {
    const { id } = await App.getInfo()
    return id
  }
}

async function isWebPlatform() {
  const { platform } = await Device.getInfo()
  return platform === 'web'
}

async function getStoredItem(key) {
  if (await isWebPlatform()) {
    const item = sessionStorage.getItem(key)
    return item ? JSON.parse(item) : null
  } else {
    const { value } = await Preferences.get({ key })
    return value ? JSON.parse(value) : null
  }
}

async function setStoredItem(key, value) {
  if (await isWebPlatform()) {
    sessionStorage.setItem(key, JSON.stringify(value))
  } else {
    await Preferences.set({ key, value: JSON.stringify(value) })
  }
}

async function removeAllStoredItems() {
  if (await isWebPlatform()) {
    sessionStorage.clear()
    localStorage.clear()
  } else {
    await Preferences.clear()
  }
}

async function removeStoredItem(key) {
  if (await isWebPlatform()) {
    sessionStorage.removeItem(key)
  } else {
    await Preferences.remove({ key })
  }
}

// Increment the version number if you need to force an upgrade
const dbPromise = idb.openDB('myAppDatabase', 2, {
  // Change version to 2 or higher if needed
  upgrade(db) {
    // Create 'reservations' store if it doesn't exist
    if (!db.objectStoreNames.contains('reservations')) {
      db.createObjectStore('reservations', { keyPath: 'id' })
    }
    // Create 'appointments' store if it doesn't exist
    if (!db.objectStoreNames.contains('appointments')) {
      db.createObjectStore('appointments', { keyPath: 'id' })
    }
    // Create 'blobs' store if it doesn't exist
    if (!db.objectStoreNames.contains('blobs')) {
      db.createObjectStore('blobs')
    }
  },
})
async function storeBlob(key, blob) {
  const db = await dbPromise
  const tx = db.transaction('blobs', 'readwrite')
  tx.objectStore('blobs').put(blob, key)
  await tx.complete
}

async function retrieveBlob(key) {
  const db = await dbPromise
  return db.transaction('blobs').objectStore('blobs').get(key)
}

async function requestNotificationPermission() {
  const { platform } = await Device.getInfo()

  // Handle Web Notifications
  if (platform === 'web') {
    if (Notification.permission === 'granted') {
      console.log('Notifications are already granted.')
      return 'granted'
    } else if (Notification.permission === 'denied') {
      console.log('Notifications are denied by the user.')
      return 'denied'
    } else if (Notification.permission === 'default') {
      // Request permission if it hasn't been granted or denied
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        console.log('Web notifications permission granted.')
      } else {
        console.log('Web notifications permission denied.')
      }
      return permission
    }
  }

  // Handle Mobile (Android/iOS) Notifications with Capacitor
  else {
    // Check the current permissions
    const currentStatus = await PushNotifications.checkPermissions()
    if (currentStatus.receive === 'granted') {
      console.log('Push notifications are already enabled on mobile.')
      return 'granted'
    } else {
      // Request permission
      const result = await PushNotifications.requestPermissions()
      if (result.receive === 'granted') {
        console.log('Push notifications permission granted on mobile.')
        await PushNotifications.register() // Register for push notifications
        return 'granted'
      } else {
        console.log('Push notifications permission denied on mobile.')
        return 'denied'
      }
    }
  }
}
export {
  getAppPackageName,
  parseJwt,
  isTokenExpired,
  isWebPlatform,
  getStoredItem,
  setStoredItem,
  removeStoredItem,
  removeAllStoredItems,
  storeBlob,
  retrieveBlob,
  dbPromise,
  requestNotificationPermission,
  setCookie,
  getCookie,
  deleteCookie,
  clearAllCookies,
}
