<template>
  <div class="locks-view">
    <v-toolbar class="custom-toolbar" prominent elevation="5">
      <img :src="logo" class="toolbar-icon" alt="Logo" />
      <v-toolbar-title class="custom-toolbar-title">Access Control</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :color="primaryColor" text :disabled="showLoader || !isBluetoothEnabled || !isBleClientInitialized || !isMobile" @click="resync">
        Re-Sync
      </v-btn>
    </v-toolbar>

    <v-container v-if="!isMobile">
      <v-card class="mx-auto beautiful-card" dark>
        <v-card-title class="beautiful-title">This Feature is only available for mobile devices.</v-card-title>
      </v-card>
    </v-container>

    <v-container v-else-if="!isBluetoothEnabled">
      <v-card class="mx-auto beautiful-card" dark elevation="5">
        <v-card-title class="beautiful-title">Bluetooth is not enabled</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="enableBluetooth">Enable Bluetooth</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-container v-else-if="!isBleClientInitialized">
      <v-card class="mx-auto beautiful-card" dark elevation="5">
        <v-card-title class="beautiful-title">BLE Client not started</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="initializeBleClient">Initialize Bluetooth Client</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <div v-else>
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-chip size="large" color="black" variant="outlined" dense>
            <v-icon left>mdi-format-list-bulleted</v-icon>
            Locks: {{ locks.length }}
          </v-chip>

          <v-chip size="large" color="black" variant="outlined" dense>
            {{ syncStatus }}
            <v-icon right class="ml-2" :color="syncColor">mdi-checkbox-blank-circle</v-icon>
          </v-chip>
        </div>
      </v-container>
      <div v-if="showLoader" class="loader-container">
        <div class="loader"></div>
      </div>

      <v-container v-if="!showLoader">
        <v-text-field v-model="search" label="Search locks" single-line hide-details class="search-bar"></v-text-field>

        <v-row>
          <v-col cols="12" v-for="lock in filteredLocks" :key="lock.id">
            <div class="lock-item">
              <div class="lock-details">
                <div class="lock-name">{{ lock?.configs?.name }}</div>
                <div class="lock-floor">{{ lock.floor?.name }}</div>
              </div>
              <v-btn icon class="unlock-button" @click="unlock(lock)">
                <v-icon>mdi-key</v-icon>
              </v-btn>
            </div>
            <v-divider :key="`divider-${lock.id}`"></v-divider>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="showLoader" class="overlay"></div>

    <v-snackbar v-model="snackbar" :timeout="3000" location="bottom" :offset-y="true">
      {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
  import { ref, computed, defineComponent, onMounted, watch, onBeforeMount } from 'vue'
  import { Capacitor } from '@capacitor/core'
  import { Device } from '@capacitor/device'
  import { BleClient } from '@capacitor-community/bluetooth-le'
  import lodash from 'lodash'
  import { Preferences } from '@capacitor/preferences'
  import { BlueId } from '@/plugins/android/blueIdHelperPlugin' // Adjust the import path as necessary

  import { loadConfig } from '@/configLoader'
  import { getBottomBarColor } from '@/assets/Branding/branding.js'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'

  import { getLocks, getHardwareByType, unregisterDeviceAccessControl, syncAccessControlDeviceId } from '@/controllers/BackboneAPI'
  import { getFloors, getBuildings } from '@/controllers/BaseController'
  import Swal from 'sweetalert2'
  import moment from 'moment'

  export default defineComponent({
    name: 'LocksView',
    setup() {
      const isMobile = Capacitor.isNativePlatform()
      const search = ref('')
      const logo = ref('')
      const locks = ref([])
      const primaryColor = ref('')
      const showLoader = ref(true)
      const syncStatus = ref('Not Synced')
      const syncColor = ref('red')
      const isBluetoothEnabled = ref(true)
      const isBleClientInitialized = ref(true)
      const deviceIdKey = 'deviceId'
      const secureObjectsKey = 'secureObjects'
      const snackbar = ref(false)
      const snackbarMessage = ref('')

      const filteredLocks = computed(() => {
        if (!search.value) {
          return locks.value
        }
        return locks.value.filter(lock => lock?.configs?.name.toLowerCase().includes(search.value.toLowerCase()))
      })

      const showSnackbar = message => {
        snackbarMessage.value = message
        snackbar.value = true
      }

      const checkBluetoothStatus = async () => {
        try {
          await BleClient.initialize()
          const enabled = await BleClient.isEnabled()
          isBluetoothEnabled.value = enabled
        } catch (error) {
          console.error('Error checking Bluetooth status:', error)
          BleClient.openAppSettings()
        }
      }

      const enableBluetooth = async () => {
        try {
          await BleClient.initialize()
          await BleClient.requestEnable()
          isBluetoothEnabled.value = true
        } catch (error) {
          console.error('Error enabling Bluetooth:', error)
          BleClient.openAppSettings()
        }
      }

      const initializeBleClient = async () => {
        try {
          await BleClient.initialize()
          isBleClientInitialized.value = true
        } catch (error) {
          console.error('Error initializing BLE Client:', error)
          BleClient.openAppSettings()
        }
      }

      const isWebPlatform = async () => {
        const { platform } = await Device.getInfo()
        return platform === 'web'
      }

      const storeData = async (key, data) => {
        const stringifiedData = JSON.stringify(data)
        if (await isWebPlatform()) {
          localStorage.setItem(key, stringifiedData)
        } else {
          await Preferences.set({ key, value: stringifiedData })
        }
      }

      const retrieveData = async key => {
        if (await isWebPlatform()) {
          const data = localStorage.getItem(key)
          return data ? JSON.parse(data) : null
        } else {
          const { value } = await Preferences.get({ key })
          return value ? JSON.parse(value) : null
        }
      }

      const removeData = async key => {
        if (await isWebPlatform()) {
          localStorage.removeItem(key)
        } else {
          await Preferences.remove({ key })
        }
      }

      const unlock = async lock => {
        try {
          showLoader.value = true
          let blueIdLock = JSON.parse(JSON.stringify(lock))
          let blueIdObjId = blueIdLock?.blueIdObjectId
          let action = 'tokn'
          let result = await BlueId.runCommand({ id: blueIdObjId, action: action })
          if (result !== undefined) {
            showLoader.value = false
            showSnackbar('Lock opened successfully')
          } else {
            showLoader.value = false
            showSnackbar('Failed to unlock')
          }
        } catch (error) {
          console.error('Failed to unlock:', error)
          showLoader.value = false
          showSnackbar('Failed to unlock')
        }
      }

      const loadLocks = async () => {
        try {
          const buildings = await getBuildings()
          const selectedBuilding = buildings[0]?.name || null

          const selectedBuildingObj = buildings.find(building => building.name === selectedBuilding)

          const allLocks = await getHardwareByType(selectedBuildingObj['id'], 'lock')
          const allFloors = await getFloors()

          const floorsMap = new Map(allFloors.map(floor => [floor.id, floor]))

          const filteredLocks = []
          const unSyncedLocks = []

          allLocks.forEach(lock => {
            const blueIdObjectId = lock?.configs?.['blue-id-object-id']
            const floor = floorsMap.get(lock?.location?.floorId)
            filteredLocks.push({
              ...lock,
              floor: floor,
              blueIdObjectId: blueIdObjectId,
            })
          })

          locks.value = lodash.orderBy(
            filteredLocks,
            [
              lock => {
                const name = lodash.get(lock, 'configs.name', '')
                const isNumeric = /^\d/.test(name)
                return (isNumeric ? '1' : '2') + name
              },
            ],
            ['asc']
          )
        } catch (error) {
          console.error('Failed to load locks or floors:', error)
        }
      }

      const resync = async () => {
        showLoader.value = true
        syncStatus.value = 'Resynchronizing...'
        syncColor.value = 'red'
        try {
          await BlueId.destroy()
          await BlueId.synchronize()
          syncStatus.value = 'Synced'
          syncColor.value = 'green'
          showSnackbar('Resynchronized successfully')
        } catch (error) {
          console.error('Failed to resynchronize:', error)
          syncStatus.value = 'Failed to Resynchronize'
          syncColor.value = 'red'
          showSnackbar('Failed to resynchronize')
        } finally {
          showLoader.value = false
        }
      }

      onBeforeMount(async () => {
        await BleClient.initialize()
        isBleClientInitialized.value = true
        let bluetoothStatus = await BleClient.isEnabled()
        isBluetoothEnabled.value = bluetoothStatus
      })

      onMounted(async () => {
        if (!isMobile) {
          showLoader.value = false
          logo.value = await getToolbarLogoImage()
          return
        } else {
          let { isInitialized } = await BlueId.isInitialized()

          if (isInitialized === false) {
            console.log('Initializing BlueId')
            const config = await loadConfig()
            let apiKey = config.accessControl.apiKey
            let { deviceId } = await BlueId.initialize({ apiKey })
            await syncAccessControlDeviceId(deviceId)
            await storeData(deviceIdKey, deviceId)
            await BlueId.synchronize()
            await loadLocks()
            showLoader.value = false
            syncStatus.value = 'Synced'
            syncColor.value = 'green'
          } else {
            let { lastSyncDate } = await BlueId.getLastSynchronizationDate()
            let diff = moment().diff(moment(lastSyncDate), 'days')
            if (diff >= 4) {
              await BlueId.synchronize()
              await loadLocks()
              showLoader.value = false
              syncStatus.value = 'Synced'
              syncColor.value = 'green'
            } else {
              await loadLocks()
              showLoader.value = false
              syncStatus.value = 'Synced'
              syncColor.value = 'green'
            }
          }
        }
      })

      return {
        locks,
        resync,
        showLoader,
        unlock,
        search,
        filteredLocks,
        primaryColor,
        syncStatus,
        syncColor,
        isMobile,
        isBluetoothEnabled,
        enableBluetooth,
        isBleClientInitialized,
        initializeBleClient,
        snackbar,
        snackbarMessage,
        logo,
      }
    },
  })
</script>

<style scoped>
  .locks-view {
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }
  .custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9); /* Slight transparency for elegance */
    backdrop-filter: blur(10px); /* Blur background content */
    color: #333333; /* Dark text color for contrast */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
    border-radius: 8px; /* Rounded corners */
    padding: 8px 16px; /* Adjusted padding for spacing */
  }

  .custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333; /* Matching text color */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px); /* Adjust based on available space */
  }

  .search-bar {
    margin-bottom: 1rem;
    /* Spacing between search bar and list */
    transition: box-shadow 0.3s ease-in-out;
  }

  .search-bar:focus-within {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    /* Shadow effect on focus */
  }

  .lock-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    /* Reduced padding for less height */
    transition: background-color 0.3s ease;
  }

  .lock-details {
    overflow: hidden;
    /* Prevents text overflow */
    white-space: nowrap;
    /* Keeps the text in a single line */
    text-overflow: ellipsis;
    /* Adds ellipsis for overflowed text */
  }

  .lock-name {
    font-weight: bold;
    margin-right: 8px;
    /* Spacing between lock name and floor name */
  }

  .lock-floor {
    font-size: 0.75rem;
    /* Smaller font size for subtlety */
    color: #666;
    /* Lighter color for floor name */
  }

  .unlock-button {
    margin-left: auto;
    /* Pushes the button to the far right */
  }

  .unlock-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  .beautiful-card {
    background-color: var(--v-card-color);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    text-align: center;
  }

  .beautiful-title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    color: black;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 3px dotted #fff;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }
  .loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted #ff3d00;
    border-style: solid solid dotted;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
</style>
