<template>
  <v-container>
  </v-container>
</template>

<script>
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
  },
  setup() {
    onMounted(async () => {
      
    });
  },
});
</script>
