<template>

	<div class="unauthorized">

		<div class="grid-container">

			<div class="grid-x grid-padding-x">
				<div class="cell medium-6">

					<img src="/static/assets/img/pinestack_logo_dark.png" class="logo" alt="Pinestack Logo">

					<h1 class="headline default-margin">
						{{ $t('auth.unknown.headline') }}
					</h1>

					<div class="text larger default-margin">
						<div>
							{{ $t('auth.unknown.explain') }}
						</div>

						<div class="bold">
							{{ $t('auth.unknown.consult-admin') }}
						</div>
					</div>

					<div class="back">
						<router-link to="/" class="button white outline">{{ $t('auth.unknown.back-to-login') }}</router-link>
					</div>

					<div class="text causes">
						<div class="bold small-margin">
							{{ $t('auth.unknown.causes') }}
						</div>
						<ul>
							<li>
								{{ $t('auth.unknown.cause1') }}
							</li>
							<li>
								{{ $t('auth.unknown.cause2') }}
							</li>
						</ul>
					</div>

					<div class="text help margin-after">
						<div class="gray">
							{{ $t('auth.unknown.need-help') }}
						</div>
						<div>
							<a :href="'mailto:' + $t('auth.unknown.support-email')">{{ $t('auth.unknown.support-email') }}</a>
						</div>
					</div>
				</div>

				<div class="cell medium-6 hide-for-small-only">
					<img src="/static/assets/img/login_unauth.png" class="image" alt="unauthorized indicator image">
				</div>
			</div>

		</div>

	</div>

</template>

<script>
	export default {
		name: 'unauthorized',
		data() {
			return {};
		},
		created() {
		}
	};

</script>

<style lang="scss">

	.unauthorized {
		width: 100%;
		height: 100%;
		overflow: auto;

		.logo {
			margin-top: 50px;
			margin-bottom: 70px;
			width: 200px;
		}
		.headline {
			font-size: 34px;
			letter-spacing: -0.5px;
			line-height: 41px;
		}
		.bold {
			font-weight: 700;
		}
		.back {
			margin-bottom: 50px;
		}
		.text {
			font-size: 15px;
			line-height: 22px;
			.larger {
				font-size: 17px;
				line-height: 25px;
			}
		}
		.default-margin {
			margin-bottom: 20px;
		}
		.small-margin {
			margin-bottom: 10px;
		}
		.no-margin {
			margin: 0;
		}
		.causes {
			margin-bottom: 40px;
		}
		.margin-after {
			margin-bottom: 120px;
		}
		.image {
			margin-top: 120px;
			width: 100%;
		}
		
	}

</style>
