// src/dummydata/motivationData.js
export const motivationItems = [
  {
    id: 1,
    title: 'Community and Collaboration',
    description: 'Emphasize that personal interaction improves team dynamics and collaboration.',
    image: require('@/assets/General/community.jpg'),
    details: [
      'Foster Team Spirit: Engage in activities that build trust and improve team dynamics.',
      'Improve Communication: Encourage open discussions to facilitate better understanding and cooperation.',
      'Collaborative Tools: Use the latest tools and technologies to enhance team collaboration.',
      // 'Success Stories: Share success stories where teamwork made a difference.',
      // 'Team Workshops: Organize workshops and seminars to strengthen team bonds.'
    ],
    images: [
      require('@/assets/General/community.jpg'),
      require('@/assets/General/community.jpg'),
      require('@/assets/General/community.jpg'),
    ],
  },
  // Add similar structures for other items
  {
    id: 2,
    title: 'Career Development',
    description: 'Show that being present in the office offers better opportunities for networking and career advancement.',
    image: require('@/assets/General/career_development.jpg'),
    details: [
      'Networking Opportunities: Interact with peers and mentors to enhance career growth.',
      'Skill Development: Participate in workshops and training sessions available on-site.',
      'Visibility: Be more visible to management for career advancement opportunities.',
      'Mentorship Programs: Access to in-office mentorship and career guidance.',
      'Collaborative Projects: Engage in projects that require team efforts and learning.'
    ],
    images: [
      require('@/assets/General/career_development.jpg'),
      require('@/assets/General/career_development.jpg'),
      require('@/assets/General/career_development.jpg'),
    ],
  },
  {
    id: 3,
    title: 'Corporate Identity',
    description: 'Explain the importance of physical presence for company culture and identification.',
    image: require('@/assets/General/corporate_identity.jpg'),
  },
  {
    id: 4,
    title: 'Hybrid Models',
    description: 'Offer a mix of working from home and in the office to ease the transition.',
    image: require('@/assets/General/hybrid.jpg'),
  },
  {
    id: 5,
    title: 'Flexible Working Hours',
    description: 'Allow flexible working hours to reduce commute times and give employees more control over their daily schedules.',
    image: require('@/assets/General/flexible_hours.jpg'),
  },
  {
    id: 6,
    title: 'Attractive Office Design',
    description: 'Create a pleasant and inspiring work environment with modern facilities and relaxation areas.',
    image: require('@/assets/General/office_env.jpg'),
  },
  {
    id: 7,
    title: 'Technological Equipment',
    description: 'Ensure that the best technical resources are available in the office.',
    image: require('@/assets/General/equipment.jpg'),
  },
  {
    id: 8,
    title: 'Additional Benefits',
    description: 'Provide perks such as free meals, fitness programs, or paid parking.',
    image: require('@/assets/General/benefits.jpg'),
  },
  {
    id: 9,
    title: 'Team-building Events',
    description: 'Organize regular social activities and team events to strengthen cohesion.',
    image: require('@/assets/General/team.jpg'),
  },
  {
    id: 10,
    title: 'Shared Lunch Breaks',
    description: 'Encourage shared breaks or lunches to facilitate social interaction.',
    image: require('@/assets/General/lunch.jpg'),
  },

  {
    id: 11,
    title: 'Health Programs',
    description: 'Offer health and wellness programs to promote employee well-being.',
    image: require('@/assets/General/wellness.jpg'),
  },
]
