import moment from 'moment'

const generateOfficeDaysData = () => {
  const data = []
  const startDate = moment().startOf('year')
  const endDate = moment().startOf('day')

  let vacationStartDate = null
  let vacationDuration = 0

  for (let date = startDate; date.isBefore(endDate); date.add(1, 'days')) {
    const dayOfWeek = date.day()
    if (dayOfWeek === 0 || dayOfWeek === 6) continue

    if (vacationStartDate && date.diff(vacationStartDate, 'days') < vacationDuration) {
      data.push({
        date: date.toISOString(),
        status: 'Vacation',
        hours: 0,
      })
      continue
    }

    const status = Math.random() > 0.85 ? 'Vacation' : Math.random() > 0.75 ? 'Sick' : 'Office'
    if (status === 'Vacation') {
      vacationStartDate = date.clone()
      vacationDuration = Math.floor(Math.random() * 5) + 1
    } else {
      vacationStartDate = null
      vacationDuration = 0
    }

    const hours = status === 'Office' ? Math.floor(Math.random() * 4) + 5 : 0

    data.push({
      date: date.toISOString(),
      status,
      hours,
    })
  }

  return data
}

export const officeDaysData = generateOfficeDaysData()
