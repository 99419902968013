// store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    isAccessControlLoading: false,
    isAccessControlSynced: false,
  },
  mutations: {
    setIsAccessControlLoading(state, payload) {
      state.isAccessControlLoading = payload;
    },
    setIsAccessControlSynced(state, payload) {
      state.isAccessControlSynced = payload;
    },
  },
});
