export const lightsTest = [
  {
    id: 'c96a60d6-edea-47ac-8182-6f65a94d8560',
    version: 'afa6ad91-6ac5-438c-baf8-26b8bfb193ff',
    tags: {},
    configs: {
      datapoint: 'ISP_Level_02-102010_Raum_06_Licht-MSV16',
      'hardware-key': 'H3RWDpkE',
      'poi-id': '87d04688-fd5c-4ca7-a25c-5c6002228453',
      'route-key': 'aedifion-connector',
      name: '2.06',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_02-102010_Raum_06_Licht-MSV16',
    actions: [
      {
        id: '411cc9ae-56b2-43fe-b26d-e8baa56c4347',
        version: 'ddf25bd3-fc51-4146-adc1-36cf402821a0',
        tags: {},
        configs: {},
        actionType: 'brightness',
        spec: {
          type: 'range',
          def: [
            {
              min: 0,
              max: 100,
              steps: 1,
            },
          ],
        },
        hardware: {
          type: 'hardwares',
          id: 'c96a60d6-edea-47ac-8182-6f65a94d8560',
        },
        type: 'actions',
      },
      {
        id: '12715e8b-2903-4bc0-88d0-43d322d80988',
        version: '29d1d1d7-623d-4906-be46-b0d6c76cfa0c',
        tags: {},
        configs: {},
        actionType: 'temperature',
        spec: {
          type: 'range',
          def: [
            {
              min: 0,
              max: 100,
              steps: 1,
            },
          ],
        },
        hardware: {
          type: 'hardwares',
          id: 'c96a60d6-edea-47ac-8182-6f65a94d8560',
        },
        type: 'actions',
      },
      {
        id: 'f1f1b558-5c76-400e-be36-5a1c0a1e8f31',
        version: 'd44c3135-73ba-4209-8ba1-32cd416f59b8',
        tags: {},
        configs: {},
        actionType: 'scene',
        spec: {
          type: 'localized_discrete',
          def: {
            '20%': {
              value: '20%',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '40%': {
              value: '40%',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            automatic: {
              value: '',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '60%': {
              value: '60%',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '80%': {
              value: '80%',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            Off: {
              value: 'Aus',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            On: {
              value: 'Ein',
              hwValue: null,
              definitionConfigs: {},
              default: true,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: 'c96a60d6-edea-47ac-8182-6f65a94d8560',
        },
        type: 'actions',
      },
      {
        id: 'd98f3854-dcac-44e3-a822-de46b0437acd',
        version: '92aa4d0f-bde8-474a-ab68-a525de3f975a',
        tags: {},
        configs: {},
        actionType: 'switch',
        spec: {
          type: 'on_off',
          def: {
            on: 'true',
            off: 'false',
          },
        },
        hardware: {
          type: 'hardwares',
          id: 'c96a60d6-edea-47ac-8182-6f65a94d8560',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'LIGHT',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
      position: {
        lat: 53.54512927996291,
        long: 10.008724950361477,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [
      {
        id: 'b6c4cbeb-8630-4476-802f-1116083b6140',
        version: 'a4cb9d39-551a-4058-ae25-c4973821fe48',
        tags: {},
        configs: {},
        measurementType: 'light',
        type: 'measurements',
      },
    ],
    type: 'hardwares',
  },
  {
    id: '960ea5dd-9957-4de4-9a84-86b66b33ed41',
    version: '77fce1c9-644f-4383-bfb6-102be3de682c',
    tags: {},
    configs: {
      datapoint: 'ISP_Level_03-103010_Raum_02_b_Licht-MSV17',
      'hardware-key': 'JO4uMbT2',
      'poi-id': '875d660d-7d76-4b47-9117-c057c023e274',
      'route-key': 'aedifion-connector',
      name: '3.02b Cloud',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_03-103010_Raum_02_b_Licht-MSV17',
    actions: [
      {
        id: '07872cb4-d261-443a-af67-c664924cf61d',
        version: 'f282a04b-8fa7-45cb-bffd-c3c635a4437c',
        tags: {},
        configs: {},
        actionType: 'scene',
        spec: {
          type: 'localized_discrete',
          def: {
            Auto: {
              value: '1',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '100%': {
              value: '7',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '20%': {
              value: '3',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '40%': {
              value: '4',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '60%': {
              value: '5',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            '80%': {
              value: '6',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            Off: {
              value: '2',
              hwValue: null,
              definitionConfigs: {},
              default: true,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: '960ea5dd-9957-4de4-9a84-86b66b33ed41',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'LIGHT',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: 'de907753-e052-49d7-a962-c287630367fd',
      position: {
        lat: 53.54527441568956,
        long: 10.008190534482289,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [
      {
        id: '2347c1f6-d475-4390-80ce-ceaaa94822ac',
        version: '7f65483c-753d-4a49-a159-617c492fee4b',
        tags: {},
        configs: {},
        measurementType: 'light',
        type: 'measurements',
      },
    ],
    type: 'hardwares',
  },
]

export const heatersTest = [
  {
    id: 'daaf04e5-18cb-4b00-8e8b-84eeadde721e',
    version: 'a99238cc-6f43-4f4d-98f7-c85e68b036c2',
    tags: {},
    configs: {
      datapoint: 'ISP_Level_03-103010_Raum_06_Temp_Sollwertverstellung-AV18',
      'hardware-key': 'NnQhIR04',
      'poi-id': '1aec822c-9da3-4ae8-8ec3-40b435250d37',
      'route-key': 'aedifion-connector',
      name: '3.06',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_03-103010_Raum_06_Temp_Sollwertverstellung-AV18',
    actions: [
      {
        id: '6b0d7bee-83f6-4732-96e6-8e630b0fe95a',
        version: '5662dd08-8ef4-4e98-b0d2-eb44aaec21b2',
        tags: {},
        configs: {},
        actionType: 'temperature',
        spec: {
          type: 'range',
          def: [
            {
              min: -30,
              max: 30,
              steps: 5,
            },
          ],
        },
        hardware: {
          type: 'hardwares',
          id: 'daaf04e5-18cb-4b00-8e8b-84eeadde721e',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'HEATER',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: 'de907753-e052-49d7-a962-c287630367fd',
      position: {
        lat: 53.54540191428231,
        long: 10.008078552255917,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [],
    type: 'hardwares',
  },
  {
    id: 'dd58ab81-9cef-40f1-9836-dd484e176c21',
    version: '7dccd1d0-6ba8-4667-afbb-756d4af86b8b',
    tags: {},
    configs: {
      datapoint: 'ISP_Level_04-104010_Raum_13_Temp_Sollwertverstellung-AV44',
      'hardware-key': 'Pbk4ZJUP',
      'poi-id': '821e64e4-a7c3-402a-96c0-ae5b1c8c00c2',
      'route-key': 'aedifion-connector',
      name: '4.13 Lab',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_04-104010_Raum_13_Temp_Sollwertverstellung-AV44',
    actions: [
      {
        id: 'ca7e8791-d0df-4e2b-8d15-3cbea3e3d7b2',
        version: 'c083c176-8337-44cc-950c-2b4c54822d34',
        tags: {},
        configs: {},
        actionType: 'temperature',
        spec: {
          type: 'range',
          def: [
            {
              min: -30,
              max: 30,
              steps: 5,
            },
          ],
        },
        hardware: {
          type: 'hardwares',
          id: 'dd58ab81-9cef-40f1-9836-dd484e176c21',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'HEATER',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: '213d352e-abd0-499d-85ac-16f9aaf0a741',
      position: {
        lat: 53.545285206987685,
        long: 10.008359423841785,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [],
    type: 'hardwares',
  },
]

export const ventilationTest = [
  {
    id: 'e9f27f3d-a2e8-48ca-aebe-589a62383065',
    version: '88fb1977-e77a-4b9b-89da-ed8aef708055',
    tags: {},
    configs: {
      datapoint: 'ISP_Level_02-102010_Raum_03_Lueftung-MSV3',
      'hardware-key': 'VI9c5ls6',
      'poi-id': '5e306f9c-b4d8-4e47-9ff6-66dc63fd5d56',
      'route-key': 'aedifion-connector',
      name: '2.03',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_02-102010_Raum_03_Lueftung-MSV3',
    actions: [
      {
        id: '6c458834-f2f2-4dd4-8373-9cbb7f49cf36',
        version: '067272b4-81b5-402e-bc9d-6eb572967d21',
        tags: {},
        configs: {},
        actionType: 'motor',
        spec: {
          type: 'localized_discrete',
          def: {
            automatic: {
              value: '3',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            Off: {
              value: '2',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            On: {
              value: '1',
              hwValue: null,
              definitionConfigs: {},
              default: true,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: 'e9f27f3d-a2e8-48ca-aebe-589a62383065',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'VENTILATOR',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: '365a50d0-a10a-4586-b79d-3a22577f85ca',
      position: {
        lat: 53.54556038451171,
        long: 10.007677547741162,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [],
    type: 'hardwares',
  },
  {
    id: '66ef6bfd-e1b1-4ace-a09a-fb0cad3cdd40',
    version: 'dcbd9999-51c0-4775-9733-41258b5e24e5',
    tags: {},
    configs: {
      datapoint: 'ISP_Level_03-103010_Raum_45_Lueftung-MSV14',
      'hardware-key': 'KkETW3A8',
      'poi-id': 'f0a11c45-16a1-4b21-b854-2a3460f439d0',
      'route-key': 'aedifion-connector',
      name: '3.45b',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_03-103010_Raum_45_b_Lueftung-MSV14',
    actions: [
      {
        id: 'eb99d859-90c5-4a8c-a800-9cf3d199d01b',
        version: 'be78bdc0-c89a-4d53-af5d-0b6eac61b9ed',
        tags: {},
        configs: {},
        actionType: 'motor',
        spec: {
          type: 'localized_discrete',
          def: {
            automatic: {
              value: '3',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            Off: {
              value: '2',
              hwValue: null,
              definitionConfigs: {},
              default: false,
            },
            On: {
              value: '1',
              hwValue: null,
              definitionConfigs: {},
              default: true,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: '66ef6bfd-e1b1-4ace-a09a-fb0cad3cdd40',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'VENTILATOR',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: 'de907753-e052-49d7-a962-c287630367fd',
      position: {
        lat: 53.545111854426786,
        long: 10.008555314908314,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [],
    type: 'hardwares',
  },
]

export const blindsTest = [
  {
    id: '7e46bde9-a528-4526-b053-183bb5bacd40',
    version: '95e9d659-e9a6-46f5-85ad-e57e5e73c4f3',
    tags: {},
    configs: {
      'hardware-key': '22_1_0',
      name: 'Jalousie Gr.1',
    },
    serialNumber: 'bfb72857-4086-47aa-872e-be09022d3f16',
    actions: [
      {
        id: 'e9883abb-4a37-48ad-9969-c322a8c5f23f',
        version: 'fc26d526-50cb-4ef4-8389-4be99f718dd5',
        tags: {},
        configs: {},
        actionType: 'angle',
        spec: {
          type: 'range',
          def: [
            {
              min: 50,
              max: 100,
              steps: 1,
            },
          ],
        },
        hardware: {
          type: 'hardwares',
          id: '7e46bde9-a528-4526-b053-183bb5bacd40',
        },
        type: 'actions',
      },
      {
        id: '39fd85a6-457d-4846-b02c-91d50935b7cf',
        version: 'c6509264-d85d-42b9-96ee-77b4497cb383',
        tags: {},
        configs: {},
        actionType: 'switch',
        spec: {
          type: 'localized_discrete',
          def: {
            aufsteigen: {
              value: 'move-up',
              hwValue: '0',
              definitionConfigs: {},
              default: false,
            },
            absteigen: {
              value: 'move-down',
              hwValue: '1',
              definitionConfigs: {},
              default: false,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: '7e46bde9-a528-4526-b053-183bb5bacd40',
        },
        type: 'actions',
      },
      {
        id: 'ba5a6e9e-c203-4893-adc6-42d099d1addc',
        version: '92730a25-80a0-458e-9894-2774ce5fd3c5',
        tags: {},
        configs: {},
        actionType: 'position',
        spec: {
          type: 'range',
          def: [
            {
              min: 0,
              max: 100,
              steps: 1,
            },
          ],
        },
        hardware: {
          type: 'hardwares',
          id: '7e46bde9-a528-4526-b053-183bb5bacd40',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'BLINDS',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: '60156cd9-7d85-4859-ba9d-5f6f1e9e5aa5',
      floorId: '5bfd0fa6-76db-407e-991a-22d31672c6d7',
      position: {
        lat: 51.1853411,
        long: 6.427787,
      },
    },
    customerId: '34b951fd-ebc7-44d7-a6aa-3e7160294ef2',
    measurements: [],
    type: 'hardwares',
    name: 'Jalousie Gr.1',
    position: {
      min: 0,
      max: 100,
      steps: 1,
    },
    angle: {
      min: 50,
      max: 100,
      steps: 1,
    },
    switch: {
      on: '0',
      off: '1',
    },
  },
  {
    id: '3ee48ab7-3f29-4aae-9c8c-30e63513dacc',
    version: '5e244c4d-f571-42bf-8d22-0653c785ceed',
    tags: {},
    configs: {
      'poi-id': '875d660d-7d76-4b47-9117-c057c023e274',
      'route-key': 'aedifion-connector',
      name: '3.02-b',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_03-103010_Raum_02_b_Sonnenschutz',
    actions: [
      {
        id: '11993aac-503f-4c18-9902-1aca0f2d2f01',
        version: '024eed73-30c3-4ad6-8079-cefbe8ac08d8',
        tags: {},
        configs: {},
        actionType: 'scene',
        spec: {
          type: 'localized_discrete',
          def: {
            close: {
              value: '2',
              hwValue: '1',
              definitionConfigs: {
                datapoint: 'ISP_Level_03-103010_Raum_02_b_Sonnenschutz_Zu-BV37',
                hash: '5D4g90Kg',
              },
              default: true,
            },
            Open: {
              value: '1',
              hwValue: '1',
              definitionConfigs: {
                datapoint:
                  'ISP_Level_03-103010_Raum_02_b_Sonnenschutz_Auf-BV36',
                hash: 'GUtbNBlB',
              },
              default: false,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: '3ee48ab7-3f29-4aae-9c8c-30e63513dacc',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'BLINDS',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: 'de907753-e052-49d7-a962-c287630367fd',
      position: {
        lat: 53.54527760315907,
        long: 10.008183828959751,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [],
    type: 'hardwares',
  },
  {
    id: '11b4cee9-e3bb-48a5-9f29-8a229e64f246',
    version: '6c1fc71e-ff46-4a15-aeba-2ffb8d941865',
    tags: {},
    configs: {
      'poi-id': 'ebbd1f67-32d5-464a-b3fd-67f91ac688a4',
      'route-key': 'aedifion-connector',
      name: '1.08b Enteractive',
      'project-id': '113',
    },
    serialNumber: 'ISP_Level_01-101010_Raum_08_Sonnenschutz_b',
    actions: [
      {
        id: 'ea537075-1ae9-4ee1-86cd-81cac3591f9d',
        version: '76ab2038-e550-4ec4-b867-660c282d4d6f',
        tags: {},
        configs: {},
        actionType: 'scene',
        spec: {
          type: 'localized_discrete',
          def: {
            close: {
              value: '2',
              hwValue: '1',
              definitionConfigs: {
                datapoint: 'ISP_Level_01-101010_Raum_08_Sonnenschutz_Zu-BV39',
                hash: 'KYNF1Idd',
              },
              default: true,
            },
            Open: {
              value: '1',
              hwValue: '1',
              definitionConfigs: {
                datapoint: 'ISP_Level_01-101010_Raum_08_Sonnenschutz_Auf-BV38',
                hash: 'PZLh40Y5',
              },
              default: false,
            },
          },
        },
        hardware: {
          type: 'hardwares',
          id: '11b4cee9-e3bb-48a5-9f29-8a229e64f246',
        },
        type: 'actions',
      },
    ],
    hardwareType: 'BLINDS',
    location: {
      campusId: '4a289bb3-99df-499f-b6d7-409448fb87f2',
      buildingId: 'e1e1c326-f8e5-472b-9ea7-673c59550d46',
      floorId: 'baee4f36-eb11-4722-975f-3c3be409d0bd',
      position: {
        lat: 53.54534465748786,
        long: 10.007887395767877,
      },
    },
    customerId: '912b1bf5-11b4-4f5f-8da0-fbedb01bc20d',
    measurements: [],
    type: 'hardwares',
  },
]
