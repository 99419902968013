// src/router/routerHelper.js
import { getCustomer } from '@/assets/Branding/branding.js'

export async function getBookingPoisListComponent() {
  const customer = await getCustomer()
  if (customer === 'pia') {
    return import('@/components/CustomerComponents/general/BookingPoisList.vue')
  } else if (customer === 'hammerbrooklyn') {
    return import('@/components/CustomerComponents/general/BookingPoisList.vue')
  }
  return import('@/components/general/BookingPoisList.vue')
}
