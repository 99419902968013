<template>
  <v-card class="vent-control-card">
    <v-card-title class="title">Adjust Ventilator</v-card-title>
    <v-card-subtitle class="subtitle">
      <v-select
        v-model="selectedVentilator"
        :items="ventilators"
        return-object
        item-value="serialNumber"
        item-title="serialNumber"
        label="Select Ventilator"
        @change="handleVentilatorChange"
        dense
        outlined
        class="custom-select"
      ></v-select>
    </v-card-subtitle>
    <v-card-text class="icon-container">
      <div v-if="fanSpeedValue === 'off'" class="mdi mdi-fan-off icon"></div>
      <div
        v-if="fanSpeedValue === 'on'"
        class="mdi mdi-fan icon spin-fast"
      ></div>
      <div
        v-if="fanSpeedValue === 'auto'"
        class="mdi mdi-fan icon spin-slow"
      ></div>
    </v-card-text>
    <v-card-text class="slider-container">
      <v-slider
        v-model="fanSpeed"
        :max="currentVentOptions.length - 1"
        :ticks="tickLabels"
        show-ticks="always"
        step="1"
        tick-size="4"
        class="custom-slider"
      ></v-slider>
    </v-card-text>
  </v-card>
</template>

<script>
  import { ref, computed, watch, onMounted, defineComponent } from 'vue'
  import 'vuetify/styles' // Ensure you are using css-loader
  import '@mdi/font/css/materialdesignicons.css'

  export default defineComponent({
    name: 'VentControl',
    props: {
      vents: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    setup(props) {
      const ventilators = computed(() => props.vents)
      const selectedVentilator = computed(() => props.vents[0])
      const currentVentOptions = ref([])

      const fanSpeed = ref(0)

      const fanSpeedValue = computed(() => {
        const option = currentVentOptions.value[fanSpeed.value]
        return option?.value.toLowerCase() === 'automatic'
          ? 'auto'
          : option?.value.toLowerCase()
      })

      const tickLabels = computed(() => {
        const labels = {}
        currentVentOptions.value.forEach((option, index) => {
          let label =
            option.label.toLowerCase() === 'automatic' ? 'auto' : option.label
          labels[index] = label
        })
        return labels
      })

      const updateFeatures = vent => {
        currentVentOptions.value = vent?.ventOptions || []
        fanSpeed.value = 0 // Reset fan speed to the first option
      }

      onMounted(() => {
        selectedVentilator.value = ventilators.value[0]
        updateFeatures(ventilators.value[0])
      })

      watch(
        selectedVentilator,
        newVent => {
          if (newVent) {
            updateFeatures(newVent)
          }
        },
        { immediate: true }
      )

      const handleVentilatorChange = () => {
        const selectedVent = ventilators.value.find(
          v => v.serialNumber === selectedVentilator.value.serialNumber
        )
        updateFeatures(selectedVent)
      }

      return {
        ventilators,
        selectedVentilator,
        currentVentOptions,
        fanSpeed,
        fanSpeedValue,
        tickLabels,
        handleVentilatorChange,
      }
    },
  })
</script>

<style scoped>
  .vent-control-card {
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
    background-color: #f5f5f5;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  .vent-control-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  }
  .title {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333333;
  }
  .subtitle {
    margin-bottom: 16px;
  }
  .icon-container {
    margin-bottom: 16px;
    height: 96px; /* Ensure consistent height for smooth transitions */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .slider-container {
    margin-top: 16px;
    position: relative;
  }
  .custom-slider {
    width: 100%;
    margin-top: 16px;
  }
  .mdi-fan-off {
    font-size: 96px;
    color: #888888;
  }
  .mdi-fan {
    font-size: 96px;
    color: #00bcd4;
  }
  @keyframes spin-fast {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spin-slow {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spin-fast {
    animation: spin-fast 1s linear infinite;
  }
  .spin-slow {
    animation: spin-slow 3s linear infinite;
  }
  .zoom-fade-enter-active,
  .zoom-fade-leave-active {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .zoom-fade-enter,
  .zoom-fade-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }
  .custom-select {
    width: 100%;
  }
</style>
